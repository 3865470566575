import { call, put } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { setError } from '../store/common/actions';

interface ApiSagaRequest {
  type: 'get' | 'post' | 'put' | 'del',
  url: string,
  params?: string | null | undefined,
  data?: object,
}

export function* getJwtToken(): any {
  try {
    const curSession = yield call([Auth, 'currentSession']);
    return curSession.getIdToken().getJwtToken();
  } catch (e) {
    console.error(e);
  }
}

export function* apiSagaRequest({ type, url, params, data }: ApiSagaRequest): any {
  try {
    const token = yield call(getJwtToken);
    return yield call([API, type], 'gci', `${url}${params ? params : ''}`, {
      headers: {
        Authorization: token,
      },
      ...(data && { body: data }),
    });
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(setError({ error: e.message }));
    }
  }
}
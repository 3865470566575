import { combineReducers } from 'redux'

import commonReducer from './common/reduser'
import authReducer from './auth/reduser'
import jobsReducer from './jobs/reduser'
import usersReducer from './users/reduser'
import usersViewReducer from './usersView/reduser'
import issuesReducer from './issues/reduser'
import issuesCategoriesReducer from './system/issueCategories/reduser'
import trainingsReducer from './trainings/reduser'
import posReducer from './pos/reduser'
import ARInvoice from './ARInvoice/reduser'
import APInvoice from './APInvoice/reduser'
import ARRequestInvoices from './ARRequestInvoices/reduser'
import manpowerReducer from './manpower/reducer'

const rootReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  jobs: jobsReducer,
  users: usersReducer,
  usersView: usersViewReducer,
  issues: issuesReducer,
  issueCategories: issuesCategoriesReducer,
  trainings: trainingsReducer,
  pos: posReducer,
  APInvoice: APInvoice,
  ARInvoice: ARInvoice,
  ARRequestInvoices: ARRequestInvoices,
  manpower: manpowerReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer

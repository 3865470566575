import React from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

import { isEqualPropsMemo } from '../../../utils/helpers';
import { routes } from '../../../constants/routes';
import { StyledFootnote, StyledTrainingsCard } from './styles';

interface ITrainingsCardProps {
  id: string,
  status?: string,
  language?: string,
  duration: number,
  level?: string,
  title: string,
  description: string,
}

const TrainingsCard = ({ id, title, description, duration, language, level, status }: ITrainingsCardProps) => {
  return (
    <StyledTrainingsCard to={`${routes.trainings}/${id}`}>
      <Stack direction="row" sx={{ mb: .5 }} spacing={2}>
        <StyledFootnote>
          <AccessTimeOutlinedIcon />
          {Math.floor(moment.duration(duration).asHours()) + moment.utc(moment.duration(duration).asMilliseconds()).format(":mm:ss")}
        </StyledFootnote>
        <StyledFootnote>
          <TranslateOutlinedIcon />
          {language === 'es'&& <>Spanish</>}
          {language === 'us'&& <>English</>}
        </StyledFootnote>
        {level !== '' && 
          <StyledFootnote>
            <BarChartOutlinedIcon />
            <div style={{textTransform: 'capitalize'}}>{level}</div>
          </StyledFootnote>
        }
        {/* {status === 'inactive' &&
          <StyledFootnote>
            <div style={{ textAlign: 'right'}}>{status}</div>
          </StyledFootnote>
        } */}
      </Stack>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2" sx={{ mb: .5, overflowY: 'auto', height: '120px' }}>{description}</Typography>
    </StyledTrainingsCard>
  );
};

export default React.memo(TrainingsCard, isEqualPropsMemo);
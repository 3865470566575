import React, { useState } from 'react';

import { useFilter } from '../../../hooks/useFilters';
import { 
  trainingsLanguageOptions, 
  trainingsTypeOptions, 
  trainingsStatusOptions,
  trainingsLevelOptions
} from '../../../constants/selectOptions';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FilterSelect from '../../ui/FilterSelect';
import FormControl from '@mui/material/FormControl';
import FormLabel from '../../ui/FormLabel/FormLabel';
import { styled } from '@mui/material/styles';
import Search from '../../ui/Search/Search';


// import { setActiveFiltersCollapsed } from '../../../store/common/actions';
// import { useDispatch } from 'react-redux';

const TrainingsFilters = () => {
  const { filters, handleRemoveFilters, handleChangeFilters } = useFilter();
  // const dispatch = useDispatch();
  // const getFromStorage = () => { 
  //   return JSON.parse(localStorage.getItem('filtersCollapsed')!)
  // }

  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const TitleWrap = styled(Stack)({
    alignItems: 'baseline',
    marginBottom: 10,
  });

  const handleCollapsed = () => {
    // const newValueFiltersCollapsed = !JSON.parse(localStorage.getItem('filtersCollapsed')!)
    setIsCollapsed(!isCollapsed)
    // dispatch(setActiveFiltersCollapsed(newValueFiltersCollapsed));
    // localStorage.setItem('filtersCollapsed', newValueFiltersCollapsed.toString());
  }

  // useEffect(
  //   () => window.localStorage.setItem('filtersCollapsed', isCollapsed.toString()),
  //   [isCollapsed]
  // );
  return (
    <>
      <TitleWrap direction="row" spacing={2}>
        <Typography variant="h4" sx={{ fontSize: 19 }} component="h1">
          Trainings
        </Typography>
        <button
          className={isCollapsed ? 'collapse__title is-active' : 'collapse__title'} 
          onClick={handleCollapsed}>
          {isCollapsed ? 'Expand filter' : 'Collapse filter'}
        </button>
      </TitleWrap>
      <div
        className="collapse__content"
        style={ isCollapsed ? { display:'block'} : {display : 'none'} } 
        aria-expanded={isCollapsed}
      >
        <div style={{display: 'flex', alignItems: 'flex-end', margin: '0 0 20px 0'}}>
        <FormControl margin="normal" style={{marginRight: '8px'}}>
          <FormLabel title="Language" />
          <FilterSelect
            name="language"
            label="Language"
            filters={filters}
            onRemove={handleRemoveFilters}
            onChange={handleChangeFilters}
            options={trainingsLanguageOptions}
          />
        </FormControl>
        <FormControl margin="normal" style={{marginRight: '8px'}}>
          <FormLabel title="Type" />
          <FilterSelect
            name="type"
            label="Type"
            filters={filters}
            onRemove={handleRemoveFilters}
            onChange={handleChangeFilters}
            options={trainingsTypeOptions}
          />
        </FormControl>
        <FormControl margin="normal" style={{marginRight: '8px'}}>
          <FormLabel title="Status" />
          <FilterSelect
            name="status"
            label="Status"
            filters={filters}
            onRemove={handleRemoveFilters}
            onChange={handleChangeFilters}
            options={trainingsStatusOptions}
          />
        </FormControl>
        <FormControl margin="normal" style={{marginRight: '8px'}}>
          <FormLabel title="Level" />
            <FilterSelect
            name="level"
            label="Level"
            filters={filters}
            onRemove={handleRemoveFilters}
            onChange={handleChangeFilters}
            options={trainingsLevelOptions}
          />
        </FormControl>
        <FormControl margin="normal">
          <FormLabel title="Search" />
          <Search />
        </FormControl>
        </div>
      </div>
    </>
  )
}

export default TrainingsFilters;
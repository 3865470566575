import React from 'react';
import { useSelector } from 'react-redux';

import { getUsersSelector } from '../store/users/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { fetchUsersRequest } from '../store/users/actions';
import { useGetPageItemsEffect } from '../utils/hooks';

import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import Loading from '../components/ui/Loading/Loading';
import UsersTable from '../components/Users/UsersTable/UsersTable';
import PageTitle from '../components/ui/PageTitle/PageTitle';

const Users: React.FC = () => {
  const users = useSelector(getUsersSelector);
  const pending = useSelector(getIsPendingSelector);
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: `Personnel`,
      path: '',
    },
  ];

  useGetPageItemsEffect(fetchUsersRequest);

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Personnel" />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <UsersTable dataValues={users} />
      }
    </Layout>
  );
};

export default Users;
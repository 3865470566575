import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';

import { getCategoriesOptionsSelector, getItemsSelector } from '../../../store/system/issueCategories/selectors';
import { getUsersOptionsSelector } from '../../../store/users/selectors';
import { getCurrentJobSelector } from '../../../store/jobs/selectors';
import { updateIssuesRequest } from '../../../store/issues/actions';
import { IIssueCategoriesItem } from '../../../types/system/issueCategories';
import { ISelectOptions } from '../../../types/common';
import { IAIItem } from '../../../types/actionItems';
import { capitalizeFirstLetter, getFilteredObjectsArray, isEqualPropsMemo } from '../../../utils/helpers';
import { useModal } from '../../../utils/hooks';
import { severitySelectOptions, statusesSelectOptions } from '../../../constants/selectOptions';
// import { routes } from '../../../constants/routes';
import CustomModal from '../../ui/CustomModal';
import MoreMenu from '../../ui/MoreMenu';
import FormLabel from '../../ui/FormLabel';
import SelectField from '../../ui/SelectField';

type KeyofIAIItem = keyof IAIItem;

const ActionItemsTable = ({ dataValues} :any ) => {
  const MaterialTable = require("material-table").default;
  console.log('dataValues', dataValues);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const job_id = useSelector(getCurrentJobSelector);
  const aiCategories = useSelector(getItemsSelector);
  const usersOptions = getFilteredObjectsArray(useSelector(getUsersOptionsSelector));
  const issueCategoriesOptions = useSelector(getCategoriesOptionsSelector);
  const [type, setType] = useState<KeyofIAIItem | undefined>();
  const [value, setValue] = useState<any>('');
  const { isModalOpen, handleToggleModal } = useModal();
  const [muiTableKey, setMuiTableKey] = React.useState(0);

  const optionsByType: { [key: string]: ISelectOptions[] } = {
    assigned_to: usersOptions,
    severity: severitySelectOptions,
    category: issueCategoriesOptions,
    status: statusesSelectOptions,
  };

  useEffect(() => {
    if (type) {
      if (type === 'assigned_to') {
        setValue({
          id: dataValues.assigned_to.id,
          fullname: usersOptions.find((user: ISelectOptions) => user.value === dataValues.assigned_to.id)?.label,
        });
      } else {
        setValue(dataValues[type]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // const handleViewDetails = () => {
  //   navigate(`issues/${dataValues.id}`);
  // };

  const handleCloseActionItem = () => {
    dispatch(updateIssuesRequest({
      job_id,
      ai_id: dataValues.id,
      data: { status: 'closed', documents: [] },
      updateItems: true,
    }));
  };

  const handleSubmit = () => {
    if (type) {
      dispatch(updateIssuesRequest({
        job_id,
        ai_id: dataValues.id,
        data: {
          [type]: value,
          ...(type === 'assigned_to' && { 'assigned_at': moment().toISOString() }),
          documents: [],
        },
        updateItems: true,
      }));
      handleToggleModal();
    }
  };

  const handleChange = (e: any) => {
    if (type) {
      if (type === 'assigned_to') {
        setValue({
          id: e.target.value,
          fullname: usersOptions.find((user: ISelectOptions) => user.value === e.target.value)?.label,
        });
      } else {
        setValue(e.target.value);
      }
    }
  };

  const handleOpenModal = (type: KeyofIAIItem) => {
    handleToggleModal();
    setType(type);
  };

  const viewMoreItems = [
    { label: 'Close issue', cb: handleCloseActionItem },
    { label: 'Assigned to ...', cb: () => handleOpenModal('assigned_to'), divider: true },
    { label: 'Change', icon: EditIcon },
    { label: 'Category', cb: () => handleOpenModal('category') },
    { label: 'Severity', cb: () => handleOpenModal('severity') },
    { label: 'Status', cb: () => handleOpenModal('status') }
    // { label: 'View details', cb: handleViewDetails, icon: RemoveRedEyeIcon },
  ];
  return (
    <>
      <MaterialTable 

        columns={[
          // Issue #
          { 
            title: 'Issue #', 
            field: 'hid',
            customSort: (a: any, b: any) => a.hid.localeCompare(b.hid, 'en', { numeric: true }),
            render: (rowData: any) => 
              <span 
                style={{ textDecoration: 'underline', cursor: 'pointer' }} 
                onClick={() => navigate(`/issues/${rowData.id}`)}
              >
                {rowData.hid}
              </span> 
          },
          // Summary
          { 
            title: 'Summary', 
            field: 'summary',
            customSort: (a: any, b: any) => a.summary.localeCompare(b.summary, 'en', { numeric: true }),
            render: (rowData: any) => 
            <div style={{width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              {rowData.summary}
            </div>
          },
          // Status
          { 
            title: 'Status', 
            field: 'status',
            lookup: { 
              'open': 'Open',
              'reviewed': 'Reviewed',
              'progress': 'Progress',
              'closed': 'Closed',
              'resolved': 'Resolved',
              'duplicate': 'Duplicate',
            },
            render: (rowData: any) => 
            <div style={{ textTransform: 'capitalize' }}>
              {rowData.status === 'closed' && <div style={{ color: '#d32f2f' }}>Closed</div>}
              {rowData.status === 'Closed' && <div style={{ color: '#d32f2f' }}>Closed</div>}
              {rowData.status === 'open' && <div style={{ color: '#2e7d32' }}>Open</div>}
              {rowData.status === 'Open' && <div style={{ color: '#2e7d32' }}>Open</div>}
              {rowData.status === 'reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
              {rowData.status === 'Reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
              {rowData.status === 'progress' && <div style={{ color: '#19857b' }}>Progress</div>}
              {rowData.status === 'Progress' && <div style={{ color: '#19857b' }}>Progress</div>}
              {rowData.status === 'resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
              {rowData.status === 'Resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
              {rowData.status === 'duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
              {rowData.status === 'Duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
            </div> 
          },
          // Category
          { 
            title: 'Category', 
            field: 'category',
            filtering: false,
            sorting: false,
            render: (rowData: any) => 
              <>
                {aiCategories.find((category: IIssueCategoriesItem) => category.id === rowData.category)?.category}
              </> 
          },
          // Type
          { 
            title: 'Type', 
            field: 'type',
            sorting: false,
            filtering: false,
            render: (rowData: any) => 
              <div style={{width: 400}}>
                {aiCategories.find((category: IIssueCategoriesItem) => category.id === rowData.category)?.type}
              </div> 
          },
          // Due Date
          { 
            title: 'Due Date', 
            field: 'due_date',
            filtering: false,
            render: (rowData: any) => 
              <>
                {rowData.due_date && moment(rowData.due_date).format()}
              </> 
          },
          // Severity
          { 
            title: 'Severity', 
            field: 'severity',
            lookup: { 
              'low': 'Low',
              'medium': 'Medium',
              'high': 'High'
            },
            render: (rowData: any) => 
              <div style={{ textTransform: 'capitalize' }}>
                {rowData.severity}
              </div> 
          },
          // Identified at
          { 
            title: 'Identified at', 
            field: 'identified_at',
            filtering: false,
            render: (rowData: any) => 
              <>
                {rowData.identified_at && 
                  <>
                    <div>{moment(rowData.identified_at).format('DD.MM.YYYY')}</div>
                    <div>{moment(rowData.identified_at).format('HH:mm')}</div>
                  </>
                }
              </> 
          },
          // Assigned to
          { 
            title: 'Assigned to', 
            field: 'assigned_to',
            filtering: false,
            // customSort: (a: any, b: any) => a.assigned_to - b.assigned_to,
            render: (rowData: any) => 
              <>
                {
                  rowData.assigned_to?.fullname 
                  ? <>{rowData.assigned_to?.fullname}</>
                  : <>&mdash;</>
                }
              </> 
          },
        ]}
        data={dataValues?.sort((a: any, b: any) => (a.status === 'open')? -1 : 1)}  
        actions={[
          {
            icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
            tooltip: "Сlear all filters",
            isFreeAction: true,
            onClick: () => {
              setMuiTableKey(muiTableKey + 1);
            }
          },
          {
            icon: () => <>
            {
              !isEmpty(aiCategories) && !isEmpty(usersOptions) &&
              <MoreMenu
                items={viewMoreItems}
              />
            }
            </>,
            isFreeAction: false,
            tooltip: '',
            // onClick: (event, rowData) => {}
          },
        ]}   
        options={{
          showTitle: false,
          headerStyle: {
            whiteSpace: 'nowrap'
          },
          search: false,
          filtering: true,
          sorting: true,
          paging:true,
          pageSize:10,
          pageSizeOptions:[10, 25, 50, 100],
          toolbar: true, 
          actionsColumnIndex: -1,
          maxBodyHeight: 'calc(100vh - 280px)',
        }}
      />
      {
        type &&
        <CustomModal
          open={isModalOpen}
          onClose={handleToggleModal}
        >
          <Box>
            Issue {dataValues.hid}
          </Box>
          <Divider sx={{ my: 2 }} />
          {type === 'assigned_to' ? 
          <FormLabel title={`Change Assigned to`} />
           : 
           <FormLabel title={`Change ${capitalizeFirstLetter(type)}`} />}
          <SelectField
            name={type}
            value={type === 'assigned_to' ? value?.id : value}
            options={optionsByType[type]}
            onChange={handleChange}
          />
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={handleToggleModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleSubmit}>
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </CustomModal>
      }
    </>
  )
}

export default memo(ActionItemsTable, isEqualPropsMemo);
import React from 'react';
import docFilePDF from '../../../assets/_pdf2.pdf'

  const DocumentPDF: React.FC = (): JSX.Element => {

  return (
    <iframe 
      id="fred" 
      title="PDF in an i-Frame" 
      src={`${docFilePDF}#view=fitH`}
      frameBorder="1" 
      scrolling="auto" 
      style={{height:"calc(100vh - 170px)"}}
      width="100%"
    ></iframe>
  );
}

export default DocumentPDF;

import React from 'react';

import { fetchTrainingsRequest } from '../store/trainings/actions';
import { useGetPageItemsEffect } from '../utils/hooks';
import SessionTabs from '../components/Session/SessionTabs';
import PageTitle from '../components/ui/PageTitle';
import Layout from '../components/layout';

const SessionCreate = () => {
  useGetPageItemsEffect(fetchTrainingsRequest);

  return (
    <Layout>
      <PageTitle title='Create session for "" training.' />
      <SessionTabs />
    </Layout>
  );
};

export default SessionCreate;
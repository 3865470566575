import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
// import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
// import FormLabel from '../components/ui/FormLabel/FormLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import imagePreview from '../assets/images/job-view.png';

const JobsView = () => {
  const MaterialTable = require("material-table").default;
  const navigate = useNavigate();
  const location = useLocation()
  const currentJob = location.pathname.split('/').pop()
  const pending = false
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Job List',
      path: '/jobs',
    },
    {
      title: `${currentJob}`,
      path: '',
    },
  ];
  // const data = [
  //   {title: 'Job #', value: '02334E-F'},
  //   {title: 'Phase', value: '02334E-F'},
  //   {title: 'Job Number', value: '02334E-F'},
  //   {title: 'Job Name', value: '02334E-F'},
  //   {title: 'Hours', value: '02334E-F'},
  //   {title: 'Total Cost', value: '02334E-F'},
  //   {title: 'Labor', value: '02334E-F'},
  //   {title: 'Material', value: '02334E-F'},
  //   {title: 'Subcontactor', value: '02334E-F'},
  //   {title: 'Equipment', value: '02334E-F'},
  //   {title: 'Equipment Rented', value: '02334E-F'},
  //   {title: 'Other', value: '02334E-F'},
  //   {title: 'Commited Cost', value: '02334E-F'},
  //   {title: 'Contract Amt', value: '02334E-F'},
  //   {title: 'Billed Amt', value: '02334E-F'},
  //   {title: 'Cust PO', value: '02334E-F'},
  //   {title: 'Phase Bill Type', value: '02334E-F'},
  //   {title: 'Bill Type', value: '02334E-F'},
  //   {title: 'Craft Template', value: '02334E-F'},
  //   {title: 'Security Group', value: '02334E-F'},
  //   {title: 'PM Number', value: '02334E-F'},
  //   {title: 'JCCo', value: '02334E-F'},
  //   {title: 'PM Name', value: '02334E-F'},
  //   {title: 'Phase Amt', value: '02334E-F'},
  //   {title: 'Status', value: 'Open'},
  //   {title: 'Total Est Billings', value: '02334E-F'},
  //   {title: 'Total Act Billing', value: '02334E-F'},
  //   {title: 'Labor Est Bill', value: '02334E-F'},
  //   {title: 'Subcontractor Est Bill', value: '02334E-F'},
  //   {title: 'Equipment Est Bill', value: '02334E-F'},
  //   {title: 'Equipment Rented Est Billing', value: '02334E-F'},
  //   {title: 'Other Est Billing', value: '02334E-F'},
  //   {title: 'Commited Cost Est Billing', value: '02334E-F'},
  //   {title: 'Labor Act Billing', value: '02334E-F'},
  //   {title: 'Material Act Billing', value: '02334E-F'},
  //   {title: 'Subcontractor Act Billing', value: '02334E-F'},
  //   {title: 'Equipment Act Billing', value: '02334E-F'},
  //   {title: 'Equipment Rented Act Billing', value: '02334E-F'},
  //   {title: 'Other Act Billing', value: '02334E-F'},
  //   {title: 'Equipment Rented Act Billing', value: '02334E-F'},
  //   {title: 'Other Act Billing', value: '02334E-F'},
  //   {title: 'CTot Act Billing', value: '02334E-F'},
  //   {title: 'CLabor Act Billing', value: '02334E-F'},
  //   {title: 'CMaterial Act Billing', value: '02334E-F'},
  //   {title: 'CSubcontractor Act Billing', value: '02334E-F'},
  //   {title: 'CEquipment Act Billing', value: '02334E-F'},
  //   {title: 'CEquipment Rented Act Billing', value: '02334E-F'},
  //   {title: 'COther Act Billing', value: '02334E-F'},
  //   {title: 'Cust Group', value: '02334E-F'},
  //   {title: 'Name', value: '02334E-F'},
  //   {title: 'SortName', value: '02334E-F'},
  //   {title: 'Customer', value: '02334E-F'},
  //   {title: 'Job Description', value: 'This field used for job description'},
  //   {title: 'Last Day Worked', value: '16-01-2022'}
  // ]
  const dataTable = [
    // {text: 'Labor', estimated: 45809.33, actual: 45808.33},
    // {text: 'Materials', estimated: 45809.33, actual: 45808.33},
    // {text: 'Equipment', estimated: 45809.33, actual: 45808.33},
    // {text: 'Man hours', estimated: 45809.33, actual: 45808.33},
    // {text: 'Subcontract', estimated: 45809.33, actual: 45808.33},
    // {text: 'Contract Amt', estimated: 45809.33, actual: 45808.33},
    // {text: 'Cost', estimated: 45809.33, actual: 45808.33},
    // {text: 'Change Orders', estimated: 45809.33, actual: 45808.33},
    {text: 'Total', estimated: 90459.33, actual: 90459.33},
  ]
  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle goBack title={`${currentJob} Job Detail`} />
      <Card style={{ height: 'calc(100vh - 180px)' }}>
        <CardContent>
          {/* <Grid container spacing={1} sx={{ width: 920 }}>
            {data.map((job, index) => (
              <Grid key={index} item xs={12} sm={4} spacing={4}>
                <FormControl size="small" sx={{ width: 290 }} margin="normal">
                  <FormLabel title={job.title} />
                  <TextField disabled value={job.value} />
                </FormControl>
              </Grid>
            ))}
          </Grid> */}
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ height: '100%' }} direction="column" justifyContent="space-between">
                <Button variant="outlined" disabled>Drawings</Button>
                <Button variant="outlined" onClick={() => navigate(`/pos`)} disabled>PO's</Button>
                <Button variant="outlined" onClick={() => navigate(`/invoices/?job=${currentJob}`)}>AR Invoices</Button>
                <Button variant="outlined" onClick={() => navigate(`/issues`)} disabled>Issues</Button>
                <Button variant="outlined" disabled>Job DOCS</Button>
                <Button variant="outlined" disabled>Equipment</Button>
                {/* <Button variant="outlined" disabled>EQ Recieve</Button> */}
                {/* <Button variant="outlined" disabled>EQ Call off</Button> */}
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <img src={imagePreview} width="100%" height="auto" alt="preview" />
              <MaterialTable 
                title=""
                columns={[
                  { 
                    title: 'Item', 
                    field: 'text',
                    render: (rowData: any) =>
                      <div style={ rowData.text === 'Total' ? { fontWeight:'600' } : { fontWeight:'400' } }>
                        {rowData.text}
                      </div>
                  },  
                  { 
                    title: 'Estimated', 
                    field: 'estimated',
                    render: (rowData: any) =>
                    <div style={ rowData.text === 'Total' ? { fontWeight:'600' } : { fontWeight:'400' } }>
                      {
                        (rowData.estimated).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                      }
                    </div>
                  },
                  { 
                    title: 'Actual', 
                    field: 'actual',
                    render: (rowData: any) =>
                    <div style={ rowData.text === 'Total' ? { fontWeight:'600' } : { fontWeight:'400' } }>
                      {
                        (rowData.actual).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                      }
                    </div>
                  }, 
                ]}
                data={dataTable}
                options={{
                  headerStyle: {
                    whiteSpace: 'nowrap'
                  },
                  search: false,
                  filtering: false,
                  paging:false,
                  pageSize:100,
                  pageSizeOptions:[50, 100]
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={2} style={{ height: '100%' }} direction="column" justifyContent="space-between">
                <Button variant="outlined" disabled>DOCS - Pictures</Button>
                <Button variant="outlined" disabled>DOCS - Quality</Button>
                <Button variant="outlined" disabled>DOCS - Safety</Button>
                <Button variant="outlined" disabled>Docs - Environmental</Button>
                <Button variant="outlined" onClick={() => navigate(`/manpower/?job=${currentJob}`)}>Manpower</Button>
                <Button variant="outlined" disabled>Tools</Button>
                {/* <Button variant="outlined" disabled>Permissions</Button> */}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default JobsView;
import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface FileUploadFieldProps {
  value?: any;
  disabled?: boolean;
  accept?: string;
  onChange?: (file: object) => void;
  buttonColor?: 'primary' | 'secondary' | 'inherit';
  buttonPosition?: 'start' | 'end';
}

const FileUploadField = ({
                           value,
                           disabled,
                           onChange,
                           accept,
                           buttonColor = 'primary',
                           buttonPosition = 'end',
                           ...props
                         }: FileUploadFieldProps & TextFieldProps) => {

  const [file, setFile] = useState<{ name: string; size: number; type: string }>({
    name: '',
    size: 0,
    type: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files[0]);
      onChange?.(e.target.files[0]);
    }
  };

  return (
    <Stack component="label" direction="row">
      <input
        type="file"
        disabled={disabled}
        onChange={handleChange}
        accept={accept}
        style={{ display: 'none' }}
      />
      {
        buttonPosition === 'start' &&
        <Button
          style={{ margin: '0 0 0 11px' }}
          color={buttonColor}
          component="span"
          variant="outlined"
          disabled={disabled}
        >
          Browse
        </Button>
      }
      <TextField
        fullWidth
        disabled={disabled}
        sx={{ pointerEvents: 'none' }}
        InputProps={{
          readOnly: true,
        }}
        value={file.name}
        {...props}
      />
      {
        buttonPosition === 'end' &&
        <Button
          style={{ margin: '0 0 0 11px' }}
          color={buttonColor}
          component="span"
          variant="outlined"
          disabled={disabled}
        >
          Browse
        </Button>
      }
    </Stack>
  );
};

export default FileUploadField;
import { createSelector } from 'reselect';

import { AppState } from '../../rootReducer';
import { ISelectOptions } from '../../../types/common';
import { IIssueCategoriesItem } from '../../../types/system/issueCategories';
import { getSortedSelectOptions } from '../../../utils/helpers';

const getItems = (state: AppState) => state.issueCategories.items;
const getError = (state: AppState) => state.issueCategories.error;
const getPending = (state: AppState) => state.issueCategories.pending;
const getSending = (state: AppState) => state.issueCategories.sending;

export const getItemsSelector = createSelector(getItems, items => items);
export const getErrorSelector = createSelector(getError, error => error);
export const getPendingSelector = createSelector(getPending, pending => pending);
export const getCategoriesListOptionsSelector = createSelector(getItems, items => {
  return items.reduce((result: ISelectOptions[], issue: IIssueCategoriesItem) => {
    if (!result.filter((item: any) => issue.category === item.label).length) {
      result.push({
        label: issue.category,
        value: issue.category,
      });
    }
    return getSortedSelectOptions(result);
  }, []);
});
export const getCategoriesOptionsSelector = createSelector(getItems, items => {
  return items.reduce((result: ISelectOptions[], issue: IIssueCategoriesItem) => {
    if (!result.filter((item: any) => issue.category === item.label).length) {
      result.push({
        label: issue.category,
        value: issue.id,
      });
    }
    return getSortedSelectOptions(result);
  }, []);
});
export const getSendingSelector = createSelector(getSending, sending => sending);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { fetchARInvoiceRequest } from '../store/ARInvoice/actions'
import { useGetPageItemsEffect } from '../utils/hooks';
import { getARInvoiceSelector } from '../store/ARInvoice/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { getJobsSelector } from '../store/jobs/selectors';
import { fetchJobsRequest } from '../store/jobs/actions';

import Layout from '../components/layout/Layout';
import Loading from '../components/ui/Loading/Loading';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import InvoicesARTable from '../components/Invoices/InvoicesARTable';

const Invoices: React.FC = (): JSX.Element => {
  const pending = useSelector(getIsPendingSelector);
  const items = useSelector(getARInvoiceSelector);
  const jobList = useSelector(getJobsSelector);
  const [job_id, setJob_id] = useState(localStorage.getItem('currentJob'));
  const [searchParams] = useSearchParams();
  const jobParams = searchParams.get('job');
  const [breadCrumbsItems, setBreadCrumbsItems] = useState([
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'AR List',
      path: '',
    },
  ]);

  useGetPageItemsEffect(fetchJobsRequest);

  useEffect(() => {
    if ((!job_id || job_id === undefined || job_id === '' || job_id === null) && jobList.length) {
      localStorage.setItem('currentJob', jobList[0]?.Job)
      setJob_id(jobList[0]?.Job);
    } else {
      const currentJobId = localStorage.getItem('currentJob');
      if (currentJobId !== null) {
        setJob_id(currentJobId);
      }
    }
  }, [jobList, job_id])

    useEffect(() => {
    if (jobParams) {
      setJob_id(jobParams);
      setBreadCrumbsItems([
        {
        title: 'Home',
        path: '/',
        },
        {
          title: `${job_id}`,
          path: `/jobs/${job_id}`
        },
        {
          title: 'AR List',
          path: '',
        },
      ])
    } else {
      setBreadCrumbsItems([
        {
        title: 'Home',
        path: '/',
        },
        {
          title: 'AR List',
          path: '',
        }
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobParams])

  const pageTitle = `AR List`;

  useGetPageItemsEffect(fetchARInvoiceRequest, false);
  
  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title={pageTitle} />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px)' }} />
        : <InvoicesARTable dataValues={items} jobParams={jobParams}/>
      }
    </Layout>
  )
};

export default Invoices;

import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const StyledTrainingsCard = styled(Link)({
  display: 'block',
  minWidth: 0,
  minHeight: '200px',
  maxHeight: '200px',
  color: 'inherit',
  textDecoration: 'none',
  boxShadow: '0 1px 2px 0 rgb(34 36 38 / 15%)',
  padding: '1rem 2rem',
  borderRadius: 4,
  border: '1px solid rgba(34,36,38,.15)',
  backgroundColor: '#fff',
  borderTop: '2px solid #2185d0',
});

export const StyledFootnote = styled('span')({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 'normal',
  fontSize: 10,
  color: 'rgba(108,117,125)',
  '& svg': {
    fontSize: '1.2em',
    marginRight: 4,
  },
});
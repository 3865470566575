import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTrainingProfileSelector } from '../store/trainings/selectors';
import { fetchTrainingProfileRequest } from '../store/trainings/actions';
import { getIsPendingSelector } from '../store/common/selectors';
import { getAuthDataSelector } from '../store/auth/selectors';
import { IHeadCell } from '../types/table';
import TrainingsProfileTableRow from '../components/Trainings/TrainingsProfileTableRow';
import PageTitle from '../components/ui/PageTitle';
import Table from '../components/ui/Table';
import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const tableHeadCells: IHeadCell[] = [
  {
    id: 'training_category',
    label: 'Training Category',
    style: { whiteSpace: 'nowrap' },
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'training_name',
    label: 'Training Name',
    style: { whiteSpace: 'nowrap' },
    numeric: false,
    disablePadding: false,
  },
  { id: 'training_session_id', label: 'Session', numeric: false, disablePadding: false },
  {
    id: 'completion_date',
    label: 'Completion Date',
    style: { whiteSpace: 'nowrap' },
    numeric: false,
    disablePadding: false,
  },
  { id: 'completion_grade', label: 'Grade', numeric: false, disablePadding: false },
];

const TrainingsProfile = () => {
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const authData = useSelector(getAuthDataSelector);
  const data = useSelector(getTrainingProfileSelector);

  useEffect(() => {
    dispatch(fetchTrainingProfileRequest({ id: authData.user_id }));

    // eslint-disable-next-line
  }, [authData.user_id]);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `Trainings`,
      path: '/trainings',
    },
    {
      title: `Trainings Profile`,
      path: '',
    },
  ];

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title={`Trainings profile: ${authData.user_fullname}`} />
      <Table
        data={data}
        pending={pending}
        headCells={tableHeadCells}
        BodyCell={TrainingsProfileTableRow}
      />
    </Layout>
  );
};

export default TrainingsProfile;
import React, { memo, useEffect, useState } from 'react';

import { isEqualPropsMemo } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateARRequestInvoiceRequest } from '../../../store/ARRequestInvoices/actions';
import { getAuthDataSelector } from '../../../store/auth/selectors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextEditor from '../../ui/TextEditor/TextEditor';

const InvoicesNotes = ({ id, status, notesList }: any) => {
  const dispatch = useDispatch();

  const authData = useSelector(getAuthDataSelector);
  const [showNotes, setShowNotes] = useState(false);
  const [note, setNote] = useState<string>('');
  const [notes, setNotes] = useState<any>([]);
  const [shouldClearValue, setShouldClearValue] = useState<boolean>(false);

  useEffect(() => {
    if (notesList) {
      if (notesList.length) {
        setShowNotes(true);
      }
      setNotes(JSON.parse(notesList));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesList]);

  const onSetFieldValue = (name: string, value: string) => {
    setNote(value);
  }

  const sendComment = () => {
    const date = new Date().toLocaleString();
    if (note !== '<p></p>' && note !== '' && note !== ' ' && note !== '<p> </p>' && note !== null && note !== undefined && note !== '  ') {
      const comment = {
        date: date,
        user: {
          id: authData?.user_id,
          fullname: authData?.user_fullname,
        },
        message: note,
      }
      setNotes([...notes, comment]);
      setShouldClearValue(true);  
      dispatch(updateARRequestInvoiceRequest({
        ar_id: id,
        data: {
          Status: "returned",
          Notes: JSON.stringify([...notes, comment]),
        }
      }));
    }
    setNote('');
  };

  return (
    <>
      <Typography variant='h4' sx={{ fontSize: 19, mb: 2 }} component='h1'>
        Notes
      </Typography>
      {showNotes ? 
        <>
          <div
            style={{
              height: '380px',
              overflow: 'auto',
              border: '1px solid #F1F1F1',
              padding: '10px',
            }}
          >
            <Grid container wrap='nowrap' spacing={2}>
              <Grid justifyContent='left' item xs zeroMinWidth>
                {notes.map((note: any, index: any) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h4 style={{ margin: 0, textAlign: 'left' }}>
                          {note.user.fullname}
                        </h4>
                        <div style={{ color: 'gray' }}>{note.date}</div>
                      </div>
                      <Typography
                        sx={{ fontSize: '15px' }}
                        dangerouslySetInnerHTML={{ __html: note.message }}
                      />
                      {notes.length > 1 && (
                        <Divider
                          variant='fullWidth'
                          style={{ margin: '15px 0' }}
                        />
                      )}
                    </div>
                  )
                })}
              </Grid>
            </Grid>
          </div>
          {status !== 'completed' &&
            <Box sx={{ mt: '20px' }}>
              <TextEditor
                value={note}
                name='Comment'
                placeholder='White here your notes'
                onSetFieldValue={onSetFieldValue}
                shouldClearValue={shouldClearValue}
                setShouldClearValue={setShouldClearValue}
              />
              <Box sx={{ textAlign: 'right', margin: '10px 0 0 0' }}>
                <Button
                  variant='outlined'
                  sx={{ px: 0.25, marginRight: '10px' }}
                  onClick={() => setShowNotes(false)}
                >
                  Close
                </Button>
                <Button
                  variant='contained'
                  sx={{ px: 0.25 }}
                  onClick={sendComment}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          }
        </>
      : 
        <>
          {!showNotes && status === 'completed' && <p>AR Request completed without notes</p>}
        </>
      }
      {!showNotes && status !== 'completed' && (
        <>
          <p>Here will be the text for the PM</p>
          <Button
            sx={{ margin: '0 10px 0 0' }}
            variant='outlined'
            onClick={() => setShowNotes(true)}
          >
            Add notes
          </Button>
        </>
      )}
    </>
  )
}

export default memo(InvoicesNotes, isEqualPropsMemo);

import React from 'react';

import { useGetPageItemsEffect } from '../utils/hooks';
import { fetchTrainingsRequest } from '../store/trainings/actions';

import TrainingsList from '../components/Trainings/TrainingsList';
import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import TrainingsFilters from '../components/Trainings/TrainingsFilters/TrainingsFilters';

const Trainings: React.FC = (): JSX.Element => {

  useGetPageItemsEffect(fetchTrainingsRequest);  

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: `Trainings`,
      path: '',
    },
  ];

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <TrainingsFilters />
      <TrainingsList />
    </Layout>
  );
};

export default Trainings;
import React from 'react';

interface ITrainingPreviewProps {
  frame: boolean;
  url: string;
}

const TrainingPreview = ({ frame, url }: ITrainingPreviewProps) => {
  return (
    <>
      {
        frame
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          ? <iframe
            width="100%"
            height={500}
            src={url + '?enablejsapi=1&controls=0&rel=0'}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          : <video
            width="100%"
            height={500}
            src={url}
            controls
          />
      }
    </>
  );
};

export default TrainingPreview;
import {
  FETCH_USERS_VIEW_REQUEST,
  FETCH_USERS_VIEW_FAILURE,
  FETCH_USERS_VIEW_SUCCESS,
  UPDATE_USER__REQUEST,
  UPDATE_USER_SUCCESS,
} from './actionTypes';
import {
  IFetchUsersViewRequest,
  IFetchUsersViewSuccess,
  IFetchUsersViewSuccessPayload,
  IFetchUsersViewFailure,
  IFetchUsersViewFailurePayload,
  IFetchUsersViewRequestParams,
  IUpdateUserRequestParams,
  IUpdateUserRequest,
  IUpdateUserSuccessPayload,
  IUpdateUserSuccess,
} from './types';

export const fetchUsersViewRequest = (
  params: IFetchUsersViewRequestParams,
): IFetchUsersViewRequest => ({
  type: FETCH_USERS_VIEW_REQUEST,
  params,
});

export const fetchUsersViewSuccess = (
  payload: IFetchUsersViewSuccessPayload,
): IFetchUsersViewSuccess => ({
  type: FETCH_USERS_VIEW_SUCCESS,
  payload,
});

export const fetchUsersViewFailure = (
  payload: IFetchUsersViewFailurePayload,
): IFetchUsersViewFailure => ({
  type: FETCH_USERS_VIEW_FAILURE,
  payload,
});

export const updateUserRequest = (
  params: IUpdateUserRequestParams,
): IUpdateUserRequest => ({
  type: UPDATE_USER__REQUEST,
  params,
});

export const updateUserSuccess = (
  payload: IUpdateUserSuccessPayload,
): IUpdateUserSuccess => 
({
  type: UPDATE_USER_SUCCESS,
  payload,
});
import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoadingProps {
  size?: number;
  style?: object;
}

const Loading = ({ size = 30, style }: ILoadingProps) => {
  return (
    <Box
      sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}>
      <CircularProgress color="inherit" size={size} />
    </Box>
  );
};

export default Loading;
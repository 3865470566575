import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';

import { getAuthDataSelector } from '../../../../store/auth/selectors';
import { getJobDetailsSelector, getJobsOptionsSelector } from '../../../../store/jobs/selectors';
import { fetchSubJobRequest } from '../../../../store/jobs/actions';
import { InvoicesARRequestFixedPriceValidation, InvoicesARRequestTMValidation, InvoicesARRequestValidation } from '../../../../constants/validations';
import { getFilteredObjectsArray, isEqualPropsMemo } from '../../../../utils/helpers';
import { updateARRequestInvoiceRequest } from '../../../../store/ARRequestInvoices/actions';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import FormLabel from '../../../ui/FormLabel/FormLabel';
import AutocompleteSelect from '../../../ui/AutocompleteSelect/AutocompleteSelect';
import DatePickerRange from '../../../ui/DatePickerRange/DatePickerRange';

const InvoicesARRequestForm = ({ data }:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authData = useSelector(getAuthDataSelector);
  const jobsOptions = getFilteredObjectsArray(useSelector(getJobsOptionsSelector));
  const jobDetails = useSelector(getJobDetailsSelector);

  const [errorAmountRequested, setErrorAmountRequested] = useState<boolean>(false);
  const [errorMessageAmountRequested, setErrorMessageAmountRequested] = useState<string>('');
  const [disabledSubjob, setDisabledSubjob] = useState<boolean>(true);
  const [disabledCustomerPO, setDisabledCustomerPO] = useState<boolean>(true);
  const [subJobsOptions, setSubJobsOptions] = useState([]);
  const [customerPOOptions, setCustomerPOOptions] = useState([]);
  const [jobType, setJobType] = useState<string>('');
  const [amountRemaining, setAmountRemaining] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<string>('');

  const isValidationSchema = () => {
    if (jobType === 'Fixed Price') {
      return InvoicesARRequestFixedPriceValidation
    } else if (jobType === 'T&M') {
      return InvoicesARRequestTMValidation
    } else {
      return InvoicesARRequestValidation
    }
  }

  const formik = useFormik({
    initialValues: {
      invoice_requestor: data?.ReqByName || '',
      invoice_job: data?.Job || '',
      invoice_subjob: data?.SubJob || '',
      invoice_customerPO: data?.CustomerPO || '',
      invoice_jobType: data?.JobType || '',
      invoice_amountRemaining: data?.AmountRemaining || '',
      invoice_amountRequested: String(data?.AmountRequested) || '',
      invoice_dateRange: data?.invoice_dateRange || '',
      DateRangeStart: data?.DateRangeStart || '',
      DateRangeFinish: data?.DateRangeFinish || '',
      invoice_amountInvoiced: String(data?.AmountInvoiced) || '',
      invoice_invoiceNumber: data?.InvoiceNumber || '',
    },
    validationSchema: isValidationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (errorAmountRequested) { return }
      let status = 'propress'
      if (!values.invoice_invoiceNumber.trim().length || !values.invoice_amountInvoiced.trim().length || !checked) {
        status = 'returned'
      }
      if (checked) {
        status = 'completed'
      }
      dispatch(updateARRequestInvoiceRequest({
        // navigate,
        ar_id: data.id,
        data: {
          Job: values.invoice_job,
          SubJob: values.invoice_subjob,
          InvoiceNumber: values.invoice_invoiceNumber,
          Status: status,
          AmountRequested: Number(values.invoice_amountRequested),
          AmountInvoiced: Number(values.invoice_amountInvoiced),
          CustomerPO: values.invoice_customerPO,
          DateRangeStart: values.DateRangeStart,
          DateRangeFinish: values.DateRangeFinish
        }
      }))
    }
  })
  const { values, errors, handleChange, handleSubmit, resetForm, setFieldValue } = formik;
  useEffect(() => {
    if (jobDetails) {
      if (jobDetails.SubJobs) {
        setSubJobsOptions(jobDetails.SubJobs?.map((item: any) => ({ label: item.SubJob, value: item.id })))
        setCustomerPOOptions(jobDetails.CustPO?.map((item: any) => ({ label: item.Description, value: item.Description })))
        if (jobDetails?.JobType === 'T') { setJobType('T&M') }
        if (jobDetails?.JobType === 'F') { setJobType('Fixed Price') }
        setDisabledSubjob(false)
        setDisabledCustomerPO(false)
        if (data.Status === 'completed') {
          setChecked(true)
        }
        if (data.AmountInvoiced === null) {
          setFieldValue('invoice_amountInvoiced', '');
        }
      }
    }

    // eslint-disable-next-line
  }, [jobDetails]);

  useEffect(() => {
    if (values.invoice_customerPO) {
      setAmountRemaining(jobDetails?.CustPO?.filter((item: any) => item.Description === values.invoice_customerPO)?.[0]?.RemainingAmt)
    }
  // eslint-disable-next-line
  }, [values.invoice_customerPO]);

  useEffect(() => {
    setUserRole(authData.user_role)
  }, [authData]);

  const handleChangeJob = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_job', '')
      setFieldValue('invoice_subjob', '')
      setFieldValue('invoice_customerPO', '')
      setDisabledSubjob(true)
      setDisabledCustomerPO(true)
    } else {
      setFieldValue('invoice_job', value);
      setFieldValue('invoice_subjob', '')
      setFieldValue('invoice_customerPO', '')
      setSubJobsOptions([])
      setCustomerPOOptions([])
      dispatch(fetchSubJobRequest({ job: value }));
      setTimeout(() => {
        setDisabledSubjob(false)
        setDisabledCustomerPO(false)
      }, 1000)
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeSubjob = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_subjob', '')
    } else {
      setFieldValue('invoice_subjob', value);      
    }
    // eslint-disable-next-line
  }, []);

  const handleChangePO = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_customerPO', '')
    } else {
      setFieldValue('invoice_customerPO', value);    
    }

    // eslint-disable-next-line
  }, []);
  
  const handleSaveForm = () => {
    if (jobType === 'Fixed Price') {
      if (!values.invoice_amountRequested.length || values.invoice_amountRequested > amountRemaining) {
        setErrorAmountRequested(true)
        if (!values.invoice_amountRequested.length) {
          setErrorMessageAmountRequested('Field is required')
        }
        if (values.invoice_amountRequested > amountRemaining) {
          setErrorMessageAmountRequested(`The amount requested must not exceed ${amountRemaining}`)
        }
      } else {
        handleSubmit()
        setErrorAmountRequested(false);
      }
    }
    if (jobType === 'T&M') {
      if (values.DateRangeStart === null || values.DateRangeFinish === null) {
        setFieldValue('invoice_dateRange', 'true');
      } else {
        setFieldValue('invoice_dateRange', '');
        handleSubmit();
      }
    }
  }

  const handleChangeDatePickerRange = (value: any) => {
    setFieldValue('DateRangeStart', value.startDate);
    setFieldValue('DateRangeFinish', value.endDate);
  }

  return (
    <Grid container sx={{ margin: '0 0 20px 0' }}>
      <Grid item xs={4} md={4}>
        <div style={{width: '425px'}}>
          {/* Requestor */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Requestor" />
            <TextField
              value={authData?.user_fullname}
              disabled={true}
            />
          </FormControl>
          {/* Job */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Job" required />
            <AutocompleteSelect
              name="invoice_job"
              error={Boolean(errors.invoice_job)}
              helperText={errors.invoice_job}
              options={jobsOptions}
              value={values.invoice_job}
              onChange={handleChangeJob}
              disabled={data.Status === 'completed' || userRole === 'user_ar'}
            />
          </FormControl>
          {/* Subjob */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Subjob" required />
            <AutocompleteSelect
              name="invoice_subjob"
              error={Boolean(errors.invoice_subjob)}
              helperText={errors.invoice_subjob}
              options={subJobsOptions}
              value={values.invoice_subjob}
              onChange={handleChangeSubjob}
              disabled={data.Status === 'completed' || disabledSubjob || userRole === 'user_ar'}
            />
          </FormControl>
          {/* Customer PO */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Customer PO" required />
            <AutocompleteSelect
              name="invoice_customerPO"
              error={Boolean(errors.invoice_customerPO)}
              helperText={errors.invoice_customerPO}
              options={customerPOOptions}
              value={values.invoice_customerPO}
              onChange={handleChangePO}
              disabled={data.Status === 'completed' || disabledCustomerPO || userRole === 'user_ar'}
            />
          </FormControl>
          {values.invoice_job && values.invoice_subjob && values.invoice_customerPO &&
            <>
              {/* Job Type */}
              <FormControl fullWidth margin="normal">
                <FormLabel title="Job Type" required />
                <TextField
                  value={jobType}
                  disabled={true}
                />
              </FormControl>
              {
                jobType === 'Fixed Price' &&
                <>
                  {/* Amount remaining */}
                  <FormControl fullWidth margin="normal">
                    <FormLabel title="Amount remaining" />
                    <TextField
                      value={amountRemaining}
                      onChange={handleChange}
                      disabled={data.Status === 'completed' || userRole === 'user_ar'}
                      // disabled={true}
                    />
                  </FormControl>
                  {/* Amount requested */}
                  <FormControl fullWidth margin="normal">
                    <FormLabel title="Amount requested" required />
                    <TextField
                      error={Boolean(errorAmountRequested)}
                      helperText={(errorAmountRequested && errorMessageAmountRequested)}
                      name="invoice_amountRequested"
                      value={values.invoice_amountRequested}
                      onChange={handleChange}
                      disabled={data.Status === 'completed' || userRole === 'user_ar'}
                    />
                  </FormControl>
                </>
              }
              {
                jobType === 'T&M' &&
                <FormControl fullWidth margin="normal">
                  <FormLabel title="Date range" required />
                  <DatePickerRange
                    error={values.invoice_dateRange ? true : false}
                    helperText={values.invoice_dateRange ? 'Field is required' : ''}
                    startDate={data?.DateRangeStart || ''}
                    endDate={data?.DateRangeFinish || ''}
                    onChange={handleChangeDatePickerRange} 
                    disabled={data.Status === 'completed' || userRole === 'user_ar'}
                  />
                </FormControl>
              }
            </>
          }
          {/* Amount Invoiced */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Amount Invoiced" />
            <TextField
              error={Boolean(errors.invoice_amountInvoiced)}
              helperText={errors.invoice_amountInvoiced}
              name="invoice_amountInvoiced"
              value={values.invoice_amountInvoiced}
              onChange={handleChange}
              disabled={data.Status === 'completed'}
            />
          </FormControl>
          {/* Invoice number */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Invoice number" />
            <TextField
              error={Boolean(errors.invoice_invoiceNumber)}
              helperText={errors.invoice_invoiceNumber}
              name="invoice_invoiceNumber"
              value={values.invoice_invoiceNumber}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g,'');
                handleChange(e);
              }}
              disabled={data.Status === 'completed'}
            />
          </FormControl>
          {/* Last updated */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Last updated" />
            <TextField
              value={moment(data.LastUpdated).format('DD.MM.YYYY')}
              disabled={true}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox 
                checked={checked} 
                onChange={(e) => setChecked(e.target.checked)} 
                color="primary" 
                disabled={data.Status === 'completed' || !values.invoice_invoiceNumber.trim().length || !values.invoice_amountInvoiced.trim().length}
              />}
            label="I confirm that I checked the Notes, filled out the information correctly and it is completely true"
          />
          <Stack spacing={2} sx={{ mt: 2 }} direction="row" justifyContent="space-between">
            <Stack spacing={2} direction="row">
              <Button
                onClick={handleSaveForm}
                variant="contained"
                disabled={data.Status === 'completed' || disabledSubjob}
              >
              { checked ? 'Complete' : 'Submit'}
              </Button>
              {/* <Button
                variant="outlined"
                onClick={() => resetForm()}
              >
                Reset
              </Button> */}
            </Stack>
            <Button
              sx={{ margin: '0 10px 0 0' }}
              variant="outlined"
              onClick={() => navigate('/invoices/request')}
            >
              Cancel
            </Button>
          </Stack>
          {data.Status === 'completed' && <Typography sx={{ mt: 2 }}>The Complete button is no longer available because the current Invoice has been completed in full and confirmed by AR.</Typography> }
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(InvoicesARRequestForm, isEqualPropsMemo);
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import TextEditor from '../../ui/TextEditor/TextEditor';
import { updateIssuesRequest } from '../../../store/issues/actions';
import { getCurrentJobSelector } from '../../../store/jobs/selectors';
import { getAuthDataSelector } from '../../../store/auth/selectors';

interface ICommentsProps {
  data: any,
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton component="span" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const imgLink =
  "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

const Comments = ({ data }: ICommentsProps): JSX.Element => {
  const dispatch = useDispatch();
  const job_id = useSelector(getCurrentJobSelector);
  const authData = useSelector(getAuthDataSelector);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [message, setMessage] = useState('');

  const handleToggleExpanded = () => setIsExpanded(!isExpanded);

  const onSetFieldValue = (name: string, value: string) => {
    console.log('onSetFieldValue', value);
    setMessage(value)
  }

  const sendComment = () => {
    // console.log('sendComment - ', message);
    dispatch(updateIssuesRequest({
      job_id,
      ai_id: data?.id,
      data: { 
        comments: [
          {
            date: Date.now(),
            user: {
              id: authData?.user_id,
              fullname: authData?.user_fullname
            },
            message: message
          }
        ]
      },
    }));
    
  }
  return (
    <>
      <Button
        fullWidth
        size="small"
        color="inherit"
        sx={{
          justifyContent: 'space-between',
          p: 0,
        }}
        endIcon={
          <ExpandMore
            expand={isExpanded}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        onClick={handleToggleExpanded}
      >
        Comments <Box sx={{ ml: 'auto' }}>
          {/* {comments.filter(i => i.action !== 'delete').length} */}
          0
          </Box>
      </Button>
      <Divider />
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>

        <Paper style={{ padding: "40px 20px" }}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar alt="Remy Sharp" src={imgLink} />
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
              <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
              <p style={{ textAlign: "left" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                Suspendisse congue vulputate lobortis. Pellentesque at interdum
                tortor. Quisque arcu quam, malesuada vel mauris et, posuere
                sagittis ipsum. Aliquam ultricies a ligula nec faucibus. In elit
                metus, efficitur lobortis nisi quis, molestie porttitor metus.
                Pellentesque et neque risus. Aliquam vulputate, mauris vitae
                tincidunt interdum, mauris mi vehicula urna, nec feugiat quam
                lectus vitae ex.{" "}
              </p>
              <p style={{ textAlign: "left", color: "gray" }}>
                posted 1 minute ago
              </p>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar alt="Remy Sharp" src={imgLink} />
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
              <h4 style={{ margin: 0, textAlign: "left" }}>Michel Michel</h4>
              <p style={{ textAlign: "left" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
                Suspendisse congue vulputate lobortis. Pellentesque at interdum
                tortor. Quisque arcu quam, malesuada vel mauris et, posuere
                sagittis ipsum. Aliquam ultricies a ligula nec faucibus. In elit
                metus, efficitur lobortis nisi quis, molestie porttitor metus.
                Pellentesque et neque risus. Aliquam vulputate, mauris vitae
                tincidunt interdum, mauris mi vehicula urna, nec feugiat quam
                lectus vitae ex.{" "}
              </p>
              <p style={{ textAlign: "left", color: "gray" }}>
                posted 1 minute ago
              </p>
            </Grid>
          </Grid>
        </Paper>

        <Box sx={{ mt: '20px' }}>
          <TextEditor
              value={message}
              name='Comment'
              onSetFieldValue={onSetFieldValue}
            />
            <Box sx={{ textAlign: 'right', margin: '10px 0 0 0' }}>
              <Button 
                variant="contained" 
                sx={{ px: 0.25 }}
                onClick={sendComment}
                >
                Comment
              </Button>
            </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default Comments
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const CONFITM__FORGOT_PASSWORD_REQUEST = 'CONFITM__FORGOT_PASSWORD_REQUEST';
export const CONFITM__FORGOT_PASSWORD_SUCCESS = 'CONFITM__FORGOT_PASSWORD_SUCCESS';
export const CONFITM__FORGOT_PASSWORD_FAILED = 'CONFITM__FORGOT_PASSWORD_FAILED';


export const AUTH_FROM_CACHE_REQUEST = 'AUTH_FROM_CACHE_REQUEST';

export const FETCH_AUTH_DATA = 'FETCH_AUTH_DATA';

export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR';
import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getTrainings = (state: AppState) => state.trainings.items;
const getHasMore = (state: AppState) => state.trainings.has_more;
const getItemView = (state: AppState) => state.trainings.itemView;
const getProfile = (state: AppState) => state.trainings.profile;

export const getTrainingsSelector = createSelector(getTrainings, items => items);
export const getHasMoreSelector = createSelector(getHasMore, has_more => has_more);
export const getTrainingViewSelector = createSelector(getItemView, itemView => itemView);
export const getTrainingProfileSelector = createSelector(getProfile, profile => profile);
export const getTrainingsCategoryListSelector = createSelector(getTrainings, items => items.map((item) => item.name));
export const getTrainingsCategoryOptionsSelector = createSelector(getTrainings, items => items.map((item) => ({
  label: item.name,
  value: item.id,
})));

import * as ACTION_TYPES from './actionTypes';

import { IPosActions, IPosState } from './types';

const initialState: IPosState = {
  items: [],
  has_more: false,
  itemView: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IPosActions)  => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_POS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_TYPES.FETCH_POS_VIEW_SUCCESS:
      return {
        ...state,
        itemView: action.payload,
      };
    default:
      return state;
  }
};
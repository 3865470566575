import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getData = (state: AppState) => state.usersView.data;
const getError = (state: AppState) => state.usersView.error;
const getPending = (state: AppState) => state.usersView.pending;

export const getDataSelector = createSelector(getData, data => data);
export const getErrorSelector = createSelector(getError, error => error);
export const getPendingSelector = createSelector(getPending, pending => pending);

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom'

import { isEqualPropsMemo } from '../../../utils/helpers';


const UsersTable = ({ dataValues} :any ) => {
  const MaterialTable = require("material-table").default;
  const navigate = useNavigate();

  const [muiTableKey, setMuiTableKey] = React.useState(0);
  
  return (
    <MaterialTable 
      title=""
      columns={[
        // Full Name
        { 
          title: 'Full Name', 
          field: 'user_fullname',
          render: (rowData: any) => 
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => navigate(`/personnel/${rowData.id}`)}
            >
              {rowData.user_fullname}
            </span> 
              
        },
        // Phone
        { 
          title: 'Phone', 
          field: 'user_phone',
          // filterComponent: ({ columnDef, onFilterChanged }: any) => (
          //   <input
          //     placeholder="custom"
          //     onChange={(e) => {
          //       // Calling the onFilterChanged with the current tableId and the new value
          //       onFilterChanged(columnDef.tableData.id, e.target.value);
          //     }}
          //   />
          // ),
          render: (rowData: any) => 
            <>
              {
                rowData.user_phone
                ? <>{rowData.user_phone}</>
                : <>&mdash;</>
              }
            </>
        },
        // Email
        { 
          title: 'Email', 
          field: 'user_email' 
        },
        // VistaPoint ID
        { 
          title: 'Vista Point ID', 
          field: 'user_vid' 
        },      
        // Status
        { 
          title: 'Status', 
          field: 'user_status',
          lookup: { 
            true: 'Active', 
            false: 'Inactive', 
          },
          render: (rowData: any) => 
            <>
              {
                rowData?.user_status
                ? <div style={{ color: '#2e7d32' }}>Active</div>
                : <div style={{ color: '#d32f2f' }}>Inactive</div>
              }
            </>
        },
        // Role
        { 
          title: 'Role', 
          field: 'user_role',
          lookup: { 
            'admin': 'Admin', 
            'user_pm': 'PM', 
            'user_super': 'Super', 
            'user_dispatcher': 'Dispatcher', 
            'user_ar': 'AR', 
            'user_training': 'Training' 
          },
          render: (rowData: any) => 
            <>
              {rowData.user_role === 'admin' && <div>Admin</div>}
              {rowData.user_role === 'user_pm' && <div>PM</div>}
              {rowData.user_role === 'user_super' && <div>Super</div>}
              {rowData.user_role === 'user_dispatcher' && <div>Dispatcher</div>}
              {rowData.user_role === 'user_ar' && <div>AR</div>}
              {rowData.user_role === 'user_training' && <div>Training</div>}
            </>
        },
      ]}
      data={dataValues}     
      options={{
        headerStyle: {
          whiteSpace: 'nowrap'
        },
        search: false,
        filtering: true,
        sorting: true,
        paging:true,
        pageSize:10,
        pageSizeOptions:[10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        
        maxBodyHeight: 'calc(100vh - 280px)',
      }}
      actions={[
        {
          icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
          tooltip: "Сlear all filters",
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1);
          }
        }
      ]}
    />
  )
}

export default memo(UsersTable, isEqualPropsMemo);
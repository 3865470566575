import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';
import { isEqualPropsMemo } from '../../../utils/helpers';

interface FieldWithButtonProps {
  type: 'minute(s)' | 'hour(s)' | 'day(s)' | 'month' | 'year(s)';
}

const DurationField = ({ type, ...props }: FieldWithButtonProps | TextFieldProps) => {
  return (
    <Stack direction="row">
      <TextField
        type="number"
        InputProps={{
          endAdornment: <Box sx={{ borderLeft: '1px solid #333', ml: 1, pl: 1 }}>{type}</Box>,
        }}
        {...props}
      />
    </Stack>
  );
};

export default memo(DurationField, isEqualPropsMemo);
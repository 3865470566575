import React from 'react';
import Loading from '../Loading';

const LoadingOverlay: React.FC = (): JSX.Element => {
  return (
    <Loading style={{
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255, 0.5)',
      width: '100%',
      height: '100%',
      zIndex: 10,
    }} />
  );
};

export default LoadingOverlay;
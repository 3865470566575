import React, { memo, useEffect, useState } from 'react';

import { isEqualPropsMemo } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthDataSelector } from '../../../store/auth/selectors';
import { updateManpowerDetailsRequest } from '../../../store/manpower/actions';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextEditor from '../../ui/TextEditor/TextEditor';

const ManpowerNotes = ({ reqId, id, row, status, notesList, setCurrentRow, setState, rows }: any) => {
  const dispatch = useDispatch();
  const authData = useSelector(getAuthDataSelector);
  const [showNotes, setShowNotes] = useState(false);
  const [note, setNote] = useState<string>('');
  const [shouldClearValue, setShouldClearValue] = useState<boolean>(false);
  let notes = notesList && notesList.length ? JSON.parse(notesList) : [];

  useEffect(() => {
    if (notesList) {
      if (notesList.length) {
        setShowNotes(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesList]);

  const onSetFieldValue = (name: string, value: string) => {
    setNote(value);
  };

  const sendComment = () => {
    const date = new Date().toLocaleString();
    if (note !== '<p></p>' && note !== '' && note !== ' ' && note !== '<p> </p>' && note !== null && note !== undefined && note !== '  ') {
      const comment = {
        date: date,
        user: {
          id: authData?.user_id,
          fullname: authData?.user_fullname,
        },
        message: note,
      };
      setShouldClearValue(true);
      setCurrentRow((prev: any) => ({
        ...prev, Notes:JSON.stringify([...notes, comment])
      }));
      const newRow = rows.filter((item: any) => item.id === row?.id);
      const needRow = newRow[0];
      needRow.Notes = JSON.stringify([...notes, comment]);
      delete row.id

      if (row.Cert2 !== '' && row.Cert2 !== ' ' && row.Cert2 !== null && row.Cert2 !== undefined) {
        delete row.Cert2
      }

      dispatch(updateManpowerDetailsRequest({
        mpr_id: reqId,
        dt_id: id,
        data: {...row, Notes:JSON.stringify([...notes, comment])}
      }));
    }
    setNote('');
  };

  return (
    <>
      <Typography variant='h4' sx={{ fontSize: 19, mb: 2 }} component='h1'>
        Notes
      </Typography>
          <div
            style={{
              height: status === 'completed' || status === 'canceled' ? '80vh' : '480px',
              overflow: 'auto',
              border: '1px solid #F1F1F1',
              padding: '10px',
            }}
          >
            <Grid container wrap='nowrap' spacing={2}>
              <Grid justifyContent='left' item xs zeroMinWidth>
                {notes.map((note: any, index: any) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h4 style={{ margin: 0, textAlign: 'left' }}>
                          {note.user.fullname}
                        </h4>
                        <div style={{ color: 'gray' }}>{note.date}</div>
                      </div>
                      <Typography
                        sx={{ fontSize: '15px' }}
                        dangerouslySetInnerHTML={{ __html: note.message }}
                      />
                      {notes.length > 1 && (
                        <Divider
                          variant='fullWidth'
                          style={{ margin: '15px 0' }}
                        />
                      )}
                    </div>
                  )
                })}
              </Grid>
            </Grid>
          </div>
          {(status !== 'completed' && status !== 'canceled') &&
            <Box sx={{ mt: '20px' }}>
              <TextEditor
                value={note}
                name='Comment'
                placeholder='White here your notes'
                onSetFieldValue={onSetFieldValue}
                shouldClearValue={shouldClearValue}
                setShouldClearValue={setShouldClearValue}
              />
              <Box sx={{ textAlign: 'right', margin: '10px 0 0 0' }}>
                <Button
                  variant='outlined'
                  sx={{ px: 0.25, marginRight: '10px' }}
                  onClick={() => {
                    setShowNotes(false);
                    setState(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  variant='contained'
                  sx={{ px: 0.25 }}
                  onClick={sendComment}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          }
    </>
  )
}

export default memo(ManpowerNotes, isEqualPropsMemo);

import React from 'react';
import Layout from '../components/layout';
import PageTitle from '../components/ui/PageTitle';

const Session = () => {
  return (
    <Layout>
      <PageTitle title="Training Calendar" />
    </Layout>
  );
};

export default Session;
import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getNotifyType = (state: AppState) => state.common.notifyType;
const getIsNotifyOpen = (state: AppState) => state.common.isNotifyOpen;
const getNotifyMessage = (state: AppState) => state.common.notifyMessage;
const getIsAsideOpen = (state: AppState) => state.common.isAsideOpen;
const getIsPending = (state: AppState) => state.common.isPending;
const getIsSending = (state: AppState) => state.common.isSending;
const getIsActiveFiltersCollapsed = (state: AppState) => state.common.isActiveFiltersCollapsed;

export const getNotifyTypeSelector = createSelector(getNotifyType, notifyType => notifyType);
export const getIsNotifyOpenSelector = createSelector(getIsNotifyOpen, isNotifyOpen => isNotifyOpen);
export const getNotifyMessageSelector = createSelector(getNotifyMessage, notifyMessage => notifyMessage);
export const getIsAsideOpenSelector = createSelector(getIsAsideOpen, isAsideOpen => isAsideOpen);
export const getIsPendingSelector = createSelector(getIsPending, isPending => isPending);
export const getIsSendingSelector = createSelector(getIsSending, isSending => isSending);
export const getIsActiveFiltersCollapsedSelector = createSelector(getIsActiveFiltersCollapsed, isActiveFiltersCollapsed => isActiveFiltersCollapsed);

import * as ACTION_TYPES from './actionTypes';

import { IUsersActions, IUsersState } from './types';

const initialState: IUsersState = {
  items: []
};

export default function usersReducer(state = initialState, action: IUsersActions) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USERS_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};
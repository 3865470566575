import * as ACTION_TYPES from './actionTypes';
import { merge } from 'lodash';
import { IUsersViewActions, IUsersViewState } from './types';

const initialState: IUsersViewState = {
  pending: false,
  data: {},
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IUsersViewActions) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USERS_VIEW_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ACTION_TYPES.FETCH_USERS_VIEW_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        data: action.payload,
      };
    case ACTION_TYPES.FETCH_USERS_VIEW_FAILURE:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload.error,
      };
      case ACTION_TYPES.UPDATE_USER_SUCCESS:
        return {
          ...state,
          data: merge(state.data, action.payload)
        };
    default:
      return {
        ...state,
      };
  }
};
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoTooltip from '../InfoTooltip';

interface FormLabelProps {
  title: string,
  required?: boolean,
  tooltip?: string,
}

const FormLabel = ({ title, required, tooltip }: FormLabelProps) => {
  return (
    <Typography gutterBottom sx={{ display: 'flex' }}>
      {title}
      {
        required && <Box component="span" sx={{ color: 'red', p: '0 2px' }}>*</Box>
      }
      {
        tooltip && <InfoTooltip title={tooltip} sx={{ ml: 0.5 }} />
      }
    </Typography>
  );
};

export default FormLabel;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Layout from '../components/layout';
import Loading from '../components/ui/Loading/Loading';
import PosTable from '../components/Pos/PosTable/PosTable';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';

import { getJobsSelector } from '../store/jobs/selectors';
import { fetchJobsRequest } from '../store/jobs/actions';

import { fetchPosRequest } from '../store/pos/actions';
import { getPOsSelector } from '../store/pos/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { useGetPageItemsEffect } from '../utils/hooks';

const Pos: React.FC = (): JSX.Element => {
  const pending = useSelector(getIsPendingSelector);
  const items = useSelector(getPOsSelector);
  const jobList = useSelector(getJobsSelector);
  const [job_id, setJob_id] = useState(localStorage.getItem('currentJob'));
  const [breadCrumbsItems, setBreadCrumbsItems] = useState([
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `PO's`,
      path: '',
    },
  ]);

  useGetPageItemsEffect(fetchJobsRequest);

    useEffect(() => {
    if ((!job_id || job_id === undefined || job_id === '' || job_id === null) && jobList.length) {
      localStorage.setItem('currentJob', jobList[0]?.Job)
      setJob_id(jobList[0]?.Job);
      setBreadCrumbsItems([
        {
        title: 'Home',
        path: '/',
        },
        {
          title: `${job_id}`,
          path: `/jobs/${job_id}`
        },
        {
          title: `PO's`,
          path: '',
        },
      ]);
    } else {
      const currentJobId = localStorage.getItem('currentJob');
      if (currentJobId !== null) {
        setJob_id(currentJobId);
        setBreadCrumbsItems([
          {
          title: 'Home',
          path: '/',
          },
          {
            title: `${job_id}`,
            path: `/jobs/${job_id}`
          },
          {
            title: `PO's`,
            path: '',
          },
        ]);
      }
    }
  }, [jobList, job_id])
  
  useGetPageItemsEffect(fetchPosRequest, true);

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="PO's" />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <PosTable dataValues={items} />
      }
    </Layout>
  )
};

export default Pos;

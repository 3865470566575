import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import DocumentPDF from '../components/ui/DocumentPDF/DocumentPDF'

const InvoicesView: React.FC = (): JSX.Element => {
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `AR List`,
      path: '/invoices',
    },
    {
      title: `0-082F`,
      path: '',
    },
  ];
  const pageTitle = `AR Invoice 0-082F`;
  const data = [
    {title: 'Invoice', value: '0-082F'},
    {title: 'Job', value: '023346E-F'},
    {title: 'Subjob', value: 'ex. 02222'},
    {title: 'Created', value: '08/04/2022'},
    {title: 'Due date', value: '12/04/2022'},
    {title: 'Invoice amount', value: '$70000.00'},
    {title: 'Paid Amount', value: '$70000.00'},
    {title: 'Remaining', value: '$0.00'},
    {title: 'Requested by', value: 'Todd Bailey'},
    {title: 'Status', value: 'Open'},
    {title: 'Customer', value: ''},
    {title: 'CustRef', value: ''},
    {title: 'CustPO', value: ''},
    {title: 'PayTerms', value: '$70000.00'},
    {title: 'Transaction date', value: '12/04/2022'},
    {title: 'Last update', value: '09/04/2022'}
  ]
  
  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle goBack title={pageTitle} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <div>
                {
                  data.map((item) => (
                    <React.Fragment key={item.title}>
                      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                        <Typography color="black" style={{ width: '200px' }}>{item.title}</Typography>
                        <TextField style={{ width: '360px' }} disabled value={item.value} />
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <DocumentPDF />
        </Grid>
      </Grid>
    </Layout>
  )
};

export default InvoicesView;

import * as ACTION_TYPES from './actionTypes';
import { merge } from 'lodash';

import { IARRequestInvoiceActions, IARRequestInvoicesState } from './types';

const initialState: IARRequestInvoicesState = {
  items: [],
  itemView: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IARRequestInvoiceActions)  => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_AR_REQUEST_INCOICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case ACTION_TYPES.VIEW_AR_REQUEST_INCOICE_SUCCESS:
      return {
        ...state,
        itemView: action.payload,
      };
      case ACTION_TYPES.UPDATE_AR_REQUEST_INCOICE_SUCCESS:
        return {
          ...state,
          itemView: merge(state.itemView, action.payload),
        };
    default:
      return state;
  }
};
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ISelectOptions } from '../../../types/common';
import { isEqualPropsMemo } from '../../../utils/helpers';

interface IAutocompleteProps {
  readOnly?: boolean,
  error?: boolean,
  helperText?: string | any | undefined,
  name: string,
  label?: string,
  disabled?: boolean,
  value?: string,
  options: ISelectOptions[],
  onSetFieldChange?: (name: string, value: any) => void,
  onChange?: (value: string) => void;
}

const AutocompleteSelect = (
  {
    name,
    label,
    error,
    readOnly,
    helperText,
    disabled,
    value,
    options,
    onChange,
    onSetFieldChange,
  }: IAutocompleteProps) => {
  const [_value, setValue] = useState<any>('');

  useEffect(() => {
    setValue(value ? options.find(option => option.value === value) : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [value, options]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    if (onChange) {
      if (newValue === null) {
        onChange('');
        if (onSetFieldChange) {
          onSetFieldChange(name, '');
        }
      } else {
        onChange(newValue.value);
        if (onSetFieldChange) {
          onSetFieldChange(name, newValue.value);
        }
      }
    }
  };

  return (
    <Autocomplete
      disablePortal
      options={options}
      readOnly={readOnly}
      disabled={disabled}
      value={_value}
      onChange={handleChange}
      renderInput={(params) =>
        <TextField
          name={name}
          label={label}
          error={error}
          helperText={helperText}
          {...params}
        />}
    />
  );
};

export default React.memo(AutocompleteSelect, isEqualPropsMemo);
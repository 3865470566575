import * as ACTION_TYPES from './actionTypes';

import { ITrainingsActions, ITrainingsState } from './types';

const initialState: ITrainingsState = {
  items: [],
  has_more: false,
  itemView: {},
  profile: [],
};

export default function trainingsReducer(state = initialState, action: ITrainingsActions) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_TYPES.FETCH_TRAININGS_VIEW_SUCCESS:
      return {
        ...state,
        itemView: action.payload,
      };
    case ACTION_TYPES.FETCH_TRAININGS_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};
import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import { getIsPendingSelector } from '../store/common/selectors';
import { useGetPageItemsEffect } from '../utils/hooks';
import { fetchAPInvoiceRequest } from '../store/APInvoice/actions';
import { getAPInvoiceSelector } from '../store/APInvoice/selectors';
import Loading from '../components/ui/Loading/Loading';

const InvoicesAP: React.FC = (): JSX.Element => {
  const MaterialTable = require("material-table").default;
  const navigate = useNavigate();
  const pending = useSelector(getIsPendingSelector);
  const items = useSelector(getAPInvoiceSelector);
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const job_id = localStorage.getItem('currentJob');
  // const invoices = [
  //   {id: 116, Job: "023346E-F", Subjob: "023346E-01F", Invoice: "0-082F", invoiceAmount: 70000.00, InvoiceDate: "12.04.2022", approvedDate: "12.04.2022", paidDate: "12.04.2022", Vendor: "TECO - Big Bend", Status: "Open"},  
  //   {id: 117, Job: "023346E-F", Subjob: "023346E-02F", Invoice: "0-083F", invoiceAmount: 86000.00, InvoiceDate: "09.04.2022", approvedDate: "09.04.2022", paidDate: "09.04.2022", Vendor: "TECO - Big Bend", Status: "Open"}, 
  //   {id: 118, Job: "012346E-F", Subjob: "023346E-03F", Invoice: "0-084F", invoiceAmount: 12000.00, InvoiceDate: "08.04.2022", approvedDate: "08.04.2022", paidDate: "08.04.2022", Vendor: "TECO - Big Bend", Status: "Open"}, 
  //   {id: 119, Job: "12906E-E", Subjob: "12906E-01E", Invoice: "0-085F", invoiceAmount: 32000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"}, 
  //   {id: 120, Job: "12906E-E", Subjob: "12906E-02E", Invoice: "0-086F", invoiceAmount: 41000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"},
  //   {id: 118, Job: "012346E-F", Subjob: "023346E-03F", Invoice: "0-087F", invoiceAmount: 12000.00, InvoiceDate: "08.04.2022", approvedDate: "08.04.2022", paidDate: "08.04.2022", Vendor: "TECO - Big Bend", Status: "Open"}, 
  //   {id: 119, Job: "12906E-E", Subjob: "12906E-01E", Invoice: "0-088F", invoiceAmount: 32000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"}, 
  //   {id: 120, Job: "12906E-E", Subjob: "12906E-02E", Invoice: "0-089F", invoiceAmount: 41000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"},
  //   {id: 118, Job: "012346E-F", Subjob: "023346E-03F", Invoice: "0-090F", invoiceAmount: 12000.00, InvoiceDate: "08.04.2022", approvedDate: "08.04.2022", paidDate: "08.04.2022", Vendor: "TECO - Big Bend", Status: "Open"}, 
  //   {id: 119, Job: "12906E-E", Subjob: "12906E-01E", Invoice: "0-091F", invoiceAmount: 32000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"}, 
  //   {id: 120, Job: "12906E-E", Subjob: "12906E-02E", Invoice: "0-092F", invoiceAmount: 41000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"},
  //   {id: 118, Job: "012346E-F", Subjob: "023346E-03F", Invoice: "0-093F", invoiceAmount: 12000.00, InvoiceDate: "08.04.2022", approvedDate: "08.04.2022", paidDate: "08.04.2022", Vendor: "TECO - Big Bend", Status: "Open"}, 
  //   {id: 119, Job: "12906E-E", Subjob: "12906E-01E", Invoice: "0-094F", invoiceAmount: 32000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"}, 
  //   {id: 120, Job: "12906E-E", Subjob: "12906E-02E", Invoice: "0-095F", invoiceAmount: 41000.00, InvoiceDate: "07.04.2022", approvedDate: "07.04.2022", paidDate: "07.04.2022", Vendor: "DiversiTech", Status: "Closed"}
  // ];

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `${job_id}`,
      path: `/jobs/${job_id}`
    },
     {
      title: `AP List`,
      path: '',
    },
  ];
  const pageTitle = `AP List`;

  useGetPageItemsEffect(fetchAPInvoiceRequest, true);
  
  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title={pageTitle} />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <MaterialTable
            title=""
            columns={[
              // ID
              // { 
              //   title: 'ID', 
              //   field: 'id',
              //   align: 'left',
              //   render: (rowData: any) =>
              //     <div 
              //       style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'left' }} 
              //       onClick={() => navigate(`/invoices/ap/116`)}
              //     >
              //       {rowData.id}
              //     </div> 
              // },   
              // Invoice
              { 
                title: 'Invoice #', 
                field: 'Invoice',
                align: 'left',
                render: (rowData: any) =>
                <div 
                  style={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer', textAlign: 'left' }} 
                  onClick={() => navigate(`/invoices/ap/${rowData.Invoice}`)}>
                  {rowData.Invoice}
                </div> 
              }, 
              // Job #
              { 
                title: 'Job #', 
                field: 'Job',
                align: 'left',
                render: (rowData: any) =>
                  <div style={{  whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer', textAlign: 'left' }}>
                    {rowData.Job}
                  </div> 
              }, 
              // Subjob
              { 
                title: 'Subjob', 
                field: 'SubJob',
                align: 'left',
                render: (rowData: any) =>
                <div style={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer', textAlign: 'left' }} >
                  {rowData.SubJob}
                </div> 
              },   
              // Status
              { 
                title: 'Status', 
                field: 'Status', 
                align: 'left',
                lookup: { 'Open': 'Open', 'Closed': 'Closed' },
                render: (rowData: any) =>
                  <div style={{textAlign: 'left'}}>
                    {rowData.Status === 'Closed' && <div style={{ color: '#d32f2f' }}>Closed</div>}
                    {rowData.Status === 'Open' && <div style={{ color: '#2e7d32' }}>Open</div>}
                    {rowData.Status === 'Reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
                    {rowData.Status === 'Progress' && <div style={{ color: '#19857b' }}>Progress</div>}
                    {rowData.Status === 'Resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
                    {rowData.Status === 'Duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
                  </div>
              },
              // Vendor 
              { 
                title: 'Vendor', 
                field: 'Vendor',
                align: 'left',
                render: (rowData: any) =>
                <div style={{whiteSpace: 'nowrap', textAlign: 'left'}} >
                  {rowData.Vendor}
                </div>  
              }, 
              // invoiceAmount
              { 
                title: 'invoice Amount', 
                field: 'InvoiceAmount',
                align: 'left',
                render: (rowData: any) =>
                <div style={{textAlign: 'left'}}>
                  {
                    (rowData.InvoiceAmount).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                  }
                </div>
              }, 
              // InvoiceDate
              { 
                title: 'Invoice Date', 
                field: 'InvoiceDate',
                align: 'left',
                render: (rowData: any) =>
                  <div style={{textAlign: 'left'}}>
                    {rowData.InvoiceDate}
                  </div> 
              }, 
              // Approved Date
              { 
                title: 'Approved Date', 
                field: 'ApprovedDate',
                align: 'left',
                render: (rowData: any) =>
                  <div style={{textAlign: 'left'}}>
                    {rowData.ApprovedDate}
                  </div> 
              }, 
              // Paid Date
              { 
                title: 'Paid Date', 
                field: 'PaidDate',
                align: 'left',
                render: (rowData: any) =>
                  <div style={{textAlign: 'left'}}>
                    {rowData.PaidDate}
                  </div> 
              }, 
            ]}
            data={items?.sort((a: any, b: any) => (a.Status === 'Open')? -1 : 1)}     
            options={{
              headerStyle: {
                whiteSpace: 'nowrap'
              },
              search: false,
              filtering: true,
              sorting: true,
              paging:true,
              pageSize:10,
              pageSizeOptions:[10, 25, 50, 100],
              showTitle: false,
              toolbar: true,
              maxBodyHeight: 'calc(100vh - 280px)',
            }}
            actions={[
              {
                icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
                tooltip: "Сlear all filters",
                isFreeAction: true,
                onClick: () => {
                  setMuiTableKey(muiTableKey + 1);
                }
              }
            ]}
          />
      }
    </Layout>
  )
};

export default InvoicesAP;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getIsPendingSelector } from '../store/common/selectors';
import { getCurrentJobSelector } from '../store/jobs/selectors';
import { getPoViewSelector } from '../store/pos/selectors';
import { viewPosRequest } from '../store/pos/actions';

import Layout from '../components/layout/Layout';
import PageTitle from '../components/ui/PageTitle';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import DocumentPDF from '../components/ui/DocumentPDF/DocumentPDF'

const TrainingsView: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const data = useSelector(getPoViewSelector);
  const job_id = useSelector(getCurrentJobSelector);

  useEffect(() => {
    if (id && job_id) {      
      dispatch(viewPosRequest({ po_id: id!, job_id }));
    }
    // eslint-disable-next-line
  }, [id, job_id]);

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: `PO's`,
      path: `/${pathnames[0]}`,
    },
    {
      title: `${data?.PO}`,
      path: ''
    },
  ];

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle goBack title={`PO ${data?.PO}`} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>PO</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.PO} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Vendor</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.VendorName} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Description</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.Description} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Status</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.Status} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Original</Typography>
                <TextField style={{ width: '360px' }} disabled value={`$${data.Original}`} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>CO Amount</Typography>
                <TextField style={{ width: '360px' }} disabled value={`$${data.COAmount}`} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Amount invoiced</Typography>
                <TextField style={{ width: '360px' }} disabled value={`$${data.Invoiced}`} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Revised cost</Typography>
                <TextField style={{ width: '360px' }} disabled value={`$${data.RemainderCost}`} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>PM</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.PM} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
                <Typography color="black" style={{ width: '200px' }}>Days open</Typography>
                <TextField style={{ width: '360px' }} disabled value={data.DaysOpen} />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <DocumentPDF />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default TrainingsView;
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { ArrowDropDown } from '@mui/icons-material';
import Box from '@mui/material/Box';

interface SelectWithInputProps {
  name: string,
  options: string[];
  onChange: (e: React.ChangeEvent) => void;
  onSetValue: (v: string) => void,
}

const SelectWithInput = ({ options, name, onChange, onSetValue, ...props }: SelectWithInputProps & TextFieldProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChooseOption = (v: string) => {
    handleClose();
    onSetValue(v);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        fullWidth
        name={name}
        onChange={onChange}
        InputProps={{
          endAdornment: <IconButton onClick={handleClick}>
            <ArrowDropDown />
          </IconButton>,
        }}
        {...props}
      />
      <Menu
        open={open}
        disablePortal
        PaperProps={{
          sx: {
            maxWidth: 360,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        {options.map((item, index) => (
          <MenuItem key={`${item}-${index}`} onClick={() => handleChooseOption(item)}>{item}</MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SelectWithInput;
import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getIssues = (state: AppState) => state.issues.items;
const getHasMore = (state: AppState) => state.issues.has_more;
const getItemView = (state: AppState) => state.issues.itemView;

export const getIssuesSelector = createSelector(getIssues, items => items);
export const getHasMoreSelector = createSelector(getHasMore, has_more => has_more);
export const getIssueViewSelector = createSelector(getItemView, itemView => itemView);

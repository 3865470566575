export const FETCH_AR_REQUEST_INCOICE_REQUEST = 'FETCH_AR_REQUEST_INCOICE_REQUEST';
export const FETCH_AR_REQUEST_INCOICE_SUCCESS = 'FETCH_AR_REQUEST_INCOICE_SUCCESS';

export const VIEW_AR_REQUEST_INCOICE_REQUEST = 'VIEW_AR_REQUEST_INCOICE_REQUEST';
export const VIEW_AR_REQUEST_INCOICE_SUCCESS = 'VIEW_AR_REQUEST_INCOICE_SUCCESS';

export const CREATE_AR_REQUEST_INCOICE_REQUEST = 'CREATE_AR_REQUEST_INCOICE_REQUEST';
export const CREATE_AR_REQUEST_INCOICE_SUCCESS = 'CREATE_AR_REQUEST_INCOICE_SUCCESS';

export const UPDATE_AR_REQUEST_INCOICE_REQUEST = 'UPDATE_AR_REQUEST_INCOICE_REQUEST';
export const UPDATE_AR_REQUEST_INCOICE_SUCCESS = 'UPDATE_AR_REQUEST_INCOICE_SUCCESS';
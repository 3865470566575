import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Layout from '../components/layout/Layout';
import Button from '@mui/material/Button';

import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import ManpowerTable from '../components/Manpower/ManpowerTable/ManpowerTable';
import Loading from '../components/ui/Loading/Loading';

import { getUserRoleSelector } from '../store/auth/selectors';
import { getManpowerListSelector } from '../store/manpower/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { getJobsSelector } from '../store/jobs/selectors';

import { useGetPageItemsEffect } from '../utils/hooks';

import { fetchManpowerRequest } from '../store/manpower/actions';
import { fetchJobsRequest } from '../store/jobs/actions';

const Manpower: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRoleSelector);
  const manpowerList = useSelector(getManpowerListSelector);
  const pending = useSelector(getIsPendingSelector);
  const [searchParams] = useSearchParams();
  const jobList = useSelector(getJobsSelector);
  const [job_id, setJob_id] = useState(localStorage.getItem('currentJob'));
  const jobParams = searchParams.get('job');
  const [breadCrumbsItems, setBreadCrumbsItems] = useState([
    {
    title: 'Home',
    path: '/',
    },
    {
      title: 'Manpower List',
      path: '',
    }
  ]);

  useGetPageItemsEffect(fetchJobsRequest);

  useEffect(() => {
    if ((!job_id || job_id === undefined || job_id === '' || job_id === null) && jobList.length) {
      localStorage.setItem('currentJob', jobList[0]?.Job)
      setJob_id(jobList[0]?.Job);
    } else {
      const currentJobId = localStorage.getItem('currentJob');
      if (currentJobId !== null) {
        setJob_id(currentJobId);
      }
    }
  }, [jobList, job_id, jobParams])

  useEffect(() => {
    if (jobParams) {
      setJob_id(jobParams);
      setBreadCrumbsItems([
        {
        title: 'Home',
        path: '/',
        },
        {
          title: `${job_id}`,
          path: `/jobs/${job_id}`
        },
        {
          title: 'Manpower List',
          path: '',
        },
      ])
    } else {
      setBreadCrumbsItems([
        {
        title: 'Home',
        path: '/',
        },
        {
          title: 'Manpower List',
          path: '',
        }
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobParams])

  useEffect(() => {
    dispatch(fetchManpowerRequest());
  }, [dispatch]);

  const pageTitle = 'Manpower List';

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle title={pageTitle} />
        {(userRole === 'admin' ||
          userRole === 'user_pm' ||
          userRole === 'user_super') && (
          <Button variant='contained' onClick={() => navigate(`/manpower/new`)}>
            New Manpower request
          </Button>
        )}
      </div>
      {pending ? (
        <Loading style={{ height: 'calc(100vh - 170px);' }} />
      ) : (
        <ManpowerTable manpowers={manpowerList} jobParams={jobParams}/>
      )}
    </Layout>
  )
}

export default Manpower

import { createTheme } from '@mui/material/styles';

const media = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      light: '#4e94fd',
      main: '#0d6efd',
      dark: '#0b5ed7',
    },
    secondary: {
      main: '#19857b',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          minHeight: '100vh',
        },
        body: {
          color: 'rgba(0,0,0,.87)',
          fontFamily: '\'Rubik\', sans-serif',
          background: 'linear-gradient(180deg,rgba(0,87,255,0),rgba(7,31,79,.1)),#fff',
        },
        '::-webkit-scrollbar': {
          width: 10,
          height: 10,
        },
        '::-webkit-scrollbar-track': {
          background: 'rgba(0,0,0,.1)',
          borderRadius: 0,
        },
        '::-webkit-scrollbar-thumb': {
          cursor: 'pointer',
          borderRadius: 5,
          background: 'rgba(0,0,0,.25)',
          boxShadow: 'inset 0 0 16px #7c9cbf',
          transition: 'color .2s ease',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [media.breakpoints.up('sm')]: {
            minHeight: 56,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: '0 10px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px',
          color: '#2c2738',
          fontSize: 14,
        },
        head: {
          borderBottomColor: '#2c2738',
        },
        body: {
          borderBottomColor: 'rgba(0,0,0,0.075)',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: '#2c2738',
          fontWeight: 500,
          fontSize: 14,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          [media.breakpoints.up('sm')]: {
            minHeight: 40,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          '&.active': {
            backgroundColor: '#056cf2',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
          color: 'inherit',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          '&.active': {
            backgroundColor: '#056cf2',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
});

export default theme;
import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  trainingOnlineContentTypes,
  trainingsLanguageOptions,
  trainingsLevelOptions,
  trainingsStatusOptions,
  trainingsTypeOptions,
} from '../../../constants/selectOptions';
import { TrainingFormValidation } from '../../../constants/validations';
import { getTrainingsCategoryListSelector } from '../../../store/trainings/selectors';
import { createTrainingsRequest, updateTrainingsRequest } from '../../../store/trainings/actions';
import { getIsSendingSelector } from '../../../store/common/selectors';
import { capitalizeFirstLetter, getObjectsDifference } from '../../../utils/helpers';
import {
  ETrainingOnlineContentQuestionTypes,
  ETrainingOnlineContentType,
  ETrainingTypes, ITrainingItem,
  ITrainingOnlineContentQuestion,
  ITrainingOnlineContentQuestionAnswer,
} from '../../../types/trainings';
import GetDurationInSeconds from '../../ui/GetDurationInSeconds';
import LoadingOverlay from '../../ui/LoadingOverlay';
import FileUploadField from '../../ui/FileUploadField';
import SelectWithInput from '../../ui/SelectWithInput';
import SelectField from '../../ui/SelectField';
import ButtonMenu from '../../ui/ButtonMenu';
import TextEditor from '../../ui/TextEditor';
import FormLabel from '../../ui/FormLabel';
import TrainingFormQuestion from './TrainingFormQuestion';
import TrainingFormVideo from './TrainingFormVideo';

const newQuestion = (id: number): ITrainingOnlineContentQuestion => ({
  id,
  question: '',
  type: ETrainingOnlineContentQuestionTypes.select,
  answers: [{ id: 0, answer: '', correct: false }],
});

const newAnswer = (id: number): ITrainingOnlineContentQuestionAnswer => ({ id, answer: '', correct: false });

interface ITrainingsFormProps {
  edit?: boolean;
  data?: ITrainingItem;
  onCancelEdit?: () => void;
}

const TrainingsForm = ({ edit, data, onCancelEdit }: ITrainingsFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sending = useSelector(getIsSendingSelector);
  const categoryList = useSelector(getTrainingsCategoryListSelector);
  const [activeConcept, setActiveConcept] = useState(0);

  const formik = useFormik({
    initialValues: data || {
      name: '',
      category: '',
      description: '',
      type: '',
      language: '',
      duration: 0,
      status: '',
      level: '',
      certificate_validity: 0,
      url: '',
      content: '',
      online_content: [
        {
          id: 0,
          title: 'Configuration!',
          type: 'text',
          text_content: 'You have successfully passed the training.\n' +
            'Please push "Submit" button below to save your results.\n' +
            'Your will see the certificate in your training profile.',
          questions: [],
        },
      ],
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: TrainingFormValidation,
    onSubmit: (values: any) => {      
      if (edit && data) {
        dispatch(updateTrainingsRequest({
          id: data.id,
          data: getObjectsDifference(values, data),
        }));
        onCancelEdit!();
      } else {
        dispatch(createTrainingsRequest({ navigate, data: values }));
      }
    },
  });

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;

  const handleAddConceptType = useCallback((type: string) => {
    const id = values.online_content.length;
    const typeData = type === ETrainingOnlineContentType.text
      ? { id, type, title: 'Text Block Title', text_content: '' }
      : type === ETrainingOnlineContentType.video
        ? { id, type, title: 'Video Block Title', url: '', link: '', filename: '' }
        : {
          id,
          type,
          title: 'Quiz Block Title',
          pass_criteria: 0,
          questions: [newQuestion(0)],
        };
    setFieldValue('online_content', [...values.online_content, typeData]);

    // eslint-disable-next-line
  }, [values.online_content]);

  const handleRemoveConceptType = useCallback((index: number) => {
    const newData = [...values.online_content];
    newData.splice(index, 1);
    setFieldValue('online_content', newData);

    // eslint-disable-next-line
  }, [values.online_content]);

  const handleAddQuestion = useCallback((id: number) => {
    const newData = get(values, `online_content[${activeConcept}].questions`);
    newData.push(newQuestion(id));
    setFieldValue(`online_content[${activeConcept}].questions`, newData);

    // eslint-disable-next-line
  }, [values.online_content, activeConcept]);

  const handleRemoveQuestion = useCallback((qIndex: number) => {
    const newData = get(values, `online_content[${activeConcept}].questions`);
    newData.splice(qIndex, 1);
    setFieldValue(`online_content[${activeConcept}].questions`, newData);

    // eslint-disable-next-line
  }, [values.online_content, activeConcept]);

  const handleChangeAnswerCorrect = useCallback((qIndex: number, aIndex: number, type: ETrainingOnlineContentQuestionTypes, value: boolean) => {
    const newData = get(values, `online_content[${activeConcept}].questions[${qIndex}].answers`);
    if (type === ETrainingOnlineContentQuestionTypes.multy) {
      newData[aIndex].correct = value;
    } else {
      newData.forEach((d: ITrainingOnlineContentQuestionAnswer, i: number) => {
        d.correct = aIndex === i ? value : false;
      });
    }
    setFieldValue(`online_content[${activeConcept}].questions[${qIndex}].answers`, newData);

    // eslint-disable-next-line
  }, [values.online_content, activeConcept]);

  const handleAddAnswer = (qIndex: number, id: number) => {
    const newData = get(values, `online_content[${activeConcept}].questions[${qIndex}].answers`);
    newData.push(newAnswer(id));
    setFieldValue(`online_content[${activeConcept}].questions[${qIndex}].answers`, newData);
  };

  const handleRemoveAnswer = (qIndex: number, id: number) => {
    const newData = get(values, `online_content[${activeConcept}].questions[${qIndex}].answers`);
    const newDataList = newData.filter((item: any) => item.id !== id)
    setFieldValue(`online_content[${activeConcept}].questions[${qIndex}].answers`, newDataList);
  }

  const handleUploadCertificateTemplate = (file: any) => {
    setFieldValue('certificate_template', {
      file,
      filename: file.name,
      url: URL.createObjectURL(file),
    });
  };

  return (
    <Card>
      <CardContent sx={{ position: 'relative' }}>
        <form onSubmit={handleSubmit}>
          {
            sending && <LoadingOverlay />
          }
          <Box sx={{ textAlign: 'right' }}>
            {
              onCancelEdit && <Button onClick={onCancelEdit}>Cancel</Button>
            }
            <Button
              type="submit"
              variant="contained"
              disabled={data ? isEmpty(getObjectsDifference(values, data!)) : false}
            >
              {edit ? 'Save changes' : 'Create'}
            </Button>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography>Overview</Typography>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel title="Name" required tooltip="This is the name of the training course" />
                    <TextField
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel title="Training Category" required tooltip="This is the name of the training course" />
                    <SelectWithInput
                      error={Boolean(errors.category)}
                      helperText={errors.category}
                      name="category"
                      value={values.category}
                      options={categoryList}
                      onChange={handleChange}
                      onSetValue={v => setFieldValue('category', v)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl margin="normal" fullWidth>
                <FormLabel title="Description" required />
                <TextField
                  multiline
                  rows={15}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Properties</Typography>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel
                      required
                      title="Training Type"
                      tooltip="This is a user defined field which can be used to categorize trainings by their type"
                    />
                    <SelectField
                      name="type"
                      readOnly={edit}
                      error={Boolean(errors.type)}
                      helperText={errors.type}
                      value={values.type}
                      options={trainingsTypeOptions}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel
                      title="Level"
                      tooltip="Preloaded and generally Introductory, Intermediate or Advanced"
                    />
                    <SelectField
                      name="level"
                      value={values.level}
                      options={trainingsLevelOptions}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel
                      title="Language"
                      required
                    />
                    <SelectField
                      name="language"
                      error={Boolean(errors.language)}
                      helperText={errors.language}
                      value={values.language}
                      options={trainingsLanguageOptions}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" fullWidth>
                    <FormLabel
                      title="Status"
                      required
                    />
                    <SelectField
                      name="status"
                      error={Boolean(errors.status)}
                      helperText={errors.status}
                      value={values.status}
                      options={trainingsStatusOptions}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth margin="normal">
                <FormLabel title="Duration" required />
                <GetDurationInSeconds
                  value={values.duration}
                  error={Boolean(errors.duration)}
                  helperText={errors.duration}
                  days
                  hours
                  minutes
                  onChange={v => setFieldValue('duration', v)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormLabel title="Certificate validity" required />
                <GetDurationInSeconds
                  value={values.certificate_validity}
                  error={Boolean(errors.certificate_validity)}
                  helperText={errors.certificate_validity}
                  years
                  month
                  onChange={v => setFieldValue('certificate_validity', v)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormLabel title="Certificate template"
                           tooltip='The PDF form which is filled automatically when user completes training. Must contain "date" and "employee_name" fields.' />
                <FileUploadField
                  accept="application/pdf,application/vnd.ms-excel"
                  onChange={handleUploadCertificateTemplate}
                  buttonColor="inherit"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel title="Content" />
              <Divider sx={{ mb: 2 }} />
              {
                values.type === ETrainingTypes.online
                  ? <Grid container spacing={5}>
                    <Grid item xs={12} sm={4} md={3}>
                      <Typography gutterBottom align="center">
                        On-line Training Content
                      </Typography>
                      {
                        values.online_content.map((concept, index) => (
                          <Box sx={{
                            px: 1,
                            py: 0.5,
                            mb: 1,
                            cursor: 'pointer',
                            backgroundColor: index === activeConcept ? 'rgba(0,0,0,.05)' : '#fff',
                            padding: '0 5px 9px 5px',
                            borderRadius: '4px'
                          }}
                               onClick={() => setActiveConcept(index)}
                               key={index}
                          >
                            <FormHelperText sx={{ pl: 2, paddingLeft: '20px' }}>{capitalizeFirstLetter(concept.type)} Block</FormHelperText>
                            <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                              <span>
                                {index + 1}.
                              </span>
                              <TextField
                                sx={{ flexGrow: 1 }}
                                name={`online_content[${index}].title`}
                                value={concept.title}
                                onChange={handleChange}
                              />
                              <IconButton size="small" color="error" onClick={() => handleRemoveConceptType(index)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Stack>
                          </Box>
                        ))
                      }
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      {
                        <>
                          <Typography gutterBottom align="center">
                            {values.online_content[activeConcept].title}
                          </Typography>
                          {
                            values.online_content[activeConcept].type === ETrainingOnlineContentType.text &&
                            <TextEditor
                              name={`online_content[${activeConcept}].text_content`}
                              value={values.online_content[activeConcept].text_content}
                              onSetFieldValue={setFieldValue}
                            />
                          }
                          {
                            values.online_content[activeConcept].type === ETrainingOnlineContentType.video &&
                            <TrainingFormVideo
                              data={values.online_content[activeConcept]}
                              active={activeConcept}
                              setFieldValue={setFieldValue}
                            />
                          }
                          {
                            values.online_content[activeConcept].type === ETrainingOnlineContentType.quiz &&
                            <TrainingFormQuestion
                              active={activeConcept}
                              questions={values.online_content[activeConcept].questions || []}
                              onChange={handleChange}
                              onAddAnswer={handleAddAnswer}
                              onRemoveAnswer={handleRemoveAnswer}
                              onAddQuestion={handleAddQuestion}
                              onRemoveQuestion={handleRemoveQuestion}
                              onSetFieldValue={setFieldValue}
                              onChangeAnswerCorrect={handleChangeAnswerCorrect}
                            />
                          }
                        </>
                      }
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                      <ButtonMenu
                        buttonText="Add new"
                        options={trainingOnlineContentTypes}
                        onChange={handleAddConceptType}
                      />
                    </Grid>
                  </Grid>
                  : <>
                    <FormControl margin="normal" fullWidth>
                      <TextEditor
                        label=""
                        name="content"
                        value={values.content}
                        onSetFieldValue={setFieldValue}
                      />
                    </FormControl>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        name="url"
                        label="URL"
                        placeholder="Link to some external resources"
                        value={values.url}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </>
              }
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default TrainingsForm;
import { all, put, call, takeLatest } from 'redux-saga/effects';

import { fetchPosSuccess, viewPosSuccess } from './actions';
import { FETCH_POS_REQUEST, FETCH_POS_VIEW_REQUEST } from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
import { IFetchPosRequest, IViewPosRequest } from './types';
import { setPending } from '../common/actions';

function* fetchPosSaga({ params }: IFetchPosRequest): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${params.job}/pos`, params: params.params });
    yield put(fetchPosSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* viewPosSaga({ params }: IViewPosRequest): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${params.job_id}/pos/${params.po_id}` });    
    yield put(viewPosSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* posSaga() {
  yield all([
    takeLatest(FETCH_POS_REQUEST, fetchPosSaga),
    takeLatest(FETCH_POS_VIEW_REQUEST, viewPosSaga),
  ]);
}

export default posSaga;
import React, { ReactChildren, ReactChild } from 'react';
import Loading from '../ui/Loading/Loading';
import AppBar from './AppBar';
import Aside from './Aside';
import { Wrapper, WrapperInner, MainContent } from './styles';

interface ILayoutProps {
  pending?: boolean,
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[],
}

const Layout = ({ pending, children }: ILayoutProps) => {
  return (
    <Wrapper>
      <AppBar />
      <WrapperInner>
        <Aside />
        {
          pending
            ? <Loading />
            : <MainContent>
              {children}
            </MainContent>
        }
      </WrapperInner>
    </Wrapper>
  );
};

export default Layout;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import CreateInvoicesARRequestForm from '../components/Invoices/InvoicesARRequest/CreateInvoicesARRequestForm/CreateInvoicesARRequestForm';

import { getIsPendingSelector } from '../store/common/selectors';
import { getCurrentJobSelector } from '../store/jobs/selectors';
import { fetchJobsRequest } from '../store/jobs/actions';

const InvoicesARRequestCreate: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const job_id = useSelector(getCurrentJobSelector);
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'AR Requests',
      path: '/invoices/request',
    },
    {
      title: 'New AR Invoice Request',
      path: '',
    },
  ];
  const pageTitle = 'New AR Invoice Request';

  useEffect(() => {
    dispatch(fetchJobsRequest(job_id))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack spacing={2} direction="row" alignItems="flex-start">
        <PageTitle goBack title={pageTitle} />
        <Button variant="outlined" style={{cursor: 'pointer'}}>In process</Button>
      </Stack>
      <Card style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}>
        <CardContent>
          <CreateInvoicesARRequestForm />
        </CardContent>
      </Card>
    </Layout>
  )
};

export default InvoicesARRequestCreate;

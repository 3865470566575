import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

import { isEqualPropsMemo } from '../../../utils/helpers';
import DurationField from '../DurationField';

interface GetDurationInSecondsProps {
  value?: number;
  error?: boolean;
  helperText?: string;
  years?: boolean;
  month?: boolean;
  days?: boolean;
  hours?: boolean;
  minutes?: boolean;
  onChange: (duration: number) => void;
}

interface IDuration {
  minutes: number;
  hours: number;
  days: number;
  months: number;
  years: number;
}

const GetDurationInSeconds = ({
                                value,
                                error,
                                helperText,
                                minutes,
                                hours,
                                days,
                                month,
                                years,
                                onChange,
                              }: GetDurationInSecondsProps) => {
  const [duration, setDuration] = useState<IDuration>({
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
    years: 0,
  });

  useEffect(() => {
    if (value) {
      setDuration(get(moment.duration(value, 'seconds'), '_data'));
    }

    // eslint-disable-next-line
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const newDur: IDuration = { ...duration };
    set(newDur, name, value);
    setDuration(newDur);
  };

  useEffect(() => {
    onChange(moment.duration(duration).asSeconds());

    // eslint-disable-next-line
  }, [duration]);

  return (
    <>
      <Stack spacing={1} direction="row">
        {
          years &&
          <DurationField
            error={error}
            name="years"
            type="years"
            inputProps={{
              min: 0,
              max: 10,
            }}
            value={duration.years}
            onChange={handleChange}
          />
        }
        {
          month &&
          <DurationField
            error={error}
            name="months"
            type="months"
            inputProps={{
              min: 0,
              max: 12,
            }}
            value={duration.months}
            onChange={handleChange}
          />
        }
        {
          days &&
          <DurationField
            error={error}
            name="days"
            type="day(s)"
            inputProps={{
              min: 0,
              max: 20,
            }}
            value={duration.days}
            onChange={handleChange}
          />
        }
        {
          hours &&
          <DurationField
            error={error}
            name="hours"
            type="hour(s)"
            inputProps={{
              min: 0,
              max: 24,
            }}
            value={duration.hours}
            onChange={handleChange}
          />
        }
        {
          minutes &&
          <DurationField
            error={error}
            name="minutes"
            type="minute(s)"
            inputProps={{
              min: 0,
              max: 59,
            }}
            value={duration.minutes}
            onChange={handleChange}
          />
        }
      </Stack>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default memo(GetDurationInSeconds, isEqualPropsMemo);
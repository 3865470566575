import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { ILinks, systemLinks } from '../../../../constants/routes';

interface IAdminSystemListProps {
  isMenuSm: boolean;
}

const AdminSystemList = ({ isMenuSm }: IAdminSystemListProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open] = React.useState(location.pathname.includes('/system') || !isMenuSm);

  const handleClick = () => {
    if (!open) navigate(systemLinks[0].path);
  };

  return (
    <List>
      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      {
        // isMenuSm &&
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Administration" />
        </ListItemButton>
      }
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            systemLinks.map((link: ILinks) => (
              <ListItemButton sx={{ pl: isMenuSm ? 4 : 2 }} key={link.label} component={NavLink} to={link.path} end>
                {
                  link.icon &&
                  <ListItemIcon>
                    <link.icon />
                  </ListItemIcon>
                }
                <ListItemText primary={link.label} />
              </ListItemButton>
            ))
          }
        </List>
      </Collapse>
      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Permissions" />
      </ListItemButton>
    </List>
  );
};

export default AdminSystemList;
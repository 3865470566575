import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';

const getPending = (state: AppState) => state.auth.pending;
const getIsAuth = (state: AppState) => state.auth.isAuth;
const getCognitoUser = (state: AppState) => state.auth.cognitoUser;
const getIsAuthCache = (state: AppState) => state.auth.isAuthCacheChecked;
const getIsForgotPassword = (state: AppState) => state.auth.isForgotPassword;
const getIsConfirmForgotPassword = (state: AppState) => state.auth.isConfirmForgotPassword;
const getAuthData = (state: AppState) => state.auth.data;
const getUserRole = (state: AppState) => state.auth.data?.user_role;
const getErrorMessage = (state: AppState) => state.auth.error;

export const getIsAuthSelector = createSelector(getIsAuth, isAuth => isAuth);
export const getPendingSelector = createSelector(getPending, pending => pending);
export const getCognitoUserSelector = createSelector(getCognitoUser, cognitoUser => cognitoUser);
export const getIsAuthCacheChecked = createSelector(getIsAuthCache, isAuthCacheChecked => isAuthCacheChecked);
export const getIsForgotPasswordChecked = createSelector(getIsForgotPassword, isForgotPassword => isForgotPassword);
export const getIsConfirmForgotPasswordChecked = createSelector(getIsConfirmForgotPassword, isConfirmForgotPassword => isConfirmForgotPassword);
export const getAuthDataSelector = createSelector(getAuthData, data => data);
export const getUserRoleSelector = createSelector(getUserRole, data => data);
export const getErrorMessageSelector = createSelector(getErrorMessage, error => error);

import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  createTrainingsSuccess,
  fetchTrainingsProfileSuccess,
  fetchTrainingsSuccess,
  updateTrainingsSuccess,
  viewTrainingsSuccess,
} from './actions';
import { notifyOpen, setPending, setSending } from '../common/actions';
import {
  CREATE_SESSION_REQUEST,
  CREATE_TRAININGS_REQUEST, FETCH_TRAININGS_PROFILE_REQUEST,
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_VIEW_REQUEST,
  UPDATE_TRAININGS_REQUEST,
} from './actionTypes';
import {
  ICreateSessionRequest,
  ICreateTrainingsRequest,
  IFetchTrainingsProfileRequest,
  IFetchTrainingsRequest,
  IUpdateTrainingsRequest,
  IViewTrainingsRequest,
} from './types';
import { ETrainingOnlineContentType } from '../../types/trainings';
import { uploadFileToS3 } from '../../utils/helpers';
import { apiSagaRequest } from '../../utils/saga';
import { routes } from '../../constants/routes';

function* fetchTrainingsSaga({ params }: IFetchTrainingsRequest): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: '/trainings', params: params.params });
    yield put(fetchTrainingsSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* createTrainingsSaga({ params }: ICreateTrainingsRequest): any {
  try {
    const { data } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'post', url: '/trainings', data });
    yield put(createTrainingsSuccess(response));
    if (response.certificate_template) {
      yield uploadFileToS3(response.certificate_template.url_post, data.certificate_template.url_post.file);
    }
    if (response.online_content) {
      yield all(response.online_content.map(function* (c: { [key: string]: any }, index: number) {
        if (c.type === ETrainingOnlineContentType.video) {
          yield uploadFileToS3(c.url_post, data.online_content[index].file);
        }
      }));
    }
    params.navigate(`${routes.trainings}/${response.id}`);
  } finally {
    yield put(setSending(false));
  }
}

function* viewTrainingsSaga({ params }: IViewTrainingsRequest): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/trainings/${params.id}` });
    yield put(viewTrainingsSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* updateTrainingSaga({ params }: IUpdateTrainingsRequest): any {
  try {
    const { id, data } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'put', url: `/trainings/${id}`, data });
    yield put(updateTrainingsSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Training information successfully updated' }));
  } catch (e) {
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Training information update failed' }));
  } finally {
    yield put(setSending(false));
  }
}

function* fetchTrainingsProfileSaga({ params }: IFetchTrainingsProfileRequest): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/users/${params.id}/tprofile` });
    yield put(fetchTrainingsProfileSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* createSessionSaga({ params }: ICreateSessionRequest): any {
  try {
    const { training_id, data, navigate } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'post', url: `/trainings/${training_id}/tsessions`, data });
    navigate(`/${routes.trainingSessions}/${response.id}`);
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Session successfully create' }));
  } catch (e) {
    yield put(notifyOpen({ notifyType: 'error', notifyMessage: 'Session create failed' }));
  } finally {
    yield put(setSending(false));
  }
}

function* trainingsSaga() {
  yield all([
    takeLatest(FETCH_TRAININGS_REQUEST, fetchTrainingsSaga),
    takeLatest(CREATE_TRAININGS_REQUEST, createTrainingsSaga),
    takeLatest(FETCH_TRAININGS_VIEW_REQUEST, viewTrainingsSaga),
    takeLatest(UPDATE_TRAININGS_REQUEST, updateTrainingSaga),
    takeLatest(FETCH_TRAININGS_PROFILE_REQUEST, fetchTrainingsProfileSaga),
    takeLatest(CREATE_SESSION_REQUEST, createSessionSaga),
  ]);
}

export default trainingsSaga;
import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchTrainingsRequest = (
  params: TYPES.IFetchTrainingsRequestParams,
): TYPES.IFetchTrainingsRequest => ({
  type: ACTION_TYPES.FETCH_TRAININGS_REQUEST,
  params,
});

export const fetchTrainingsSuccess = (
  payload: TYPES.IFetchTrainingsSuccessPayload,
): TYPES.IFetchTrainingsSuccess => ({
  type: ACTION_TYPES.FETCH_TRAININGS_SUCCESS,
  payload,
});

export const createTrainingsRequest = (
  params: TYPES.ICreateTrainingsRequestParams,
): TYPES.ICreateTrainingsRequest => ({
  type: ACTION_TYPES.CREATE_TRAININGS_REQUEST,
  params,
});

export const createTrainingsSuccess = (
  payload: TYPES.ICreateTrainingsSuccessPayload,
): TYPES.ICreateTrainingsSuccess => ({
  type: ACTION_TYPES.CREATE_TRAININGS_SUCCESS,
  payload,
});

export const viewTrainingsRequest = (
  params: TYPES.IViewTrainingsRequestParams,
): TYPES.IViewTrainingsRequest => ({
  type: ACTION_TYPES.FETCH_TRAININGS_VIEW_REQUEST,
  params,
});

export const viewTrainingsSuccess = (
  payload: TYPES.IViewTrainingsSuccessPayload,
): TYPES.IViewTrainingsSuccess => ({
  type: ACTION_TYPES.FETCH_TRAININGS_VIEW_SUCCESS,
  payload,
});

export const updateTrainingsRequest = (
  params: TYPES.IUpdateTrainingsRequestParams,
): TYPES.IUpdateTrainingsRequest => ({
  type: ACTION_TYPES.UPDATE_TRAININGS_REQUEST,
  params,
});

export const updateTrainingsSuccess = (
  payload: TYPES.IUpdateTrainingsSuccessPayload,
): TYPES.IUpdateTrainingsSuccess => ({
  type: ACTION_TYPES.UPDATE_TRAININGS_SUCCESS,
  payload,
});

export const fetchTrainingProfileRequest = (
  params: TYPES.IFetchTrainingsProfileRequestParams,
): TYPES.IFetchTrainingsProfileRequest => ({
  type: ACTION_TYPES.FETCH_TRAININGS_PROFILE_REQUEST,
  params,
});

export const fetchTrainingsProfileSuccess = (
  payload: TYPES.IFetchTrainingsProfileSuccessPayload,
): TYPES.IFetchTrainingsProfileSuccess => ({
  type: ACTION_TYPES.FETCH_TRAININGS_PROFILE_SUCCESS,
  payload,
});

export const createSessionRequest = (
  params: TYPES.ICreateSessionRequestParams,
): TYPES.ICreateSessionRequest => ({
  type: ACTION_TYPES.CREATE_SESSION_REQUEST,
  params,
});
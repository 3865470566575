import React, { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { notifyOpen } from '../../../store/common/actions';

import { isEqualPropsMemo } from '../../../utils/helpers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { IEmployee } from './types';

const ManpowerEmployee = ({toggleEmployee, status, row, employeesList, handleUpdateEmployees, userRole}: any) => {
  const [checked, setChecked] = useState<number[]>([]);
  const [usersArr, setUsersArr] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [initialCheckedEmployees, setInitialCheckedEmployees] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState([]);
  const [specialEmployees, setSpecialEmployees] = useState([]);
  const dispatch = useDispatch();
  const handleCheck = (e: any) => {
    let updatedList = [...checked];
    if (e.target.checked && checked?.length < row?.Qty) {
      updatedList = [...checked, Number(e.target.value)];
      setChecked(updatedList);
    }
    if (!e.target.checked && checked?.length < row?.Qty) {
      const Index = updatedList.indexOf(Number(e.target.value));
      if (Index !== -1) {
        updatedList.splice(Index, 1);
      }
      setChecked(updatedList);
    }
    if (e.target.checked && checked?.length >= row?.Qty) {
      dispatch(notifyOpen({ notifyType: 'warning', notifyMessage: `You can set only ${row?.Qty} employees!` }));
    }
    if (!e.target.checked && checked?.length >= row?.Qty) {
      const Index = updatedList.indexOf(Number(e.target.value));
      if (Index !== -1) {
        updatedList.splice(Index, 1);
      }
      setChecked(updatedList);
    }
  };

  const handleChangeValue = (e: any) => {
    setInputValue(e.target.value);
    let filteredUsers
    if ((status === 'completed' || status === 'canceled') || (userRole !== 'admin' && userRole !== 'user_dispatcher')) {
      filteredUsers = checkedEmployees.filter((employee: IEmployee) => employee?.label.toLowerCase().includes(inputValue.toLowerCase()));
      setCheckedEmployees(filteredUsers);
    } else {
      filteredUsers = specialEmployees.filter((employee: IEmployee) => employee?.label.toLowerCase().includes(inputValue.toLowerCase()));
      setUsersArr(filteredUsers);
    }
    if (!e.target.value) {
      setUsersArr(specialEmployees);
      setCheckedEmployees(initialCheckedEmployees);
    }
  }

  useEffect(() => {
    if (row && employeesList?.length) {
      setSpecialEmployees(employeesList.filter((employee: IEmployee) => employee.class === row.Class || employee.class === row.Craft));
    }
  }, [row, employeesList]);

  useEffect(() => {
    if (!row.Craft || !row.Class || !row.Duration || !row.Cert1 || !row.Qty) {
      dispatch(notifyOpen({ notifyType: 'warning', notifyMessage: `To select employees you must first fill in and save a row!` }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row])

  useEffect(() => {
    setUsersArr(specialEmployees);
    setChecked(row?.Employees?.map((key: number) => specialEmployees?.find((element: any) => element?.value === key)).map((sel: any) => sel?.value));
    if ((status === 'completed' || status === 'canceled') || (userRole !== 'admin' && userRole !== 'user_dispatcher')) {
      setCheckedEmployees(row?.Employees?.map((key: number) => specialEmployees?.find((element: any) => element?.value === key)));
      setInitialCheckedEmployees(row?.Employees?.map((key: number) => specialEmployees?.find((element: any) => element?.value === key)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialEmployees])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '1rem',
        }}
      >
        <Typography variant='h4' sx={{ fontSize: 19, mb: 2 }} component='h1'>
          Employee
        </Typography>
        <CloseIcon
          style={{ cursor: 'pointer' }}
          fontSize='large'
          onClick={toggleEmployee(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: 15 }}>Add employee to request</Typography>
        <Typography sx={{ fontSize: 15, color: 'gray' }}>
          {checked?.length
            ? `Selected ${checked?.length} from ${row?.Qty}`
            : `No employees selected`}
        </Typography>
      </div>
      <FormControl margin='normal' fullWidth>
        <TextField
          placeholder='Select users'
          name='users-search'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleChangeValue}
        />
      </FormControl>
      <div
        style={{
          height: '650px',
          overflow: 'auto',
          border: '1px solid #F1F1F1',
          padding: '10px',
        }}
      >
        <Grid container wrap='nowrap' spacing={2}>
          <Grid justifyContent='left' item xs zeroMinWidth>
            {/* <Typography sx={{ fontSize: 14, mb: 1 }}>
              {checked?.length
                ? `Selected ${checked?.length} from ${row?.Qty}`
                : `No employees selected`}
            </Typography> */}
            <FormGroup>
              {status !== 'completed' && status !== 'canceled' && userRole !== 'user_pm' && userRole !== 'user_super' &&
                usersArr?.map((employee: IEmployee, index: number) => (
                  <FormControlLabel
                    control={<Checkbox checked={checked?.includes(employee.value)} />}
                    label={employee?.label}
                    key={index}
                    value={employee?.value}
                    onChange={handleCheck}
                  />
                ))}
              {((status === 'completed' || status === 'canceled') || (userRole !== 'admin' && userRole !== 'user_dispatcher')) && 
                checkedEmployees?.map((employee: IEmployee, index: number) => (
                  <FormControlLabel
                    control={<Checkbox checked={true} disabled={true}/>}
                    label={employee?.label}
                    key={index}
                    value={employee?.value}
                  />
                ))}
              {(!usersArr?.length && status === 'progress' || !checkedEmployees?.length && (status === 'completed' || status === 'canceled')) && (
                <Typography
                  sx={{ fontSize: 15, alignSelf: 'center', marginTop: '10rem' }}
                >
                  {inputValue ? `No results for ${inputValue}` : `No match found. Please, create a note and contact the requestor.`}
                </Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>
      </div>
      <Box sx={{ mt: '20px' }}>
        <Box sx={{ textAlign: 'start', margin: '10px 0 0 0' }}>
          {status !== 'completed' && status !== 'canceled' && userRole !== 'user_pm' && userRole !== 'user_super' && (
            <Button
              variant='contained'
              sx={{ px: 0.25, marginRight: '10px' }}
              onClick={(e: any) => {
                handleUpdateEmployees(checked)(e)
              }}
            >
              Submit
            </Button>
          )}
          <Button
            variant='outlined'
            sx={{ px: 0.25 }}
            onClick={(e: any) => {
              toggleEmployee(false)(e)
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default memo(ManpowerEmployee, isEqualPropsMemo)

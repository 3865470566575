import React, { memo } from 'react';

import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { SelectProps } from '@mui/material/Select/Select';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';

import { ISelectOptions } from '../../../types/common';
import { isEqualPropsMemo } from '../../../utils/helpers';

interface ISelectFieldProps extends SelectProps {
  name: string,
  value: string | any | undefined,
  options: ISelectOptions[],
  label?: string,
  helperText?: string | any | undefined,
}

const SelectField = ({
                       name,
                       label,
                       helperText,
                       options,
                       value,
                       onClose,
                       onChange,
                       ...props
                     }: ISelectFieldProps): JSX.Element => {
  return (
    <FormControl fullWidth size="small">
      {label && <InputLabel>{label}{props.required && '*'}</InputLabel>}
      <Stack direction="row">
        <Select
          name={name || ''}
          value={value || ''}
          label={label || ''}
          displayEmpty
          onChange={onChange}
          sx={{
            flexGrow: 1,
          }}
          {...props}
        >
          {
            options.map((option, index) =>
              <MenuItem
                key={`${option.label} ${option.value} ${index}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>)
          }
        </Select>
        {
          value && onClose &&
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
      </Stack>
      {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default memo(SelectField, isEqualPropsMemo);
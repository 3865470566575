import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { IIssueCategoriesItem } from '../../../types/system/issueCategories';
import { ITableRowProps } from '../../../types/table';
import { isEqualPropsMemo } from '../../../utils/helpers';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';

interface IIssueCategoriesTableRowProps extends ITableRowProps<IIssueCategoriesItem> {
  onEdit: (data: IIssueCategoriesItem) => void,
  onDelete: (id: string) => void,
}

const IssueCategoriesTableRow = ({ index, data, onEdit, onDelete }: IIssueCategoriesTableRowProps) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
    >
      <TableCell sx={{ width: 30 }}>
        {index + 1}.
      </TableCell>
      <TableCell>
        {data.category}
      </TableCell>
      <TableCell>
        {data.type}
      </TableCell>
      <TableCell dangerouslySetInnerHTML={{ __html: data.description }} />
      <TableCell>
        <ButtonGroup variant="outlined">
          <Button sx={{ px: 0.25 }} onClick={() => onEdit(data)}>
            <EditIcon fontSize="small" />
          </Button>
          <Button color="error" sx={{ px: 0.25 }} onClick={() => onDelete(data.id)}>
            <DeleteIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

export default memo(IssueCategoriesTableRow, isEqualPropsMemo);
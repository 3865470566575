import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { getIsAsideOpenSelector } from '../../../store/common/selectors';
import { asideClose, asideOpen } from '../../../store/common/actions';
import { routes } from '../../../constants/routes';
import AsideCurrentPageList from './AsideCurrentPageList';
import AdminSystemList from './AsideSystemList';
import { Drawer, DrawerHeader } from './styles';

const Aside = () => {
  const isOpen = useSelector(getIsAsideOpenSelector);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    if (isOpen) {
      dispatch(asideClose());
    } else {
      dispatch(asideOpen());
    }
  };

  return (
    <Drawer variant="permanent" open={isOpen}>
      <DrawerHeader>
        <IconButton color="inherit" onClick={handleDrawerToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItemButton component={NavLink} to={routes.home} end>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </List>
      <Divider />
      <AsideCurrentPageList isMenuSm={isOpen} />
      <AdminSystemList isMenuSm={isOpen} />
    </Drawer>
  );
};

export default Aside;
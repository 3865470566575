import { all, put, call, takeLatest } from 'redux-saga/effects';

import { fetchCurrentJobsSuccess, fetchJobsSuccess, fetchSubJobSuccess } from './actions';
import { FETCH_CURRENT_JOBS_REQUEST, FETCH_JOBS_REQUEST, FETCH_SUB_JOB_REQUEST } from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
import { IFetchJobsRequest } from './types';
import { setPending } from '../common/actions';

function* fetchJobsSaga({ params }: IFetchJobsRequest): any {
  try {
    yield put(setPending(true));
    const paramsFetch = '?size=1000000&filter=Status%3DOpen'
    const response = yield call(apiSagaRequest, { type: 'get', url: '/jobs', params: paramsFetch });
    yield put(fetchJobsSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* fetchSubJobSaga({ params }: any): any {
  try {
    yield put(setPending(false));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${params.job}` });   
    yield put(fetchSubJobSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* fetchCurrentJobsSaga({ params }: IFetchJobsRequest): any {
  try {
    const response = yield call(apiSagaRequest, { type: 'get', url: '/jobs', params: params.params });
    yield put(fetchCurrentJobsSuccess(response));
  } catch (e) {
    console.error(e);
  }
}

function* jobsSaga() {
  yield all([
    takeLatest(FETCH_JOBS_REQUEST, fetchJobsSaga),
    takeLatest(FETCH_SUB_JOB_REQUEST, fetchSubJobSaga),
    takeLatest(FETCH_CURRENT_JOBS_REQUEST, fetchCurrentJobsSaga),
  ]);
}

export default jobsSaga;
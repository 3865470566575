import React from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { getTrainingsCategoryOptionsSelector } from '../../../store/trainings/selectors';
import { ISessionItem } from '../../../types/session';
import {
  sessionNotificationOptions,
  sessionVerificationOptions,
  stateOptions,
  timezoneOptions,
} from '../../../constants/selectOptions';
import AutocompleteSelect from '../../ui/AutocompleteSelect';
import DatePicker from '../../ui/DatePicker';
import FormLabel from '../../ui/FormLabel';
import TextEditor from '../../ui/TextEditor';
import SelectField from '../../ui/SelectField';

interface ISessionInfoFormProps {
  values: ISessionItem;
  errors?: any;
  onChange: (e: any) => void;
  setFieldValue: (name: string, value: any) => void;
  onChangeCategory: (v: string) => void;
}

const SessionInfoForm = ({
                           values,
                           errors,
                           onChange,
                           onChangeCategory,
                           setFieldValue,
                         }: ISessionInfoFormProps): JSX.Element => {
  const categoryList = useSelector(getTrainingsCategoryOptionsSelector);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="body1">
              Training
            </Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <FormLabel
                    required
                    title="Training Category"
                    tooltip="These are the different categories under which a training is classified.  Selecting a category will filter the possible selection of Names that can be chosen"
                  />
                  <AutocompleteSelect
                    value={values.training.id}
                    name="training"
                    options={categoryList}
                    onChange={onChangeCategory}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Address
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Street"
                      />
                      <TextField
                        error={Boolean(errors.address?.street)}
                        helperText={errors.address?.street}
                        name="address.street"
                        value={values.address.street}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="City"
                      />
                      <TextField
                        error={Boolean(errors.address?.city)}
                        helperText={errors.address?.city}
                        name="address.city"
                        value={values.address.city}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Country"
                      />
                      <TextField
                        error={Boolean(errors.address?.country)}
                        helperText={errors.address?.country}
                        name="address.country"
                        value={values.address.country}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="State"
                      />
                      <AutocompleteSelect
                        error={Boolean(errors.address?.state)}
                        helperText={errors.address?.state}
                        name="address.state"
                        options={stateOptions}
                        onSetFieldChange={setFieldValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Zip"
                      />
                      <TextField
                        error={Boolean(errors.address?.zipcode)}
                        helperText={errors.address?.zipcode}
                        name="address.zipcode"
                        value={values.address.zipcode}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  Trainer
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Full Name"
                      />
                      <TextField
                        error={Boolean(errors.trainer?.fullname)}
                        helperText={errors.trainer?.fullname}
                        name="trainer.fullname"
                        value={values.trainer.fullname}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Email"
                      />
                      <TextField
                        error={Boolean(errors.trainer?.email)}
                        helperText={errors.trainer?.email}
                        name="trainer.email"
                        value={values.trainer.email}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        required
                        title="Phone"
                      />
                      <TextField
                        error={Boolean(errors.trainer?.phone)}
                        helperText={errors.trainer?.phone}
                        name="trainer.phone"
                        value={values.trainer.phone}
                        onChange={onChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
              <Typography variant="body1">
                Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <TextEditor
                name="notes"
                label="Notes"
                value={values.notes}
                onSetFieldValue={setFieldValue}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel title="Link" />
              <TextField
                name="link"
                value={values.link}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <FormLabel
                required
                title="Verification type"
                tooltip="Identification of the method use for passing a class."
              />
              <SelectField
                error={Boolean(errors.verification_type)}
                helperText={errors.verification_type}
                name="verification_type"
                value={values.verification_type}
                options={sessionVerificationOptions}
                onChange={onChange}
              />
            </FormControl>
            <Divider />
            <FormControl fullWidth margin="normal">
              <FormLabel
                required
                title="Start time"
              />
              <DatePicker
                error={Boolean(errors.start_time)}
                helperText={errors.start_time}
                required
                type="datetime-local"
                name="start_time"
                value={values.start_time}
                onChange={onChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel
                required
                title="Time Zone"
              />
              <AutocompleteSelect
                error={Boolean(errors.time_zone)}
                helperText={errors.time_zone}
                name="time_zone"
                options={timezoneOptions}
                value={values.time_zone}
                onSetFieldChange={setFieldValue}
              />
              <FormLabel title="Finish time" />
            </FormControl>
            <Divider />
            <FormControl fullWidth margin="normal">
              <FormLabel title="Notification" />
              <SelectField
                multiple
                name="notifications"
                value={values.notifications}
                options={sessionNotificationOptions}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SessionInfoForm;
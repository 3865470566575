import { createSelector } from 'reselect';
import { getSortedSelectOptions } from '../../utils/helpers';

import { AppState } from '../rootReducer';

const getJobs = (state: AppState) => state.jobs.items;
const getJobDetails = (state: AppState) => state.jobs.jobDetails;
const getHasMore = (state: AppState) => state.jobs.has_more;
const getCurrentJob = (state: AppState) => state.jobs.currentJob;
const getCurrentJobsItems = (state: AppState) => state.jobs.currentJobsItems;
const getCurrentJobsPending = (state: AppState) => state.jobs.currentJobsPending;
const getCurrentJobsHasMore = (state: AppState) => state.jobs.currentJobsHasMore;

export const getJobsSelector = createSelector(getJobs, items => items);
export const getJobDetailsSelector = createSelector(getJobDetails, items => items);
export const getJobsOptionsSelector = createSelector(getJobs, items => getSortedSelectOptions(items.map((item: any) => ({
    label: item.Job,
    value: item.id,
  }))));
export const getHasMoreSelector = createSelector(getHasMore, has_more => has_more);
export const getCurrentJobSelector = createSelector(getCurrentJob, currentJob => currentJob);
export const getCurrentJobsSelector = createSelector(getCurrentJobsItems, currentJobsItems => currentJobsItems);
export const getCurrentJobsPendingSelector = createSelector(getCurrentJobsPending, currentJobsPending => currentJobsPending);
export const getCurrentJobsHasMoreSelector = createSelector(getCurrentJobsHasMore, currentJobsHasMore => currentJobsHasMore);

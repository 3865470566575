import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchJobsRequest = (
  params: TYPES.IFetchJobsRequestParams,
): TYPES.IFetchJobsRequest => ({
  type: ACTION_TYPES.FETCH_JOBS_REQUEST,
  params,
});

export const fetchJobsSuccess = (
  payload: TYPES.IFetchJobsSuccessPayload,
): TYPES.IFetchJobsSuccess => ({
  type: ACTION_TYPES.FETCH_JOBS_SUCCESS,
  payload,
});

export const fetchSubJobRequest = (
  params: TYPES.IFetchSubJobRequestParams,
): TYPES.IFetchSubJobRequest => ({
  type: ACTION_TYPES.FETCH_SUB_JOB_REQUEST,
  params,
});

export const fetchSubJobSuccess = (
  payload: TYPES.IFetchSubJobSuccessPayload,
): TYPES.IFetchSubJobSuccess => ({
  type: ACTION_TYPES.FETCH_SUB_JOB_SUCCESS,
  payload,
});

export const fetchCurrentJobsRequest = (
  params: TYPES.IFetchJobsRequestParams,
): TYPES.IFetchCurrentJobsRequest => ({
  type: ACTION_TYPES.FETCH_CURRENT_JOBS_REQUEST,
  params,
});

export const fetchCurrentJobsSuccess = (
  payload: TYPES.IFetchJobsSuccessPayload,
): TYPES.IFetchCurrentJobsSuccess => ({
  type: ACTION_TYPES.FETCH_CURRENT_JOBS_SUCCESS,
  payload,
});

export const setCurrentJob = (
  payload: TYPES.ISetCurrentJobPayload,
): TYPES.ISetCurrentJob => ({
  type: ACTION_TYPES.SET_CURRENT_JOB,
  payload,
});
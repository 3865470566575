import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom'

import { isEqualPropsMemo } from '../../../utils/helpers';


const PosTable = ({ dataValues} :any ) => {
  const MaterialTable = require("material-table").default;
  const navigate = useNavigate();

  const [muiTableKey, setMuiTableKey] = React.useState(0);
  
  return (
    <MaterialTable 
      title=""
      columns={[
        // PO #
        { 
          title: 'PO #', 
          field: 'PO',
          render: (rowData: any) => 
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => navigate(`/pos/${rowData.id}`)}
            >
              {rowData.PO}
            </span> 
        },
        // Status
        { 
          title: 'Status', 
          field: 'Status',
          lookup: { 'OPEN': 'Open', 'CLOSED': 'Closed' },
          render: (rowData: any) => 
            <div style={{textAlign: 'center'}}>
              {rowData.Status === 'CLOSED' && <div style={{ color: '#d32f2f' }}>Closed</div>}
              {rowData.Status === 'OPEN' && <div style={{ color: '#2e7d32' }}>Open</div>}
              {rowData.Status === 'Reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
              {rowData.Status === 'Progress' && <div style={{ color: '#19857b' }}>Progress</div>}
              {rowData.Status === 'Resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
              {rowData.Status === 'Duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
            </div>
        },
        // Vendor
        { 
          title: 'Vendor', 
          field: 'VendorName'
        },
        // Original
        { 
          title: 'Original', 
          field: 'Original',
          render: (rowData: any) =>
            <>
              {
                (rowData.Original).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </>
        },
        // CO Amt
        { 
          title: 'CO Amt', 
          field: 'COAmount',
          customSort: (a: any, b: any) => a.COAmount.localeCompare(b.COAmount, 'en', { numeric: true }),
          render: (rowData: any) =>
            <>
              {
                (rowData.COAmount).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </>
        },
        // Invoiced
        { 
          title: 'Invoiced', 
          field: 'Invoiced',
          render: (rowData: any) =>
            <>
              {
                (rowData.Invoiced).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </>
        },
        // // Paid
        // { 
        //   title: 'Paid', 
        //   field: 'Paid',
        //   render: (rowData: any) =>
        //     <>
        //       {
        //         (rowData.Paid).toLocaleString('en-US', {
        //           style: 'currency',
        //           currency: 'USD',
        //         })
        //       }
        //     </>
        // },
        // Days
        { 
          title: 'Days', 
          field: 'DaysOpen' 
        },
      ]}
      data={dataValues?.sort((a: any, b: any) => (a.Status === 'OPEN')? -1 : 1)}     
      options={{
        headerStyle: {
          whiteSpace: 'nowrap'
        },
        search: false,
        filtering: true,
        sorting: true,
        paging:true,
        pageSize:10,
        pageSizeOptions:[10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        
        maxBodyHeight: 'calc(100vh - 280px)',
      }}
      actions={[
        {
          icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
          tooltip: "Сlear all filters",
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1);
          }
        }
      ]}
    />
  )
}

export default memo(PosTable, isEqualPropsMemo);
import * as Yup from 'yup';
import { ESeverity } from '../types/common';
import { ETrainingLanguage, ETrainingStatus, ETrainingTypes } from '../types/trainings';
import { ESessionValidationType } from '../types/session';

export const IssueFormValidation = Yup.object().shape({
  summary: Yup.string()
    .required('Field is required'),
  category: Yup.string()
    .required('Field is required'),
  description: Yup.string()
    .required('Field is required'),
  severity: Yup.mixed<ESeverity>().oneOf(Object.values(ESeverity))
    .required('Field is required'),
  due_date: Yup.string()
    .required('Field is required'),
});

export const TrainingFormValidation = Yup.object().shape({
  name: Yup.string()
    .required('Field is required'),
  category: Yup.string()
    .required('Field is required'),
  description: Yup.string()
    .required('Field is required'),
  type: Yup.mixed<ETrainingTypes>().oneOf(Object.values(ETrainingTypes))
    .required('Field is required'),
  language: Yup.mixed<ETrainingLanguage>().oneOf(Object.values(ETrainingLanguage))
    .required('Field is required'),
  status: Yup.mixed<ETrainingStatus>().oneOf(Object.values(ETrainingStatus))
    .required('Field is required'),
  duration: Yup.number()
    .min(1, 'Field is required'),
  certificate_validity: Yup.number()
    .min(1, 'Field is required'),
});

export const SessionFormValidation = Yup.object().shape({
  training: Yup.object().shape({
    id: Yup.string()
      .required('Field is required'),
  }),
  address: Yup.object().shape({
    street: Yup.string()
      .required('Field is required'),
    city: Yup.string()
      .required('Field is required'),
    country: Yup.string()
      .required('Field is required'),
    state: Yup.string()
      .required('Field is required'),
    zipcode: Yup.string()
      .required('Field is required'),
  }),
  trainer: Yup.object().shape({
    fullname: Yup.string()
      .required('Field is required'),
    email: Yup.string()
      .required('Field is required'),
    phone: Yup.string()
      .required('Field is required'),
  }),
  verification_type: Yup.mixed<ESessionValidationType>().oneOf(Object.values(ESessionValidationType))
    .required('Field is required'),
  start_time: Yup.string()
    .required('Field is required'),
  time_zone: Yup.string()
    .required('Field is required'),
});

export const UserFormValidation = Yup.object().shape({
  user_fullname: Yup.string()
    .required('Field is required'),
    user_email: Yup.string()
    .required('Field is required'),
    user_role: Yup.string()
    .required('Field is required'),
});

export const InvoicesARRequestFixedPriceValidation = Yup.object().shape({
  invoice_job: Yup.string()
    .required('Field is required'),
  invoice_subjob: Yup.string()
    .required('Field is required'),
  invoice_customerPO: Yup.string()
    .required('Field is required'),
  invoice_amountRequested: Yup.string()
    .required('Field is required'),
});

export const InvoicesARRequestTMValidation = Yup.object().shape({
  invoice_job: Yup.string()
    .required('Field is required'),
  invoice_subjob: Yup.string()
    .required('Field is required'),
  invoice_customerPO: Yup.string()
    .required('Field is required'),
});

export const InvoicesARRequestValidation = Yup.object().shape({
  invoice_job: Yup.string()
    .required('Field is required'),
  invoice_subjob: Yup.string()
    .required('Field is required'),
  invoice_customerPO: Yup.string()
    .required('Field is required'),
  invoice_lastUpdated: Yup.string()
    .required('Field is required'),
  invoice_jobType: Yup.string()
    .required('Field is required'),
  invoice_amountRequested: Yup.string()
    .required('Field is required'),
});

export const ManpowerRequestValidation = Yup.object().shape({
  requestor: Yup.string()
    .required('Field is required'),
  job: Yup.string().nullable()
    .required('Field is required'),
  customer: Yup.string()
    .required('Field is required'),
  startDate: Yup.string()
    .required('Field is required'),
  startTime: Yup.string()
    .required('Field is required'),
  dateRequested: Yup.string()
    .required('Field is required'),
  reportsTo: Yup.string()
    .required('Field is required'),
  hoursDay: Yup.number()
    .min(1, 'The number of hours must be at least 1 and not more than 24')
    .max(24, 'The number of hours must be at least 1 and not more than 24')
    .required('Field is required'),
  daysWeek: Yup.number()
    .min(1, 'The number of days must be at least 1 and not more than 7')
    .max(7, 'The number of days must be at least 1 and not more than 7')
    .required('Field is required'),
  rows: Yup.array().of(Yup.object().shape({
    Craft: Yup.string().required('Field is required'),
    Class: Yup.string().required('Field is required'),
    Specialty: Yup.string().required('Field is required'),
    Cert1: Yup.string().required('Field is required'),
    Qty: Yup.number()
    .min(1, 'Min number is 1')
    .max(32000, 'Max number is 32000')
    .required('Field is required')
    .typeError('Qty must be a number'),
    Duration: Yup.number()
    .min(1, 'Min number is 1')
    .max(2000000000, 'Max number is 2000000000')
    .required('Field is required')
    .typeError('Duration must be a number')
  }))
})
import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchIssueCategoriesRequest = (): TYPES.IFetchIssueCategoriesRequest => ({
  type: ACTION_TYPES.FETCH_ISSUE_CATEGORIES_REQUEST,
});

export const fetchIssueCategoriesSuccess = (
  payload: TYPES.IFetchIssueCategoriesSuccessPayload,
): TYPES.IFetchIssueCategoriesSuccess => ({
  type: ACTION_TYPES.FETCH_ISSUE_CATEGORIES_SUCCESS,
  payload,
});

export const fetchIssueCategoriesFailure = (
  payload: TYPES.IFetchIssueCategoriesFailurePayload,
): TYPES.IFetchIssueCategoriesFailure => ({
  type: ACTION_TYPES.FETCH_ISSUE_CATEGORIES_FAILURE,
  payload,
});

export const deleteIssueCategoriesRequest = (
  params: TYPES.IDeleteIssueCategoriesRequestParams,
): TYPES.IDeleteIssueCategoriesRequest => ({
  type: ACTION_TYPES.DELETE_ISSUE_CATEGORIES_REQUEST,
  params,
});

export const deleteIssueCategoriesSuccess = (
  payload: TYPES.IDeleteIssueCategoriesSuccessPayload,
): TYPES.IDeleteIssueCategoriesSuccess => ({
  type: ACTION_TYPES.DELETE_ISSUE_CATEGORIES_SUCCESS,
  payload,
});

export const deleteIssueCategoriesFailure = (
  payload: TYPES.IDeleteIssueCategoriesFailurePayload,
): TYPES.IDeleteIssueCategoriesFailure => ({
  type: ACTION_TYPES.DELETE_ISSUE_CATEGORIES_FAILURE,
  payload,
});

export const editIssueCategoriesRequest = (
  params: TYPES.IEditIssueCategoriesRequestParams,
): TYPES.IEditIssueCategoriesRequest => ({
  type: ACTION_TYPES.EDIT_ISSUE_CATEGORIES_REQUEST,
  params,
});

export const editIssueCategoriesSuccess = (
  payload: TYPES.IEditIssueCategoriesSuccessPayload,
): TYPES.IEditIssueCategoriesSuccess => ({
  type: ACTION_TYPES.EDIT_ISSUE_CATEGORIES_SUCCESS,
  payload,
});

export const editIssueCategoriesFailure = (
  payload: TYPES.IEditIssueCategoriesFailurePayload,
): TYPES.IEditIssueCategoriesFailure => ({
  type: ACTION_TYPES.EDIT_ISSUE_CATEGORIES_FAILURE,
  payload,
});

export const createIssueCategoriesRequest = (
  params: TYPES.ICreateIssueCategoriesRequestParams,
): TYPES.ICreateIssueCategoriesRequest => ({
  type: ACTION_TYPES.CREATE_ISSUE_CATEGORIES_REQUEST,
  params,
});

export const createIssueCategoriesSuccess = (
  payload: TYPES.ICreateIssueCategoriesSuccessPayload,
): TYPES.ICreateIssueCategoriesSuccess => ({
  type: ACTION_TYPES.CREATE_ISSUE_CATEGORIES_SUCCESS,
  payload,
});

export const createIssueCategoriesFailure = (
  payload: TYPES.ICreateIssueCategoriesFailurePayload,
): TYPES.ICreateIssueCategoriesFailure => ({
  type: ACTION_TYPES.CREATE_ISSUE_CATEGORIES_FAILURE,
  payload,
});


import * as ACTION_TYPES from './actionTypes';
import { ICommonActions, ICommonState } from './types';

const initialState: ICommonState = {
  isNotifyOpen: false,
  notifyType: 'info',
  notifyMessage: '',
  isAsideOpen: true,
  error: '',
  isPending: true,
  isSending: false,
  isActiveFiltersCollapsed: false
};

export default function commonReducer(state = initialState, action: ICommonActions) {
  switch (action.type) {
    case ACTION_TYPES.NOTIFY_OPEN:
      return {
        ...state,
        isNotifyOpen: true,
        notifyType: action.payload.notifyType || 'info',
        notifyMessage: action.payload.notifyMessage,
      };
    case ACTION_TYPES.NOTIFY_CLOSE:
      return {
        ...state,
        isNotifyOpen: false,
        notifyMessage: '',
      };
    case ACTION_TYPES.ASIDE_OPEN:
      return {
        ...state,
        isAsideOpen: true,
      };
    case ACTION_TYPES.ASIDE_CLOSE:
      return {
        ...state,
        isAsideOpen: false,
      };
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case ACTION_TYPES.SET_PENDING:
      return {
        ...state,
        isPending: action.payload,
      };
    case ACTION_TYPES.SET_SENDING:
      return {
        ...state,
        isSending: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE_FILTERS_COLLAPSED:
      return {
        ...state,
        isActiveFiltersCollapsed: action.payload,
      };
    default:
      return state;
  }
};
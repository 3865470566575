import { ESeverity, EStatus, ISelectOptions } from '../types/common';
import {
  ETrainingLevel,
  ETrainingLanguage,
  ETrainingStatus,
  ETrainingTypes,
  ETrainingOnlineContentType, ETrainingOnlineContentQuestionTypes,
} from '../types/trainings';
import QuizRoundedIcon from '@mui/icons-material/QuizRounded';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import VideoCameraBackRoundedIcon from '@mui/icons-material/VideoCameraBackRounded';
import { ESessionValidationType } from '../types/session';

export const severitySelectOptions: ISelectOptions[] = [
  { label: ESeverity.low, value: ESeverity.low },
  { label: ESeverity.medium, value: ESeverity.medium },
  { label: ESeverity.high, value: ESeverity.high },
];

export const statusOpenClosedSelectOptions: ISelectOptions[] = [
  { label: EStatus.open, value: EStatus.open },
  { label: EStatus.closed, value: EStatus.closed }
];

export const statusesSelectOptions: ISelectOptions[] = [
  { label: EStatus.open, value: EStatus.open },
  { label: EStatus.reviewed, value: EStatus.reviewed },
  { label: EStatus.progress, value: EStatus.progress },
  { label: EStatus.closed, value: EStatus.closed },
  { label: EStatus.resolved, value: EStatus.resolved },
  { label: EStatus.duplicate, value: EStatus.duplicate },
];

export const trainingsTypeOptions: ISelectOptions[] = [
  { label: ETrainingTypes.online, value: ETrainingTypes.online },
  { label: ETrainingTypes.onsite, value: ETrainingTypes.onsite },
  { label: ETrainingTypes.webinar, value: ETrainingTypes.webinar },
];

export const trainingsLanguageOptions: ISelectOptions[] = [
  { label: 'Spanish', value: ETrainingLanguage.es },
  { label: 'English', value: ETrainingLanguage.us },
];

export const trainingsStatusOptions: ISelectOptions[] = [
  { label: ETrainingStatus.active, value: ETrainingStatus.active },
  { label: ETrainingStatus.inactive, value: ETrainingStatus.inactive },
];

export const trainingsLevelOptions: ISelectOptions[] = [
  { label: ETrainingLevel.advanced, value: ETrainingLevel.advanced },
  { label: ETrainingLevel.fundamental, value: ETrainingLevel.fundamental },
  { label: ETrainingLevel.intermediate, value: ETrainingLevel.intermediate },
];

export const trainingOnlineContentTypes: ISelectOptions[] = [
  { label: 'Text Block', Icon: RateReviewOutlinedIcon, value: ETrainingOnlineContentType.text },
  { label: 'Quiz Block', Icon: QuizRoundedIcon, value: ETrainingOnlineContentType.quiz },
  { label: 'Video Block', Icon: VideoCameraBackRoundedIcon, value: ETrainingOnlineContentType.video },
];

export const trainingQuestionAnswersTypes: ISelectOptions[] = [
  { label: 'Single Answer', value: ETrainingOnlineContentQuestionTypes.select },
  { label: 'Multiple Answer', value: ETrainingOnlineContentQuestionTypes.multy },
];

export const stateOptions: ISelectOptions[] = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'WestVirginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const roleSelectOptions: ISelectOptions[] = [
  { label: 'Admin', value: 'admin' },
  { label: 'PM', value: 'user_pm' },
  { label: 'Super', value: 'user_super' },
  { label: 'Dispatcher', value: 'user_dispatcher' },
  { label: 'AR', value: 'user_ar' },
  { label: 'Training', value: 'user_training' },
]

export const jobTypeSelectOptions: ISelectOptions[] = [
  { label: 'Fixed Price', value: 'F' },
  { label: 'T&M', value: 'T' }
]


export const timezoneOptions: ISelectOptions[] = [
  { value: 'Pacific/Niue', label: '(UTC-11:00) Niue' },
  { value: 'Pacific/Pago_Pago', label: '(UTC-11:00) Pago Pago' },
  { value: 'Pacific/Honolulu', label: '(UTC-10:00) Hawaii Time' },
  { value: 'Pacific/Rarotonga', label: '(UTC-10:00) Rarotonga' },
  { value: 'Pacific/Tahiti', label: '(UTC-10:00) Tahiti' },
  { value: 'Pacific/Marquesas', label: '(UTC-09:30) Marquesas' },
  { value: 'America/Anchorage', label: '(UTC-09:00) Alaska Time' },
  { value: 'Pacific/Gambier', label: '(UTC-09:00) Gambier' },
  { value: 'America/Los_Angeles', label: '(UTC-08:00) Pacific Time' },
  { value: 'America/Tijuana', label: '(UTC-08:00) Pacific Time - Tijuana' },
  { value: 'America/Vancouver', label: '(UTC-08:00) Pacific Time - Vancouver' },
  { value: 'America/Whitehorse', label: '(UTC-08:00) Pacific Time - Whitehorse' },
  { value: 'Pacific/Pitcairn', label: '(UTC-08:00) Pitcairn' },
  { value: 'America/Denver', label: '(UTC-07:00) Mountain Time' },
  { value: 'America/Phoenix', label: '(UTC-07:00) Mountain Time - Arizona' },
  { value: 'America/Mazatlan', label: '(UTC-07:00) Mountain Time - Chihuahua, Mazatlan' },
  { value: 'America/Dawson_Creek', label: '(UTC-07:00) Mountain Time - Dawson Creek' },
  { value: 'America/Edmonton', label: '(UTC-07:00) Mountain Time - Edmonton' },
  { value: 'America/Hermosillo', label: '(UTC-07:00) Mountain Time - Hermosillo' },
  { value: 'America/Yellowknife', label: '(UTC-07:00) Mountain Time - Yellowknife' },
  { value: 'America/Belize', label: '(UTC-06:00) Belize' },
  { value: 'America/Chicago', label: '(UTC-06:00) Central Time' },
  { value: 'America/Mexico_City', label: '(UTC-06:00) Central Time - Mexico City' },
  { value: 'America/Regina', label: '(UTC-06:00) Central Time - Regina' },
  { value: 'America/Tegucigalpa', label: '(UTC-06:00) Central Time - Tegucigalpa' },
  { value: 'America/Winnipeg', label: '(UTC-06:00) Central Time - Winnipeg' },
  { value: 'America/Costa_Rica', label: '(UTC-06:00) Costa Rica' },
  { value: 'America/El_Salvador', label: '(UTC-06:00) El Salvador' },
  { value: 'Pacific/Galapagos', label: '(UTC-06:00) Galapagos' },
  { value: 'America/Guatemala', label: '(UTC-06:00) Guatemala' },
  { value: 'America/Managua', label: '(UTC-06:00) Managua' },
  { value: 'America/Cancun', label: '(UTC-05:00) America Cancun' },
  { value: 'America/Bogota', label: '(UTC-05:00) Bogota' },
  { value: 'Pacific/Easter', label: '(UTC-05:00) Easter Island' },
  { value: 'America/New_York', label: '(UTC-05:00) Eastern Time' },
  { value: 'America/Iqaluit', label: '(UTC-05:00) Eastern Time - Iqaluit' },
  { value: 'America/Toronto', label: '(UTC-05:00) Eastern Time - Toronto' },
  { value: 'America/Guayaquil', label: '(UTC-05:00) Guayaquil' },
  { value: 'America/Havana', label: '(UTC-05:00) Havana' },
  { value: 'America/Jamaica', label: '(UTC-05:00) Jamaica' },
  { value: 'America/Lima', label: '(UTC-05:00) Lima' },
  { value: 'America/Nassau', label: '(UTC-05:00) Nassau' },
  { value: 'America/Panama', label: '(UTC-05:00) Panama' },
  { value: 'America/Port-au-Prince', label: '(UTC-05:00) Port-au-Prince' },
  { value: 'America/Rio_Branco', label: '(UTC-05:00) Rio Branco' },
  { value: 'America/Halifax', label: '(UTC-04:00) Atlantic Time - Halifax' },
  { value: 'America/Barbados', label: '(UTC-04:00) Barbados' },
  { value: 'Atlantic/Bermuda', label: '(UTC-04:00) Bermuda' },
  { value: 'America/Boa_Vista', label: '(UTC-04:00) Boa Vista' },
  { value: 'America/Caracas', label: '(UTC-04:00) Caracas' },
  { value: 'America/Curacao', label: '(UTC-04:00) Curacao' },
  { value: 'America/Grand_Turk', label: '(UTC-04:00) Grand Turk' },
  { value: 'America/Guyana', label: '(UTC-04:00) Guyana' },
  { value: 'America/La_Paz', label: '(UTC-04:00) La Paz' },
  { value: 'America/Manaus', label: '(UTC-04:00) Manaus' },
  { value: 'America/Martinique', label: '(UTC-04:00) Martinique' },
  { value: 'America/Port_of_Spain', label: '(UTC-04:00) Port of Spain' },
  { value: 'America/Porto_Velho', label: '(UTC-04:00) Porto Velho' },
  { value: 'America/Puerto_Rico', label: '(UTC-04:00) Puerto Rico' },
  { value: 'America/Santo_Domingo', label: '(UTC-04:00) Santo Domingo' },
  { value: 'America/Thule', label: '(UTC-04:00) Thule' },
  { value: 'America/St_Johns', label: '(UTC-03:30) Newfoundland Time - St. Johns' },
  { value: 'America/Araguaina', label: '(UTC-03:00) Araguaina' },
  { value: 'America/Asuncion', label: '(UTC-03:00) Asuncion' },
  { value: 'America/Belem', label: '(UTC-03:00) Belem' },
  { value: 'America/Argentina/Buenos_Aires', label: '(UTC-03:00) Buenos Aires' },
  { value: 'America/Campo_Grande', label: '(UTC-03:00) Campo Grande' },
  { value: 'America/Cayenne', label: '(UTC-03:00) Cayenne' },
  { value: 'America/Cuiaba', label: '(UTC-03:00) Cuiaba' },
  { value: 'America/Fortaleza', label: '(UTC-03:00) Fortaleza' },
  { value: 'America/Godthab', label: '(UTC-03:00) Godthab' },
  { value: 'America/Maceio', label: '(UTC-03:00) Maceio' },
  { value: 'America/Miquelon', label: '(UTC-03:00) Miquelon' },
  { value: 'America/Montevideo', label: '(UTC-03:00) Montevideo' },
  { value: 'Antarctica/Palmer', label: '(UTC-03:00) Palmer' },
  { value: 'America/Paramaribo', label: '(UTC-03:00) Paramaribo' },
  { value: 'America/Punta_Arenas', label: '(UTC-03:00) Punta Arenas' },
  { value: 'America/Recife', label: '(UTC-03:00) Recife' },
  { value: 'Antarctica/Rothera', label: '(UTC-03:00) Rothera' },
  { value: 'America/Bahia', label: '(UTC-03:00) Salvador' },
  { value: 'America/Santiago', label: '(UTC-03:00) Santiago' },
  { value: 'Atlantic/Stanley', label: '(UTC-03:00) Stanley' },
  { value: 'America/Noronha', label: '(UTC-02:00) Noronha' },
  { value: 'America/Sao_Paulo', label: '(UTC-02:00) Sao Paulo' },
  { value: 'Atlantic/South_Georgia', label: '(UTC-02:00) South Georgia' },
  { value: 'Atlantic/Azores', label: '(UTC-01:00) Azores' },
  { value: 'Atlantic/Cape_Verde', label: '(UTC-01:00) Cape Verde' },
  { value: 'America/Scoresbysund', label: '(UTC-01:00) Scoresbysund' },
  { value: 'Africa/Abidjan', label: '(UTC+00:00) Abidjan' },
  { value: 'Africa/Accra', label: '(UTC+00:00) Accra' },
  { value: 'Africa/Bissau', label: '(UTC+00:00) Bissau' },
  { value: 'Atlantic/Canary', label: '(UTC+00:00) Canary Islands' },
  { value: 'Africa/Casablanca', label: '(UTC+00:00) Casablanca' },
  { value: 'America/Danmarkshavn', label: '(UTC+00:00) Danmarkshavn' },
  { value: 'Europe/Dublin', label: '(UTC+00:00) Dublin' },
  { value: 'Africa/El_Aaiun', label: '(UTC+00:00) El Aaiun' },
  { value: 'Atlantic/Faroe', label: '(UTC+00:00) Faeroe' },
  { value: 'Etc/UTC', label: '(UTC+00:00) UTC (no daylight saving)' },
  { value: 'Europe/Lisbon', label: '(UTC+00:00) Lisbon' },
  { value: 'Europe/London', label: '(UTC+00:00) London' },
  { value: 'Africa/Monrovia', label: '(UTC+00:00) Monrovia' },
  { value: 'Atlantic/Reykjavik', label: '(UTC+00:00) Reykjavik' },
  { value: 'UTC', label: 'UTC' },
  { value: 'Africa/Algiers', label: '(UTC+01:00) Algiers' },
  { value: 'Europe/Amsterdam', label: '(UTC+01:00) Amsterdam' },
  { value: 'Europe/Andorra', label: '(UTC+01:00) Andorra' },
  { value: 'Europe/Berlin', label: '(UTC+01:00) Berlin' },
  { value: 'Europe/Brussels', label: '(UTC+01:00) Brussels' },
  { value: 'Europe/Budapest', label: '(UTC+01:00) Budapest' },
  { value: 'Europe/Belgrade', label: '(UTC+01:00) Central European Time - Belgrade' },
  { value: 'Europe/Prague', label: '(UTC+01:00) Central European Time - Prague' },
  { value: 'Africa/Ceuta', label: '(UTC+01:00) Ceuta' },
  { value: 'Europe/Copenhagen', label: '(UTC+01:00) Copenhagen' },
  { value: 'Europe/Gibraltar', label: '(UTC+01:00) Gibraltar' },
  { value: 'Africa/Lagos', label: '(UTC+01:00) Lagos' },
  { value: 'Europe/Luxembourg', label: '(UTC+01:00) Luxembourg' },
  { value: 'Europe/Madrid', label: '(UTC+01:00) Madrid' },
  { value: 'Europe/Malta', label: '(UTC+01:00) Malta' },
  { value: 'Europe/Monaco', label: '(UTC+01:00) Monaco' },
  { value: 'Africa/Ndjamena', label: '(UTC+01:00) Ndjamena' },
  { value: 'Europe/Oslo', label: '(UTC+01:00) Oslo' },
  { value: 'Europe/Paris', label: '(UTC+01:00) Paris' },
  { value: 'Europe/Rome', label: '(UTC+01:00) Rome' },
  { value: 'Europe/Stockholm', label: '(UTC+01:00) Stockholm' },
  { value: 'Europe/Tirane', label: '(UTC+01:00) Tirane' },
  { value: 'Africa/Tunis', label: '(UTC+01:00) Tunis' },
  { value: 'Europe/Vienna', label: '(UTC+01:00) Vienna' },
  { value: 'Europe/Warsaw', label: '(UTC+01:00) Warsaw' },
  { value: 'Europe/Zurich', label: '(UTC+01:00) Zurich' },
  { value: 'Asia/Amman', label: '(UTC+02:00) Amman' },
  { value: 'Europe/Athens', label: '(UTC+02:00) Athens' },
  { value: 'Asia/Beirut', label: '(UTC+02:00) Beirut' },
  { value: 'Europe/Bucharest', label: '(UTC+02:00) Bucharest' },
  { value: 'Africa/Cairo', label: '(UTC+02:00) Cairo' },
  { value: 'Europe/Chisinau', label: '(UTC+02:00) Chisinau' },
  { value: 'Asia/Damascus', label: '(UTC+02:00) Damascus' },
  { value: 'Asia/Gaza', label: '(UTC+02:00) Gaza' },
  { value: 'Europe/Helsinki', label: '(UTC+02:00) Helsinki' },
  { value: 'Asia/Jerusalem', label: '(UTC+02:00) Jerusalem' },
  { value: 'Africa/Johannesburg', label: '(UTC+02:00) Johannesburg' },
  { value: 'Africa/Khartoum', label: '(UTC+02:00) Khartoum' },
  { value: 'Europe/Kiev', label: '(UTC+02:00) Kiev' },
  { value: 'Africa/Maputo', label: '(UTC+02:00) Maputo' },
  { value: 'Europe/Kaliningrad', label: '(UTC+02:00) Kaliningrad' },
  { value: 'Asia/Nicosia', label: '(UTC+02:00) Nicosia' },
  { value: 'Europe/Riga', label: '(UTC+02:00) Riga' },
  { value: 'Europe/Sofia', label: '(UTC+02:00) Sofia' },
  { value: 'Europe/Tallinn', label: '(UTC+02:00) Tallinn' },
  { value: 'Africa/Tripoli', label: '(UTC+02:00) Tripoli' },
  { value: 'Europe/Vilnius', label: '(UTC+02:00) Vilnius' },
  { value: 'Africa/Windhoek', label: '(UTC+02:00) Windhoek' },
  { value: 'Asia/Baghdad', label: '(UTC+03:00) Baghdad' },
  { value: 'Europe/Istanbul', label: '(UTC+03:00) Istanbul' },
  { value: 'Europe/Minsk', label: '(UTC+03:00) Minsk' },
  { value: 'Europe/Moscow', label: '(UTC+03:00) Moscow' },
  { value: 'Africa/Nairobi', label: '(UTC+03:00) Nairobi' },
  { value: 'Asia/Qatar', label: '(UTC+03:00) Qatar' },
  { value: 'Asia/Riyadh', label: '(UTC+03:00) Riyadh' },
  { value: 'Antarctica/Syowa', label: '(UTC+03:00) Syowa' },
  { value: 'Asia/Tehran', label: '(UTC+03:30) Tehran' },
  { value: 'Asia/Baku', label: '(UTC+04:00) Baku' },
  { value: 'Asia/Dubai', label: '(UTC+04:00) Dubai' },
  { value: 'Indian/Mahe', label: '(UTC+04:00) Mahe' },
  { value: 'Indian/Mauritius', label: '(UTC+04:00) Mauritius' },
  { value: 'Europe/Samara', label: '(UTC+04:00) Samara' },
  { value: 'Indian/Reunion', label: '(UTC+04:00) Reunion' },
  { value: 'Asia/Tbilisi', label: '(UTC+04:00) Tbilisi' },
  { value: 'Asia/Yerevan', label: '(UTC+04:00) Yerevan' },
  { value: 'Asia/Kabul', label: '(UTC+04:30) Kabul' },
  { value: 'Asia/Aqtau', label: '(UTC+05:00) Aqtau' },
  { value: 'Asia/Aqtobe', label: '(UTC+05:00) Aqtobe' },
  { value: 'Asia/Ashgabat', label: '(UTC+05:00) Ashgabat' },
  { value: 'Asia/Dushanbe', label: '(UTC+05:00) Dushanbe' },
  { value: 'Asia/Karachi', label: '(UTC+05:00) Karachi' },
  { value: 'Indian/Kerguelen', label: '(UTC+05:00) Kerguelen' },
  { value: 'Indian/Maldives', label: '(UTC+05:00) Maldives' },
  { value: 'Antarctica/Mawson', label: '(UTC+05:00) Mawson' },
  { value: 'Asia/Yekaterinburg', label: '(UTC+05:00) Yekaterinburg' },
  { value: 'Asia/Tashkent', label: '(UTC+05:00) Tashkent' },
  { value: 'Asia/Colombo', label: '(UTC+05:30) Colombo' },
  { value: 'Asia/Kolkata', label: '(UTC+05:30) India Standard Time' },
  { value: 'Asia/Katmandu', label: '(UTC+05:45) Katmandu' },
  { value: 'Asia/Almaty', label: '(UTC+06:00) Almaty' },
  { value: 'Asia/Bishkek', label: '(UTC+06:00) Bishkek' },
  { value: 'Indian/Chagos', label: '(UTC+06:00) Chagos' },
  { value: 'Asia/Dhaka', label: '(UTC+06:00) Dhaka' },
  { value: 'Asia/Omsk', label: '(UTC+06:00) Omsk' },
  { value: 'Asia/Thimphu', label: '(UTC+06:00) Thimphu' },
  { value: 'Antarctica/Vostok', label: '(UTC+06:00) Vostok' },
  { value: 'Indian/Cocos', label: '(UTC+06:30) Cocos' },
  { value: 'Asia/Yangon', label: '(UTC+06:30) Rangoon' },
  { value: 'Asia/Bangkok', label: '(UTC+07:00) Bangkok' },
  { value: 'Indian/Christmas', label: '(UTC+07:00) Christmas' },
  { value: 'Antarctica/Davis', label: '(UTC+07:00) Davis' },
  { value: 'Asia/Saigon', label: '(UTC+07:00) Hanoi' },
  { value: 'Asia/Hovd', label: '(UTC+07:00) Hovd' },
  { value: 'Asia/Jakarta', label: '(UTC+07:00) Jakarta' },
  { value: 'Asia/Krasnoyarsk', label: '(UTC+07:00) Krasnoyarsk' },
  { value: 'Asia/Brunei', label: '(UTC+08:00) Brunei' },
  { value: 'Asia/Shanghai', label: '(UTC+08:00) China Time - Beijing' },
  { value: 'Asia/Choibalsan', label: '(UTC+08:00) Choibalsan' },
  { value: 'Asia/Hong_Kong', label: '(UTC+08:00) Hong Kong' },
  { value: 'Asia/Kuala_Lumpur', label: '(UTC+08:00) Kuala Lumpur' },
  { value: 'Asia/Macau', label: '(UTC+08:00) Macau' },
  { value: 'Asia/Makassar', label: '(UTC+08:00) Makassar' },
  { value: 'Asia/Manila', label: '(UTC+08:00) Manila' },
  { value: 'Asia/Irkutsk', label: '(UTC+08:00) Irkutsk' },
  { value: 'Asia/Singapore', label: '(UTC+08:00) Singapore' },
  { value: 'Asia/Taipei', label: '(UTC+08:00) Taipei' },
  { value: 'Asia/Ulaanbaatar', label: '(UTC+08:00) Ulaanbaatar' },
  { value: 'Australia/Perth', label: '(UTC+08:00) Western Time - Perth' },
  { value: 'Asia/Pyongyang', label: '(UTC+08:30) Pyongyang' },
  { value: 'Asia/Dili', label: '(UTC+09:00) Dili' },
  { value: 'Asia/Jayapura', label: '(UTC+09:00) Jayapura' },
  { value: 'Asia/Yakutsk', label: '(UTC+09:00) Yakutsk' },
  { value: 'Pacific/Palau', label: '(UTC+09:00) Palau' },
  { value: 'Asia/Seoul', label: '(UTC+09:00) Seoul' },
  { value: 'Asia/Tokyo', label: '(UTC+09:00) Tokyo' },
  { value: 'Australia/Darwin', label: '(UTC+09:30) Central Time - Darwin' },
  { value: 'Antarctica/DumontDUrville', label: '(UTC+10:00) Dumont D"Urville' },
  { value: 'Australia/Brisbane', label: '(UTC+10:00) Eastern Time - Brisbane' },
  { value: 'Pacific/Guam', label: '(UTC+10:00) Guam' },
  { value: 'Asia/Vladivostok', label: '(UTC+10:00) Vladivostok' },
  { value: 'Pacific/Port_Moresby', label: '(UTC+10:00) Port Moresby' },
  { value: 'Pacific / Chuuk', label: '(UTC+10:00) Truk' },
  { value: 'Australia/Adelaide', label: '(UTC+10:30) Central Time - Adelaide' },
  { value: 'Antarctica/Casey', label: '(UTC+11:00) Casey' },
  { value: 'Australia/Hobart', label: '(UTC+11:00) Eastern Time - Hobart' },
  { value: 'Australia/Sydney', label: '(UTC+11:00) Eastern Time - Melbourne, Sydney' },
  { value: 'Pacific/Efate', label: '(UTC+11:00) Efate' },
  { value: 'Pacific/Guadalcanal', label: '(UTC+11:00) Guadalcanal' },
  { value: 'Pacific/Kosrae', label: '(UTC+11:00) Kosrae' },
  { value: 'Asia/Magadan', label: '(UTC+11:00) Magadan' },
  { value: 'Pacific / Norfolk', label: '(UTC+11:00) Norfolk' },
  { value: 'Pacific/Noumea', label: '(UTC+11:00) Noumea' },
  { value: 'Pacific/Pohnpei', label: '(UTC+11:00) Ponape' },
  { value: 'Pacific/Funafuti', label: '(UTC+12:00) Funafuti' },
  { value: 'Pacific/Kwajalein', label: '(UTC+12:00) Kwajalein' },
  { value: 'Pacific/Majuro', label: '(UTC+12:00) Majuro' },
  { value: 'Asia/Kamchatka', label: '(UTC+12:00) Petropavlovsk - Kamchatskiy' },
  { value: 'Pacific / Nauru', label: '(UTC+12:00) Nauru' },
  { value: 'Pacific/Tarawa', label: '(UTC+12:00) Tarawa' },
  { value: 'Pacific/Wake', label: '(UTC+12:00) Wake' },
  { value: 'Pacific/Wallis', label: '(UTC+12:00) Wallis' },
  { value: 'Pacific/Auckland', label: '(UTC+13:00) Auckland' },
  { value: 'Pacific/Enderbury', label: '(UTC+13:00) Enderbury' },
  { value: 'Pacific/Fakaofo', label: '(UTC+13:00) Fakaofo' },
  { value: 'Pacific/Fiji', label: '(UTC+13:00) Fiji' },
  { value: 'Pacific/Tongatapu', label: '(UTC+13:00) Tongatapu' },
  { value: 'Pacific/Apia', label: '(UTC+14:00) Apia' },
  { value: 'Pacific/Kiritimati', label: '(UTC+14:00) Kiritimati' },
];

export const sessionNotificationOptions: ISelectOptions[] = [
  { value: 60 * 60, label: '1 hour' },
  { value: 2 * 60 * 60, label: '2 hour' },
  { value: 24 * 60 * 60, label: '1 day' },
  { value: 2 * 24 * 60 * 60, label: '2 days' },
  { value: 7 * 24 * 60 * 60, label: '1 week' },
];

export const sessionVerificationOptions: ISelectOptions[] = [
  { value: ESessionValidationType.pass, label: ESessionValidationType.pass },
  { value: ESessionValidationType.attendance, label: ESessionValidationType.attendance },
];
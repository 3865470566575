import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchAPInvoiceRequest = (
  params: TYPES.IFetchAPInvoiceRequestParams,
): TYPES.IFetchAPInvoiceRequest => ({
  type: ACTION_TYPES.FETCH_AP_INCOICE_REQUEST,
  params,
});

export const fetchAPInvoiceSuccess = (
  payload: TYPES.IFetchAPInvoiceSuccessPayload,
): TYPES.IFetchAPInvoiceSuccess => ({
  type: ACTION_TYPES.FETCH_AP_INCOICE_SUCCESS,
  payload,
});

export const viewAPInvoiceRequest = (
  params: TYPES.IViewAPInvoiceRequestParams,
): TYPES.IViewAPInvoiceRequest => ({
  type: ACTION_TYPES.FETCH_AP_INCOICE_VIEW_REQUEST,
  params,
});

export const viewAPInvoiceSuccess = (
  payload: TYPES.IViewAPInvoiceSuccessPayload,
): TYPES.IViewPosSuccess => ({
  type: ACTION_TYPES.FETCH_AP_INCOICE_VIEW_SUCCESS,
  payload,
});

export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST';
export const FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS';

export const CREATE_TRAININGS_REQUEST = 'CREATE_TRAININGS_REQUEST';
export const CREATE_TRAININGS_SUCCESS = 'CREATE_TRAININGS_SUCCESS';

export const UPDATE_TRAININGS_REQUEST = 'UPDATE_TRAININGS_REQUEST';
export const UPDATE_TRAININGS_SUCCESS = 'UPDATE_TRAININGS_SUCCESS';

export const FETCH_TRAININGS_VIEW_REQUEST = 'FETCH_TRAININGS_VIEW_REQUEST';
export const FETCH_TRAININGS_VIEW_SUCCESS = 'FETCH_TRAININGS_VIEW_SUCCESS';

export const FETCH_TRAININGS_PROFILE_REQUEST = 'FETCH_TRAININGS_PROFILE_REQUEST';
export const FETCH_TRAININGS_PROFILE_SUCCESS = 'FETCH_TRAININGS_PROFILE_SUCCESS';

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
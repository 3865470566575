import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const notifyOpen = (
  payload: TYPES.INotifyOpenPayload,
): TYPES.NotifyOpen => ({
  type: ACTION_TYPES.NOTIFY_OPEN,
  payload,
});

export const notifyClose = (): TYPES.NotifyClose => ({
  type: ACTION_TYPES.NOTIFY_CLOSE,
});

export const asideOpen = (): TYPES.AsideOpen => ({
  type: ACTION_TYPES.ASIDE_OPEN,
});

export const asideClose = (): TYPES.AsideClose => ({
  type: ACTION_TYPES.ASIDE_CLOSE,
});

export const setError = (
  payload: TYPES.ISetErrorPayload,
): TYPES.ISetError => ({
  type: ACTION_TYPES.SET_ERROR,
  payload,
});

export const setPending = (
  payload: TYPES.ISetPendingPayload,
): TYPES.ISetPending => ({
  type: ACTION_TYPES.SET_PENDING,
  payload,
});

export const setSending = (
  payload: TYPES.ISetSendingPayload,
): TYPES.ISetSending => ({
  type: ACTION_TYPES.SET_SENDING,
  payload,
});

export const setActiveFiltersCollapsed= (
  payload: TYPES.ISetActiveFiltersCollapsedPayload,
): TYPES.ISetActiveFiltersCollapsed => ({
  type: ACTION_TYPES.SET_ACTIVE_FILTERS_COLLAPSED,
  payload,
});
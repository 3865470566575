import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import TrainingsViewLaunchListQuestion from './TrainingsViewLaunchListQuestion/TrainingsViewLaunchListQuestion';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const TrainingsViewLaunchList = ({data}: any) => {  
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeTab = (newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '250px', maxWidth: '250px' }}
      >
        {data.online_content.map((item: any, index: number) => (
          <Tab key={item.id} label={item.title} {...a11yProps(index)} />
        ))}
      </Tabs>
      {data.online_content.map((item: any, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          <div style={{ height: 'calc(100vh - 280px)', overflow: 'auto' }}>
            {item.type === 'text' && <Typography dangerouslySetInnerHTML={{ __html: item.text_content }} />}
            {item.type === 'quiz' && <TrainingsViewLaunchListQuestion quizList={item} />}
            {item.type === 'video' && <div>video</div>}
          </div>
          <Stack direction="row" spacing={2} sx={{display: 'flex', position: 'absolute', bottom: '10px', right: '10px'}}>
            {index > 0 && <Button variant="outlined" onClick={() => handleChangeTab(index - 1)}>Go Back</Button>}
            {data.online_content.length !== index + 1 && <Button variant="outlined" onClick={() => handleChangeTab(index + 1)}>Continue</Button>}
            {data.online_content.length === index + 1 && <Button variant="outlined" color="success">Submit</Button>}
          </Stack>
        </TabPanel>
      ))}
    </Box>
  );
}

export default TrainingsViewLaunchList;

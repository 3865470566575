import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { ISelectOptions } from '../../../types/common';

interface IFilterSelectProps {
  name: string,
  multiple?: boolean,
  label?: string,
  updateLocation?: boolean,
  options: ISelectOptions[],
  filters: any,
  onChange: (name: string, value: string) => void,
  onRemove?: (name: string) => void,
}

const FilterSelect = (
  {
    name,
    label,
    multiple,
    options,
    updateLocation = true,
    filters,
    onChange,
    onRemove,
    ...props
  }: IFilterSelectProps): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [values, setValues] = React.useState<string[] | string>(multiple ? [] : '');

  const handleChange = (event: any) => {
    const { target: { value } } = event;
    onChange(name, typeof value === 'string' ? value.split(',') : value);
    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  const handleRemove = () => {
    onRemove?.(name);
    setValues(multiple ? [] : '');
  };

  useEffect(() => {
    if (updateLocation) {
      if (!isEmpty(filters)) {
        searchParams.set('filter', Object.entries(filters).map(([key, value]) => `${key}=${value}`).join(';'));
        navigate(`?${searchParams}`);
      }
      if (isEmpty(filters) && searchParams.get('filter')) {
        searchParams.delete('filter');
        navigate(`?${searchParams}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLocation, filters, values]);

  return (
    <FormControl size="small" sx={{ width: 180 }}>
      {label && <InputLabel>{label}</InputLabel>}
      <Stack direction="row">
        <Select
          displayEmpty
          name={name}
          value={values}
          label={label}
          multiple={multiple}
          onChange={handleChange}
          sx={{
            flexGrow: 1,
            minWidth: 0,
          }}
          {...props}
        >
          {
            options.map((option) => (
              <MenuItem key={`${option.label} ${option.value}`} value={option.value}>{option.label}</MenuItem>
            ))
          }
        </Select>
        {
          onRemove && values.length > 0 &&
          <IconButton onClick={handleRemove}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      </Stack>
    </FormControl>
  );
};

export default FilterSelect;
export const FETCH_MANPOWER_REQUEST = 'FETCH_MANPOWER_REQUEST';
export const FETCH_MANPOWER_SUCCESS = 'FETCH_MANPOWER_SUCCESS';

export const CREATE_MANPOWER_REQUEST = 'CREATE_MANPOWER_REQUEST';

export const FETCH_MANPOWER_DEFAULTS_REQUEST = 'FETCH_MANPOWER_DEFAULTS_REQUEST';
export const FETCH_MANPOWER_DEFAULTS_SUCCESS = 'FETCH_MANPOWER_DEFAULTS_SUCCESS';

export const VIEW_MANPOWER_REQUEST = 'VIEW_MANPOWER_REQUEST';
export const VIEW_MANPOWER_REQUEST_SUCCESS = 'VIEW_MANPOWER_REQUEST_SUCCESS';

export const UPDATE_MANPOWER_DETAILS_REQUEST = 'UPDATE_MANPOWER_DETAILS_REQUEST';
export const UPDATE_MANPOWER_DETAILS_REQUEST_SUCCESS = 'UPDATE_MANPOWER_REQUEST_DETAILS_SUCCESS';

export const CREATE_NEW_MANPOWER_DETAILS = 'CREATE_NEW_MANPOWER_DETAILS';
export const CREATE_NEW_MANPOWER_DETAILS_SUCCESS = 'CREATE_NEW_MANPOWER_DETAILS_SUCCESS';

export const UPDATE_MANPOWER_REQUEST = 'UPDATE_MANPOWER_REQUEST';
export const UPDATE_MANPOWER_REQUEST_SUCCESS = 'UPDATE_MANPOWER_REQUEST_SUCCESS';

export const DELETE_MANPOWER_REQUEST_DETAIL = 'DELETE_MANPOWER_REQUEST_DETAIL';
export const DELETE_MANPOWER_REQUEST_DETAIL_SUCCESS = 'DELETE_MANPOWER_REQUEST_DETAIL_SUCCESS';
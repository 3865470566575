export const NOTIFY_OPEN = 'NOTIFY_OPEN';
export const NOTIFY_CLOSE = 'NOTIFY_CLOSE';

export const ASIDE_OPEN = 'ASIDE_OPEN';
export const ASIDE_CLOSE = 'ASIDE_CLOSE';

export const SET_ERROR = 'SET_ERROR';
export const SET_PENDING = 'SET_PENDING';
export const SET_SENDING = 'SET_SENDING';

export const SET_ACTIVE_FILTERS_COLLAPSED = 'SET_ACTIVE_FILTERS_COLLAPSED';
import React, { memo } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';

import { ETrainingOnlineContentQuestionTypes, ITrainingOnlineContentQuestion } from '../../../../types/trainings';
import { trainingQuestionAnswersTypes } from '../../../../constants/selectOptions';
import { getLastArrayValue, isEqualPropsMemo } from '../../../../utils/helpers';
import FormLabel from '../../../ui/FormLabel';
import SelectField from '../../../ui/SelectField';
import TextEditor from '../../../ui/TextEditor';

interface TrainingFormQuestionProps {
  active: number;
  questions: ITrainingOnlineContentQuestion[];
  onChange: (e: any) => void;
  onAddQuestion: (id: number) => void;
  onSetFieldValue: (name: string, value: any) => void;
  onRemoveQuestion: (qIndex: number) => void;
  onAddAnswer: (qIndex: number, id: number) => void;
  onRemoveAnswer: (qIndex: number, id: number) => void;
  onChangeAnswerCorrect: (qIndex: number, aIndex: number, type: ETrainingOnlineContentQuestionTypes, value: boolean) => void;
}

const TrainingFormQuestion:
  React.FC<TrainingFormQuestionProps> = (
  {
    active,
    questions,
    onChange,
    onAddAnswer,
    onRemoveAnswer,
    onAddQuestion,
    onRemoveQuestion,
    onSetFieldValue,
    onChangeAnswerCorrect,
  }: TrainingFormQuestionProps) => {
  const [value, setValue] = React.useState('1');

  const handleChangeValue = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleRemoveQuestion = (index: number) => {
    onRemoveQuestion(index);
    setValue('1');
  };

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeValue}>
            {
              questions.map((q: ITrainingOnlineContentQuestion, qIndex: number) => (
                <Tab
                  key={`question-${q.id}`}
                  label={
                    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                      Question {qIndex + 1}
                      {questions.length > 1 &&
                        <IconButton component="span" size="small" onClick={() => handleRemoveQuestion(qIndex)}>
                          <CloseIcon fontSize="small" />
                        </IconButton>}
                    </Stack>
                  }
                  value={`${qIndex + 1}`} />
              ))
            }
          </TabList>
        </Box>
        {
          questions.map((q: ITrainingOnlineContentQuestion, qIndex) => (
            <TabPanel key={`question-content-${q.id}`} value={`${qIndex + 1}`}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12} sm={8} md={9}>
                  <TextEditor
                    value={q.question}
                    name={`online_content[${active}].questions[${qIndex}].question`}
                    onSetFieldValue={onSetFieldValue}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormLabel title="Question type" required />
                  <SelectField
                    name={`online_content[${active}].questions[${qIndex}].type`}
                    value={q.type}
                    options={trainingQuestionAnswersTypes}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
              <FormControl fullWidth margin="normal">
                <FormLabel title="Please choose the correct answer:" required />
                <RadioGroup
                  name="answers"
                >
                  {
                    q.answers.map((item, aIndex) => (
                      <Stack key={item.id} direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                        {
                          q.type === ETrainingOnlineContentQuestionTypes.multy
                            ? <Checkbox
                              name={`online_content[${active}].questions[${qIndex}].answers[${aIndex}].correct`}
                              checked={item.correct}
                              onChange={onChange}
                            />
                            : <FormControlLabel
                              label=""
                              name={`online_content[${active}].questions[${qIndex}].answers[${aIndex}].correct`}
                              checked={item.correct}
                              value={item.correct}
                              control={<Radio />}
                              onChange={() => onChangeAnswerCorrect(qIndex, aIndex, q.type, !item.correct)}
                            />
                        }
                        <TextField
                          fullWidth
                          name={`online_content[${active}].questions[${qIndex}].answers[${aIndex}].answer`}
                          value={item.answer}
                          onChange={onChange}
                        />
                        {q.answers.length > 1 &&
                          <IconButton size="small" color="error" onClick={() => onRemoveAnswer(qIndex, item.id)}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        }
                      </Stack>
                    ))
                  }
                </RadioGroup>
              </FormControl>
              <Button
                variant="contained"
                disabled={!getLastArrayValue(q.answers).answer}
                onClick={() => onAddAnswer(qIndex, getLastArrayValue(q.answers).id + 1)}
              >
                Add Answer
              </Button>
            </TabPanel>
          ))
        }
      </TabContext>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disabled={!getLastArrayValue(questions).question || !getLastArrayValue(questions).answers.filter((f: any) => f.correct).length}
            onClick={() => onAddQuestion(getLastArrayValue(questions).id + 1)}
          >
            Add question
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(TrainingFormQuestion, isEqualPropsMemo);
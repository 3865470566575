import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ size: ["small", false, "large", "huge"] }]
  ],
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const RichTextEditor = ({ name, value, error, onSetFieldValue }) => {
  const [state, setState] = React.useState({ value: null });
    useEffect(() => {
      setState({ value })
  }, [value])
  const handleChange = value => {
    setState({ value });
    // onSetFieldValue(name, { value })
    // console.log(name, value);
    onSetFieldValue(name, value)
  };
  return (
    <div className={error ? 'error-validation text-editor' : 'text-editor'} >
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={handleChange}
        modules={modules}
      />
    </div>
  );
};

export default RichTextEditor;
export const FETCH_ISSUES_REQUEST = 'FETCH_ISSUES_REQUEST';
export const FETCH_ISSUES_SUCCESS = 'FETCH_ISSUES_SUCCESS';

export const CREATE_ISSUES_REQUEST = 'CREATE_ISSUES_REQUEST';

export const VIEW_ISSUES_REQUEST = 'VIEW_ISSUES_REQUEST';
export const VIEW_ISSUES_SUCCESS = 'VIEW_ISSUES_SUCCESS';

export const UPDATE_ISSUES_REQUEST = 'UPDATE_ISSUES_REQUEST';
export const UPDATE_ISSUES_SUCCESS = 'UPDATE_ISSUES_SUCCESS';

import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { ESessionStatus, ESessionValidationType, ISessionItem } from '../../../types/session';
import { getTrainingsSelector } from '../../../store/trainings/selectors';
import { createSessionRequest } from '../../../store/trainings/actions';
import { getIsSendingSelector } from '../../../store/common/selectors';
import { SessionFormValidation } from '../../../constants/validations';
import { useDocuments } from '../../../utils/hooks';
import Documents from '../../ui/Documents';
import LoadingOverlay from '../../ui/LoadingOverlay';
import SessionInfoForm from '../SessionInfoForm';

const SessionTabs = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trainings = useSelector(getTrainingsSelector);
  const sending = useSelector(getIsSendingSelector);
  const [value, setValue] = React.useState<string>('1');
  const { documents, handleRemoveDocuments, handleUpdateDocuments } = useDocuments();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      training: {
        id: '',
        name: '',
        category: '',
        description: '',
        type: '',
        language: '',
        duration: 0,
        level: '',
      },
      time_zone: '',
      start_time: '',
      finish_time: '',
      verification_type: ESessionValidationType,
      status: ESessionStatus,
      link: '',
      notes: '',
      trainer: {
        fullname: '',
        email: '',
        phone: '',
      },
      address: {
        zipcode: '',
        country: '',
        state: '',
        city: '',
        street: '',
      },
      notifications: [],
      participants: [],
      documents: [],
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: SessionFormValidation,
    onSubmit: (values: ISessionItem) => {
      dispatch(createSessionRequest({ data: values, training_id: values.training.id, navigate }));
    },
  });

  const handleChangeCategory = useCallback((v: string) => {
    setFieldValue('training', trainings.find(training => training.id === v));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainings]);

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;

  return (
    <TabContext value={value}>
      <form onSubmit={handleSubmit}>
        {
          sending && <LoadingOverlay />
        }
        <Box sx={{ textAlign: 'right' }}>
          <Button
            type="submit"
            variant="contained"
          >
            Create
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab}>
            <Tab label="Session Info" value="1" />
            <Tab label="Participants" disabled value="2" />
            <Tab label="Documents" value="3" />
            <Tab label="Roster" disabled value="4" />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <SessionInfoForm
            values={values}
            errors={errors}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            onChangeCategory={handleChangeCategory}
          />
        </TabPanel>
        <TabPanel value="2">Participants</TabPanel>
        <TabPanel value="3">
          <Documents
            documents={documents}
            onUpload={handleUpdateDocuments}
            onRemove={handleRemoveDocuments}
          />
        </TabPanel>
        <TabPanel value="4">Roster</TabPanel>
      </form>
    </TabContext>
  );
};

export default SessionTabs;
import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const InvoicesARRequestTable = ({items, users}: any) => {
  const navigate = useNavigate();
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const MaterialTable = require("material-table").default; 

  return (
    <MaterialTable
      title=""
      columns={[
        // Request #
        { 
          title: 'Request #', 
          field: 'RequestNumber',
          align: 'left',
          render: (rowData: any) =>
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => navigate(`/invoices/request/${rowData.RequestNumber}`)}
            >
              {rowData.RequestNumber}
            </span> 
        },   
        // Job #
        { 
          title: 'Job #', 
          field: 'Job',
          align: 'left',
          render: (rowData: any) =>
              <span 
                style={{ textDecoration: 'underline', cursor: 'pointer' }} 
                onClick={() => navigate(`/jobs/${rowData.Job}`)}
              >
                {rowData.Job}
              </span> 
        }, 
        // Subjob #
        { 
          title: 'Subjob #', 
          field: 'SubJob',
          align: 'left',
          render: (rowData: any) =>
            <>
              {rowData.SubJob}
            </> 
        }, 
        // Job Type
        { 
          title: 'Job Type', 
          field: 'JobType',
          align: 'left',
          render: (rowData: any) =>
          <>
            {rowData.JobType}
          </>
        }, 
        // Invoice  #
        { 
          title: 'Invoice  #', 
          field: 'InvoiceNumber',
          align: 'left',
          render: (rowData: any) =>
          {
            if (rowData.InvoiceNumber === '' || rowData.InvoiceNumber === null) {
              return <div>&mdash;</div>
            } else {
              return <div>AR-{rowData.InvoiceNumber}</div>
            }
          }
        }, 
        // Status
        { 
          title: 'Status', 
          field: 'Status', 
          lookup: { 'progress': 'In process', 'returned': 'Returned', 'completed': 'Completed' },
          align: 'left',
          render: (rowData: any) =>
          <>
            {rowData.Status === 'progress' && <div style={{ color: '#006aff' }}>In process</div>}
            {rowData.Status === 'completed' && <div style={{ color: '#2e7d32' }}>Completed</div>}
            {rowData.Status === 'returned' && <div style={{ color: '#f4ba00' }}>Returned</div>}
          </>
        },  
        // Amount remaining
        { 
          title: 'Amount remaining', 
          field: 'AmountRemaining',
          align: 'left',
          render: (rowData: any) =>
          {
            if (rowData.AmountRemaining === '' || rowData.AmountRemaining === null || rowData.JobType === 'T&M') {
              return <div>&mdash;</div>
            } else {
              return <div>${rowData.AmountRemaining.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            }
          }
        }, 
        // Amount request 
        { 
          title: 'Amount request', 
          field: 'AmountRequested',
          align: 'left',
          render: (rowData: any) =>
          <>${ rowData.AmountRequested.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }</>
        }, 
        // Amount invoiced
        { 
          title: 'Amount invoiced', 
          field: 'AmountInvoiced',
          align: 'left',
          render: (rowData: any) =>
            {
              if (rowData.AmountInvoiced === '' || rowData.AmountInvoiced === null) {
                return <div>&mdash;</div>
              } else {
                return <div>${rowData.AmountInvoiced.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
              }
            }
        }, 
        // Request date
        {
          title: 'Request date', 
          field: 'LastUpdated',
          align: 'left',
          render: (rowData: any) =>
            <>
            {rowData.Created && 
              <>
                <div>{moment(rowData.Created).local().format('DD.MM.YYYY')}</div>
                <div>{moment(rowData.Created).local().format('HH:mm')}</div>
              </>
            }
          </> 
        },
        // Completed date
        {
          title: 'Completed date', 
          field: 'Created',
          align: 'left',
          render: (rowData: any) =>
          <>
            {rowData.LastUpdated && rowData.Status === 'completed' && 
              <>
                <div>{moment(rowData.LastUpdated).format('DD.MM.YYYY')}</div>
                <div>{moment(rowData.LastUpdated).format('HH:mm')}</div>
              </>
            }
            {rowData.Status !== 'completed' && <div>&mdash;</div>}
          </> 
        },
        {
          title: 'Created by', 
          field: 'ReqByName',
          align: 'left',
          render: (rowData: any) =>
          <span data-value={rowData.ReqByName}
          style={{ textDecoration: 'underline', cursor: 'pointer' }} 
          onClick={() => {
            const user = users.filter((item: any) => rowData.ReqBy === item.user_vid)[0]?.id
            navigate(`/personnel/${user}`)
          }}
        >
          {users.filter((item: any) => rowData.ReqBy === item.user_vid)[0]?.user_fullname}
        </span> 
        }, 
      ]}
      data={items?.sort((a: any, b: any) => a.LastUpdated > b.LastUpdated ? -1 : 1)}
      options={{
        headerStyle: {
          whiteSpace: 'nowrap'
        },
        search: false,
        filtering: true,
        sorting: true,
        paging:true,
        pageSize:10,
        pageSizeOptions:[10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        // maxBodyHeight: 'calc(100vh - 280px)',
      }}
      actions={[
        {
          icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
          tooltip: "Сlear all filters",
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1);
          }
        }
      ]}
    />
  );
};

export default InvoicesARRequestTable;
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Loading from '../../ui/Loading';
import { ITrainingItem } from '../../../types/trainings';
import TrainingsCard from '../TrainingsCard';
import TablePagination from '../../ui/TablePagination';
import { useSelector } from 'react-redux';
import { getIsPendingSelector } from '../../../store/common/selectors';
import { getHasMoreSelector, getTrainingsSelector } from '../../../store/trainings/selectors';
import IconButton from '@mui/material/IconButton';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import Box from '@mui/material/Box';

const TrainingsList = () => {
  const [listView, setListView] = useState(false);
  const pending = useSelector(getIsPendingSelector);
  const trainings = useSelector(getTrainingsSelector);
  const hasMore = useSelector(getHasMoreSelector);

  const handleHideListView = () => setListView(false);
  const handleShowListView = () => setListView(true);

  return (
    <>
      {
        pending
        ? <Loading />
        : <>
          <Box sx={{ mb: 1 }}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <IconButton color={!listView ? 'primary' : 'default'} onClick={handleHideListView}>
                  <AppsOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton color={listView ? 'primary' : 'default'} onClick={handleShowListView}>
                  <ViewListIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            {trainings.map((training: ITrainingItem) => (
              <Grid key={training.id} item xs={listView ? 12 : 4}>
                <TrainingsCard
                  id={training.id}
                  status={training.status}
                  language={training.language}
                  title={training.name}
                  description={training.description}
                  duration={training.duration}
                  level={training.level}
                />
              </Grid>
            ))}
          </Grid>
          <TablePagination
            hasMore={hasMore}
            count={trainings.length}
          />    
        </>
      }
    </>
  );
};

export default TrainingsList;
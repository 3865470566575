import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { deleteIssueCategoriesRequest, fetchIssueCategoriesRequest } from '../store/system/issueCategories/actions';
import {
  getCategoriesListOptionsSelector,
  getItemsSelector,
  getPendingSelector,
} from '../store/system/issueCategories/selectors';
import { IIssueCategoriesItem } from '../types/system/issueCategories';
import { IHeadCell } from '../types/table';
import { useFilter } from '../hooks/useFilters';
import { useModal } from '../utils/hooks';
import IssueCategoriesTableRow from '../components/IssueCategories/IssueCategoriesTableRow/IssueCategoriesTableRow';
import IssueCategoriesForm from '../components/IssueCategories/IssueCategoriesForm/IssueCategoriesForm';
import ConfirmDeletingModal from '../components/ConfirmDeletingModal/ConfirmDeletingModal';
import FiltersContainer from '../components/ui/FiltersContainer/FiltersContainer';
import FilterSelect from '../components/ui/FilterSelect/FilterSelect';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import Search from '../components/ui/Search/Search';
import Table from '../components/ui/Table/Table';
import Layout from '../components/layout/Layout';

const tableHeadCells: IHeadCell[] = [
  { id: 'category', label: 'Category', numeric: false, disablePadding: false },
  { id: 'type', label: 'Type', numeric: false, disablePadding: false },
  { id: 'description', label: 'Description', numeric: false, disablePadding: false },
  { id: 'actions', label: '', numeric: false, disablePadding: false },
];

const defaultData = {
  id: '',
  type: '',
  category: '',
  description: '',
};

const IssueCategories: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const issues = useSelector(getItemsSelector);
  const pending = useSelector(getPendingSelector);
  const issueCategoriesOptions = useSelector(getCategoriesListOptionsSelector);
  const { isModalOpen: isEditModalOpen, handleToggleModal: handleToggleEditModal } = useModal();
  const { isModalOpen: isDeleteModalOpen, handleToggleModal: handleToggleDeleteModal } = useModal();
  const { filters, handleChangeFilters, handleRemoveFilters } = useFilter();
  const [renderIssues, setRenderIssues] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [editData, setEditData] = useState<IIssueCategoriesItem>({ ...defaultData });

  useEffect(() => {
    setRenderIssues(issues);
  }, [issues]);

  useEffect(() => {
    if (!issues.length) dispatch(fetchIssueCategoriesRequest());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters?.type) {
      setRenderIssues(issues.filter((i: IIssueCategoriesItem) => i.category === filters?.type[0]));
    } else {
      setRenderIssues(issues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleDelete = useCallback((id: string) => {
    setDeleteItemId(id);
    handleToggleDeleteModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmDelete = useCallback(() => {
    dispatch(deleteIssueCategoriesRequest({ id: deleteItemId }));
    handleToggleDeleteModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItemId]);

  const handleOpenEditModal = (itemData?: IIssueCategoriesItem) => {
    if (itemData) {
      setEditData(itemData);
    }
    handleToggleEditModal();
  };

  const handleCloseEditModal = () => {
    setEditData({ ...defaultData });
    handleToggleEditModal();
  };

  const handleSearchChange = (v: string) => {
    setRenderIssues(issues.filter((i: IIssueCategoriesItem) => i.category.includes(v) || i.type.includes(v) || i.description.includes(v)));
  };

  return (
    <Layout>
      <PageTitle title="Issue Categories" />
      <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <FiltersContainer>
            <FilterSelect
              name="type"
              label="Type"
              filters={filters}
              onRemove={handleRemoveFilters}
              onChange={handleChangeFilters}
              options={issueCategoriesOptions}
            />
            <Search onChange={handleSearchChange} />
          </FiltersContainer>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => handleOpenEditModal()}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <Table
        pending={pending}
        data={renderIssues}
        hasMore={renderIssues.length > 50}
        headCells={tableHeadCells}
        BodyCell={(props: any) =>
          <IssueCategoriesTableRow
            onDelete={handleDelete}
            onEdit={handleOpenEditModal}
            {...props}
          />}
      />
      <IssueCategoriesForm
        data={editData}
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
      />
      <ConfirmDeletingModal
        open={isDeleteModalOpen}
        onClose={handleToggleDeleteModal}
        onConfirm={handleConfirmDelete}
      />
    </Layout>
  );
};

export default IssueCategories;
import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchARInvoiceRequest = (
  params: TYPES.IFetchARInvoiceRequestParams,
): TYPES.IFetchARInvoiceRequest => ({
  type: ACTION_TYPES.FETCH_AR_INCOICE_REQUEST,
  params,
});

export const fetchARInvoiceSuccess = (
  payload: any[],
): TYPES.IFetchARInvoiceSuccess => ({
  type: ACTION_TYPES.FETCH_AR_INCOICE_SUCCESS,
  payload,
});

export const viewARInvoiceRequest = (
  params: TYPES.IViewARInvoiceRequestParams,
): TYPES.IViewARInvoiceRequest => ({
  type: ACTION_TYPES.FETCH_AR_INCOICE_VIEW_REQUEST,
  params,
});

export const viewARInvoiceSuccess = (
  payload: TYPES.IViewARInvoiceSuccessPayload,
): TYPES.IViewARSuccess => ({
  type: ACTION_TYPES.FETCH_AR_INCOICE_VIEW_SUCCESS,
  payload,
});

import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

export enum ESeverity {
  'low' = 'Low',
  'medium' = 'Medium',
  'high' = 'High',
}

export enum EStatus {
  'open' = 'Open',
  'reviewed' = 'Reviewed',
  'progress' = 'Progress',
  'closed' = 'Closed',
  'resolved' = 'Resolved',
  'duplicate' = 'Duplicate',
}

export enum EAction {
  'create' = 'create',
  'update' = 'update',
  'delete' = 'delete',
}

export interface ISelectOptions {
  label: string,
  value: string | number,
  Icon?: OverridableComponent<SvgIconTypeMap> | undefined,
}

export interface IDocumentItem {
  url?: string,
  id?: string,
  filename: string,
  filetype: string,
  filesize: number,
  description: string,
  action?: EAction,
  file: { [key: string]: any },
}
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';

export const LogoWrap = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    minWidth: 240,
  },
  'a': {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export const LinkButton = styled(NavLink)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  padding: '5px 15px',
  textDecoration: 'none',
  color: 'hsla(0,0%,100%,.8)',
  '&:hover': {
    textDecoration: 'none',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 2,
    backgroundColor: 'rgba(34,36,38,.15)',
  },
  '&.active': {
    '&::after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const StyledAppBar = styled(AppBar)({
  backgroundColor: '#14295f',
  boxShadow: '0 4px 4px 0 rgb(0 0 0 / 40%)',
  zIndex: 1210,
});

export const paperStyles = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
};
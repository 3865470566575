import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchARRequestInvoiceRequest = (
  params: TYPES.IFetchARRequestInvoicesRequestParams,
): TYPES.IFetchARRequestInvoicesRequest => ({
  type: ACTION_TYPES.FETCH_AR_REQUEST_INCOICE_REQUEST,
  params,
});

export const fetchARRequestInvoiceSuccess = (
  payload: any[],
): TYPES.IFetchARRequestInvoicesSuccess => ({
  type: ACTION_TYPES.FETCH_AR_REQUEST_INCOICE_SUCCESS,
  payload,
});


export const viewARRequestInvoiceRequest = (
  params: TYPES.IViewARRequestInvoicesRequestParams,
): TYPES.IViewARRequestInvoicesRequest => ({
  type: ACTION_TYPES.VIEW_AR_REQUEST_INCOICE_REQUEST,
  params,
});

export const viewARRequestInvoiceSuccess = (
  payload: any[],
): TYPES.IViewARRequestInvoicesSuccess => ({
  type: ACTION_TYPES.VIEW_AR_REQUEST_INCOICE_SUCCESS,
  payload,
});


export const createARRequestInvoiceRequest = (
  params: TYPES.ICreateARRequestInvoicesRequestParams,
): TYPES.ICreateARRequestInvoicesRequest => ({
  type: ACTION_TYPES.CREATE_AR_REQUEST_INCOICE_REQUEST,
  params,
});

export const createARRequestInvoiceSuccess = (
  payload: any[],
): TYPES.ICreateARRequestInvoicesSuccess => ({
  type: ACTION_TYPES.CREATE_AR_REQUEST_INCOICE_SUCCESS,
  payload,
});


export const updateARRequestInvoiceRequest = (
  params: TYPES.IUpdateARRequestInvoiceRequestParams,
): TYPES.IUpdateARRequestInvoiceRequest => ({
  type: ACTION_TYPES.UPDATE_AR_REQUEST_INCOICE_REQUEST,
  params,
});

export const updateARRequestInvoiceSuccess = (
  payload: any[],
): TYPES.IUpdateARRequestInvoiceSuccess => ({
  type: ACTION_TYPES.UPDATE_AR_REQUEST_INCOICE_SUCCESS,
  payload,
});

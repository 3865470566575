import { all, put, call, takeLatest } from 'redux-saga/effects';

import {
  fetchIssuesSuccess,
  updateIssuesSuccess,
  viewIssuesSuccess,
} from './actions';
import { FETCH_ISSUES_REQUEST, CREATE_ISSUES_REQUEST, VIEW_ISSUES_REQUEST, UPDATE_ISSUES_REQUEST } from './actionTypes';
import { ICreateIssuesRequest, IFetchIssuesRequest, IUpdateIssuesRequest, IViewIssuesRequest } from './types';
import { apiSagaRequest } from '../../utils/saga';
import { uploadFileToS3 } from '../../utils/helpers';
import { notifyOpen, setPending, setSending } from '../common/actions';
import { fetchUsersSuccess } from '../users/actions';
import { fetchIssueCategoriesSuccess } from '../system/issueCategories/actions';

function* fetchIssuesSaga({ params }: IFetchIssuesRequest): any {
  console.log('params', params);
  
  try {
    yield put(setPending(true));
    // const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${params.job}/ai`, params: params.params });
    const paramsFetch = '?size=1000000'
    const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${params.job}/ai`, params: paramsFetch });
    yield put(fetchIssuesSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* createIssuesSaga({ params }: ICreateIssuesRequest): any {
  try {
    const { data } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'post', url: `/jobs/${params.job_id}/ai`, data });
    const issueID = response.id;

    if (response.documents?.length) {
      yield all(response.documents.map(function* (d: { [key: string]: any }, index: number) {
        yield uploadFileToS3(d.url_post, data.documents![index]!.file);
      }));
    }

    params.navigate(`/issues/${issueID}`);
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue successfully added' }));
  } finally {
    yield put(setSending(false));
  }
}

function* viewIssuesSaga({ params }: IViewIssuesRequest): any {
  try {
    const { job_id, ai_id } = params;
    yield put(setPending(true));
    const users = yield call(apiSagaRequest, { type: 'get', url: '/users' });
    yield put(fetchUsersSuccess(users));
    const issueCategories = yield call(apiSagaRequest, { type: 'get', url: '/system/aic' });
    yield put(fetchIssueCategoriesSuccess(issueCategories));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${job_id}/ai/${ai_id}` });
    yield put(viewIssuesSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* updateIssuesSaga({ params }: IUpdateIssuesRequest): any {
  try {
    const { job_id, ai_id, data, updateItems } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'put', url: `/jobs/${job_id}/ai/${ai_id}`, data });

    if (response.documents?.length) {
      yield all(response.documents.map(function* (d: { [key: string]: any }, index: number) {
        yield uploadFileToS3(d.url_post, data.documents![index]!.file);
      }));
    }

    if (updateItems) {
      yield put(setPending(true));
      const response = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${job_id}/ai` });
      yield put(fetchIssuesSuccess(response));
      yield put(setPending(false));
    }

    yield put(updateIssuesSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue information successfully updated' }));
  } catch (e) {
    yield put(notifyOpen({ notifyType: 'error', notifyMessage: 'Issue information update failed' }));
  } finally {
    yield put(setSending(false));
  }
}

function* issuesSaga() {
  yield all([
    takeLatest(FETCH_ISSUES_REQUEST, fetchIssuesSaga),
    takeLatest(CREATE_ISSUES_REQUEST, createIssuesSaga),
    takeLatest(VIEW_ISSUES_REQUEST, viewIssuesSaga),
    takeLatest(UPDATE_ISSUES_REQUEST, updateIssuesSaga),
  ]);
}

export default issuesSaga;
import React, { memo, useEffect } from 'react';
import moment from 'moment';
import { getDaysDiff, isEqualPropsMemo } from '../../../utils/helpers';
import { useDocuments } from '../../../utils/hooks';
import { EAction } from '../../../types/common';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ImagePreview from '../../ui/ImagePreview/ImagePreview';
import InfoTooltip from '../../ui/InfoTooltip/InfoTooltip';
import Documents from '../../ui/Documents/Documents';
import { getItemsSelector } from '../../../store/system/issueCategories/selectors';
import { useSelector } from 'react-redux';
import Comments from '../Comments/Comments';

interface IIssuesFormProps {
  data?: { [key: string]: any };
}

const IssuesViewCard = ({ data }: IIssuesFormProps) => {
  const issueCategories = useSelector(getItemsSelector);
  const { documents, handleSetDocuments, handleRemoveDocuments, handleUpdateDocuments } = useDocuments();
  // console.log(documents);

  useEffect(() => {
    if (data) {
      if (data.documents) handleSetDocuments(data.documents);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data])
  return (
    <Card>
      <CardContent sx={{ position: 'relative' }}>
        <Grid container spacing={2}>
          {/* LEFT */}
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              {
                documents.filter(document => document.filetype.includes('image') && document.action !== EAction.delete).length
                  ? documents.filter(document => document.filetype.includes('image') && document.action !== EAction.delete).map((document, index) => (
                    <Grid key={document.url} item xs={index === 0 ? 12 : 3}>
                      <img src={document.url} width="100%" alt="" />
                    </Grid>
                  ))
                  : <Grid item xs={12}>
                    <ImagePreview />
                  </Grid>
              }
            </Grid>
          </Grid>
          {/* CENTER */}
          <Grid item xs={12} md={6}>
            <div style={{display: 'flex', alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Summary:
              </Typography>
              <Typography sx={{ fontSize: '15px' }}><b>{data?.summary}</b></Typography>
            </div>
            <div style={{display: 'flex', alignItems:'top', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Location: <InfoTooltip title="An open text field defining the location where the issue occured" sx={{ ml: 0 }} />
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{data?.location}</Typography>
            </div>
            <div style={{display: 'flex', alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Issue Category:
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{issueCategories.filter((item: any) => item.id === data?.category)[0]?.category}</Typography>
            </div>
            <div style={{display: 'flex', alignItems:'top', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Issue Type:
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{issueCategories.filter((item: any) => item.id === data?.category)[0]?.type}</Typography>
            </div>
            <div style={{display: 'flex', alignItems:'top', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Recommendation:
              </Typography>
              <Typography sx={{ fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: data?.recommendation }} />
            </div>
            <div style={{display: 'flex', alignItems:'top', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
              Description:
              </Typography>
              <Typography sx={{ fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
          </Grid>
          {/* RIGHT */}
          <Grid item xs={12} md={3}>
            <div style={{display: 'flex', alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Status: <InfoTooltip title="The current state of the Issue.`Open` is the only status available when creating a new Issue" sx={{ ml: 0 }} />
              </Typography>
              <Typography sx={{ fontSize: '15px', textTransform: 'capitalize' }}>{data?.status}</Typography>
            </div>
            <div style={{display: 'flex', alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Severity: <InfoTooltip title="The level of severity for this Issue" sx={{ ml: 0 }} />
              </Typography>
              <Typography sx={{ fontSize: '15px', textTransform: 'capitalize' }}>{data?.severity}</Typography>
            </div>
            <div style={{display: 'flex', alignItems:'top', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Due date:
              </Typography>
              <Typography sx={{ fontSize: '15px' }} style={{ display: 'block' }}>
                <div>{moment(data?.due_date).format('DD.MM.YYYY')}</div>
                {
                  data?.due_date
                    ? getDaysDiff(data?.due_date) >= 0
                      ? `${getDaysDiff(data?.due_date) + 1} day${getDaysDiff(data?.due_date) === 0 ? '' : '(s)'} left`
                      : 'Overdue'
                    : <></>
                }
              </Typography>
            </div>
            <div style={{alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Assigned
              </Typography>
              <Typography sx={{ fontSize: '15px' }} style={{ display: 'block', padding: '0 0 0 20px' }}>
                <div>to:&nbsp;&nbsp;{data?.assigned_to?.fullname}</div>
                {
                  data?.assigned_at &&
                  <div>on:&nbsp;&nbsp;{moment(data?.assigned_at).format('DD.MM.YYYY')}&nbsp;{moment(data?.assigned_at).format('HH:mm')}</div>
                }
                </Typography>
            </div>
            <div style={{alignItems:'center', margin: '0 0 15px 0'}}>
              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: '17px', fontWeight: '500', margin: '0 25px 0 0' }}>
                Identified
              </Typography>
              <Typography sx={{ fontSize: '15px' }} style={{ display: 'block', padding: '0 0 0 20px' }}>
                <div>to:&nbsp;&nbsp;{data?.identified_by?.fullname}</div>
                {
                  data?.assigned_at &&
                  <div>on:&nbsp;&nbsp;{moment(data?.identified_at).format('DD.MM.YYYY')}&nbsp;{moment(data?.identified_at).format('HH:mm')}</div>
                }
                </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={9}>
            <Documents
              edit={false}
              documents={documents}
              onUpload={handleUpdateDocuments}
              onRemove={handleRemoveDocuments}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Comments
              data={data}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default memo(IssuesViewCard, isEqualPropsMemo);
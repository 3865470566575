import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const ManpowerDialog = ({open, setOpenRemoveDialog, rowId, handleDeleteRow}: any) => {
  const handleClose = () => {
    setOpenRemoveDialog(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure you want to remove the line?'}
        </DialogTitle>
        <DialogContent style={{ padding: '20px' }}>
          <DialogContentText id='alert-dialog-description'>
            The completed line data will be removed. This action cannot be
            undone in the future.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteRow(rowId);
              handleClose();
            }}
            variant='contained'
            color='error'
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManpowerDialog

import { createSelector } from 'reselect';
import { getSortedSelectOptions } from '../../utils/helpers';

import { AppState } from '../rootReducer';

const getPOs = (state: AppState) => state.pos.items;
const getItemView = (state: AppState) => state.pos.itemView;

export const getPOsSelector = createSelector(getPOs, items => items);
export const getPOsOptionsSelector = createSelector(getPOs, items => getSortedSelectOptions(items.map((item: any) => ({
    label: item.PO,
    value: item.id,
  }))));
export const getPoViewSelector = createSelector(getItemView, itemView => itemView);
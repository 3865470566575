import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { getAuthDataSelector } from '../../../../store/auth/selectors';
import { getJobDetailsSelector, getJobsOptionsSelector } from '../../../../store/jobs/selectors';
import { fetchSubJobRequest } from '../../../../store/jobs/actions';
import { InvoicesARRequestFixedPriceValidation, InvoicesARRequestTMValidation, InvoicesARRequestValidation } from '../../../../constants/validations';
import { getFilteredObjectsArray, isEqualPropsMemo } from '../../../../utils/helpers';
import { createARRequestInvoiceRequest } from '../../../../store/ARRequestInvoices/actions';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../ui/FormLabel/FormLabel';
import Stack from '@mui/material/Stack';

import AutocompleteSelect from '../../../ui/AutocompleteSelect/AutocompleteSelect';
import DatePickerRange from '../../../ui/DatePickerRange/DatePickerRange';

const CreateInvoicesARRequestForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authData = useSelector(getAuthDataSelector);
  const jobsOptions = getFilteredObjectsArray(useSelector(getJobsOptionsSelector));
  const jobDetails = useSelector(getJobDetailsSelector);

  const [errorAmountRequested, setErrorAmountRequested] = useState(false);
  const [errorMessageAmountRequested, setErrorMessageAmountRequested] = useState('');
  const [disabledSubjob, setDisabledSubjob] = useState(true);
  const [disabledCustomerPO, setDisabledCustomerPO] = useState(true);
  const [subJobsOptions, setSubJobsOptions] = useState([]);
  const [customerPOOptions, setCustomerPOOptions] = useState([]);
  const [jobType, setJobType] = useState('');
  const [amountRemaining, setAmountRemaining] = useState('');

  const isValidationSchema = () => {
    if (jobType === 'Fixed Price') {
      return InvoicesARRequestFixedPriceValidation
    } else if (jobType === 'T&M') {
      return InvoicesARRequestTMValidation
    } else {
      return InvoicesARRequestValidation
    }
  }

  const formik = useFormik({
    initialValues: {
      invoice_job: '',
      invoice_subjob: '',
      invoice_customerPO: '',
      invoice_amountRequested: '',
      invoice_dateRange: '',
      DateRangeStart: '',
      DateRangeFinish: ''
    },
    validationSchema: isValidationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (errorAmountRequested) { return }
        dispatch(createARRequestInvoiceRequest({
          navigate,
          data: {
            Job: values.invoice_job,
            SubJob: values.invoice_subjob,
            InvoiceNumber: '',
            AmountRequested: Number(values.invoice_amountRequested),
            CustomerPO: values.invoice_customerPO,
            DateRangeStart: values.DateRangeStart,
            DateRangeFinish: values.DateRangeFinish
          }
        }))
    }
  })

  const { values, errors, handleChange, handleSubmit, resetForm, setFieldValue } = formik;

  useEffect(() => {
    if (jobDetails) {
      if (jobDetails?.SubJobs) {
        setSubJobsOptions(jobDetails?.SubJobs?.map((item: any) => ({ label: item.SubJob, value: item.id })));
        setCustomerPOOptions(jobDetails?.CustPO?.map((item: any) => ({ label: item.Description, value: item.Description })));
        if (jobDetails?.JobType === 'T') { setJobType('T&M') }
        if (jobDetails?.JobType === 'F') { setJobType('Fixed Price') }
      }
    }

    // eslint-disable-next-line
  }, [jobDetails]);

  useEffect(() => {
    if (values.invoice_customerPO) {
      setAmountRemaining(jobDetails?.CustPO?.filter((item: any) => item.Description === values.invoice_customerPO)?.[0].RemainingAmt);
    }

  // eslint-disable-next-line
  }, [values.invoice_customerPO]);

  const handleChangeJob = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_job', '');
      setFieldValue('invoice_subjob', '');
      setFieldValue('invoice_customerPO', '');
      setDisabledSubjob(true);
      setDisabledCustomerPO(true);
    } else {
      setFieldValue('invoice_job', value);
      setFieldValue('invoice_subjob', '');
      setFieldValue('invoice_customerPO', '');
      setSubJobsOptions([]);
      setCustomerPOOptions([]);
      dispatch(fetchSubJobRequest({ job: value }));
      setTimeout(() => {
        setDisabledSubjob(false);
        setDisabledCustomerPO(false);
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeSubjob = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_subjob', '');
    } else {
      setFieldValue('invoice_subjob', value);      
    }
    // eslint-disable-next-line
  }, []);

  const handleChangePO = useCallback((value: string | undefined) => {
    if (value!.length === 0) {  
      setFieldValue('invoice_customerPO', '');
    } else {
      setFieldValue('invoice_customerPO', value);    
    }

    // eslint-disable-next-line
  }, []);

  const handleSaveForm = () => {
    if (jobType === 'Fixed Price') {
      if (!values.invoice_amountRequested.length || values.invoice_amountRequested > amountRemaining) {
        setErrorAmountRequested(true);
        if (!values.invoice_amountRequested.length) {
          setErrorMessageAmountRequested('Field is required');
        }
        if (values.invoice_amountRequested > amountRemaining) {
          setErrorMessageAmountRequested(`The amount requested must not exceed ${amountRemaining}`);
        }
      } else if (values.invoice_amountRequested.includes(',') || values.invoice_amountRequested.includes('.')) {
        setErrorAmountRequested(true);
        setErrorMessageAmountRequested('The amount requested must be integer');
      } else if (values.invoice_amountRequested.match(/[^0-9]/)) {
        setErrorAmountRequested(true);
        setErrorMessageAmountRequested('The amount requested must be number');
      } else {
        handleSubmit();
        setErrorAmountRequested(false);
      }
    }
    if (jobType === 'T&M') {
      if (values.DateRangeStart === null || values.DateRangeFinish === null) {
        setFieldValue('invoice_dateRange', 'true');
      } else {
        setFieldValue('invoice_dateRange', '');
        handleSubmit();
      }
    }
  }

  const handleChangeDatePickerRange = (value: any) => {
    setFieldValue('DateRangeStart', value.startDate);
    setFieldValue('DateRangeFinish', value.endDate);
  }

  return (
    <Grid container sx={{ margin: '0 0 20px 0' }}>
      <Grid item xs={4} md={4}>
        <div style={{width: '425px'}}>
          {/* Requestor */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Requestor" />
            <TextField
              value={authData?.user_fullname}
              disabled={true}
            />
          </FormControl>
          {/* Job */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Job" required />
            <AutocompleteSelect
              name="invoice_job"
              error={Boolean(errors.invoice_job)}
              helperText={errors.invoice_job}
              options={jobsOptions}
              value={values.invoice_job}
              onChange={handleChangeJob}
            />
          </FormControl>
          {/* Subjob */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Subjob" required />
            <AutocompleteSelect
              name="invoice_subjob"
              error={Boolean(errors.invoice_subjob)}
              helperText={errors.invoice_subjob}
              options={subJobsOptions}
              value={values.invoice_subjob}
              onChange={handleChangeSubjob}
              disabled={disabledSubjob}
            />
          </FormControl>
          {/* Customer PO */}
          <FormControl fullWidth margin="normal">
            <FormLabel title="Customer PO" required />
            <AutocompleteSelect
              name="invoice_customerPO"
              error={Boolean(errors.invoice_customerPO)}
              helperText={errors.invoice_customerPO}
              options={customerPOOptions}
              value={values.invoice_customerPO}
              onChange={handleChangePO}
              disabled={disabledCustomerPO}
            />
          </FormControl>
          {values.invoice_job && values.invoice_subjob && values.invoice_customerPO &&
            <>
              {/* Job Type */}
              <FormControl fullWidth margin="normal">
                <FormLabel title="Job Type" required />
                <TextField
                  value={jobType}
                  disabled={true}
                />
              </FormControl>
              {
                jobType === 'Fixed Price' &&
                <>
                  {/* Amount remaining */}
                  <FormControl fullWidth margin="normal">
                    <FormLabel title="Amount remaining" />
                    <TextField
                      value={amountRemaining}
                      disabled={true}
                    />
                  </FormControl>
                  {/* Amount requested */}
                  <FormControl fullWidth margin="normal">
                    <FormLabel title="Amount requested" required />
                    <TextField
                      error={Boolean(errorAmountRequested)}
                      helperText={(errorAmountRequested && errorMessageAmountRequested)}
                      name="invoice_amountRequested"
                      value={values.invoice_amountRequested}
                      onChange={handleChange}
                    />
                  </FormControl>
                </>
              }
              {
                jobType === 'T&M' &&
                <FormControl fullWidth margin="normal">
                  <FormLabel title="Date range" required />
                  <DatePickerRange
                    error={values.invoice_dateRange ? true : false}
                    helperText={values.invoice_dateRange ? 'Field is required' : ''}
                    onChange={handleChangeDatePickerRange} 
                  />
                </FormControl>
              }
            </>
          }
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Stack spacing={2} direction="row">
              <Button
                onClick={handleSaveForm}
                variant="contained"
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
            </Stack>
            <Button
              sx={{ margin: '0 10px 0 0' }}
              variant="outlined"
              onClick={() => navigate('/invoices/request')}
            >
              Cancel
            </Button>
          </Stack>
        </div>
      </Grid>
    </Grid>
  )
}

export default memo(CreateInvoicesARRequestForm, isEqualPropsMemo);
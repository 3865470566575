import React, { memo, useEffect, useState } from 'react'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import { styled } from '@mui/material/styles';

import { isEqualPropsMemo } from '../../../utils/helpers'
import FormLabel from '../../ui/FormLabel'

interface ITextEditorProps extends EditorProps {
  name: string
  label?: string
  value: string | undefined
  required?: boolean
  onSetFieldValue: (name: string, value: string) => void
  shouldClearValue?: boolean
  setShouldClearValue?: (value: boolean) => void
}

// const StyledEditorWrap = styled('div')(({ theme }) => ({
//   // borderRadius: 4,
//   // border: '1px solid rgba(0, 0, 0, 0.23)',
//   // padding: theme.spacing(1),
//   // '& .rdw-editor-toolbar': {
//   //   marginBottom: 0,
//   //   padding: 0,
//   //   border: 0,
//   //   borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
//   // },
// }));

const TextEditor = ({
  label,
  name,
  value,
  required,
  onSetFieldValue,
  shouldClearValue,
  setShouldClearValue,
  ...props
}: ITextEditorProps): JSX.Element => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value)
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
    return EditorState.createWithContent(contentState)
  }

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  )

  const resetState = () => {
    const editorState = EditorState.createEmpty()
    setEditorState(editorState)
  }

  useEffect(() => {
    if (shouldClearValue) {
      resetState()
      setShouldClearValue && setShouldClearValue(false)
    }
  }, [shouldClearValue, setShouldClearValue])

  // useEffect(() => {
  //   setEditorState(value ? prepareDraft(value) : EditorState.createEmpty())
  // }, [value])

  const handleEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    onSetFieldValue(name, forFormik)
    setEditorState(editorState)
  }

  return (
    <>
      {label && <FormLabel title={label} required={required} />}
      {/* <StyledEditorWrap> */}
      <Editor
        toolbar={{
          options: ['inline', 'list', 'link', 'blockType'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: { options: ['unordered', 'ordered'] },
          link: { showOpenOptionOnHover: false },
        }}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        editorClassName='demo-editor'
        {...props}
      />
      {/* </StyledEditorWrap> */}
    </>
  )
}

export default memo(TextEditor, isEqualPropsMemo)

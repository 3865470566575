import * as TYPES from './actionTypes';

import { IIssueCategoriesActions, IIssueCategoriesState } from './types';

const initialState: IIssueCategoriesState = {
  pending: false,
  items: [],
  error: null,
  sending: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IIssueCategoriesActions) => {
  switch (action.type) {
    case TYPES.FETCH_ISSUE_CATEGORIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case TYPES.FETCH_ISSUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        items: action.payload || [],
      };
    case TYPES.FETCH_ISSUE_CATEGORIES_FAILURE:
      return {
        ...state,
        pending: false,
        items: [],
        error: action.payload.error,
      };
    case TYPES.DELETE_ISSUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item: any) => item.id !== action.payload.id),
      };
    case TYPES.EDIT_ISSUE_CATEGORIES_REQUEST:
      return {
        ...state,
        sending: true,
      };
    case TYPES.EDIT_ISSUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        sending: false,
        items: state.items.map((item: any) => item.id === action.payload.id ? { ...item, ...action.payload } : item),
      };
    case TYPES.EDIT_ISSUE_CATEGORIES_FAILURE:
      return {
        ...state,
        sending: false,
      };
    case TYPES.CREATE_ISSUE_CATEGORIES_REQUEST:
      return {
        ...state,
        sending: true,
      };
    case TYPES.CREATE_ISSUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        sending: false,
        items: [...state.items, action.payload],
      };
    case TYPES.CREATE_ISSUE_CATEGORIES_FAILURE:
      return {
        ...state,
        sending: false,
      };
    default:
      return {
        ...state,
      };
  }
};
import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const setError = (
  payload: TYPES.ISetErrorPayload,
): TYPES.ISetError => ({
  type: ACTION_TYPES.SET_ERROR,
  payload,
});

export const resetError = (): TYPES.IResetError => ({
  type: ACTION_TYPES.RESET_ERROR,
});

export const signInRequest = (
  params: TYPES.ISignInRequestSagaParams,
): TYPES.ISignInRequest => ({
  type: ACTION_TYPES.SIGN_IN_REQUEST,
  params,
});

export const signInSuccess = (
  payload: TYPES.ISignInSuccessPayload,
): TYPES.ISignInSuccess => ({
  type: ACTION_TYPES.SIGN_IN_SUCCESS,
  payload,
});

export const signInFailed = (): TYPES.ISignInFailed => ({
  type: ACTION_TYPES.SIGN_IN_FAILED,
});

export const signOutRequest = (): TYPES.ISignOutRequest => ({
  type: ACTION_TYPES.SIGN_OUT_REQUEST,
});

export const signOutSuccess = (): TYPES.ISignOutSuccess => ({
  type: ACTION_TYPES.SIGN_OUT_SUCCESS,
});

export const signOutFailed = (): TYPES.ISignOutFailed => ({
  type: ACTION_TYPES.SIGN_OUT_FAILED,
});

export const authFromCache = (): TYPES.IAuthFromCacheRequest => ({
  type: ACTION_TYPES.AUTH_FROM_CACHE_REQUEST,
});

export const forgotPasswordRequest = (
  params: TYPES.IForgotPasswordRequestParams,
): TYPES.IForgotPasswordRequest => ({
  type: ACTION_TYPES.FORGOT_PASSWORD_REQUEST,
  params,
});

export const forgotPasswordSuccess = (): TYPES.IForgotPasswordSuccess => ({
  type: ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailed = (): TYPES.IForgotPasswordFailed => ({
  type: ACTION_TYPES.FORGOT_PASSWORD_FAILED,
});

export const  confirmforgotPasswordRequest = (
  params: any,
): TYPES.IConfirmForgotPasswordRequest => ({
  type: ACTION_TYPES.CONFITM__FORGOT_PASSWORD_REQUEST,
  params,
});

export const confirmForgotPasswordSuccess = (): TYPES.IConfirmForgotPasswordSuccess => ({
  type: ACTION_TYPES.CONFITM__FORGOT_PASSWORD_SUCCESS,
});

export const confirmForgotPasswordFailed = (): TYPES.IConfirmForgotPasswordFailed => ({
  type: ACTION_TYPES.CONFITM__FORGOT_PASSWORD_FAILED,
});

export const fetchAuthDataSuccess = (
  payload: TYPES.IFetchAuthDataPayload,
): TYPES.IFetchAuthDataSuccess => ({
  type: ACTION_TYPES.FETCH_AUTH_DATA,
  payload,
});
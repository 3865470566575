import React, { memo, useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MuiTablePagination from '@mui/material/TablePagination';

interface ITablePagination {
  count: number,
  hasMore?: boolean,
}

const TablePagination = ({ count, hasMore }: ITablePagination) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    newPage > 0 ? searchParams.set('page', newPage.toString()) : searchParams.delete('page');
    navigate(`?${searchParams}`);
  };

  useEffect(() => {
    setPage(searchParams.get('page') ? +searchParams.get('page')! : 0);
    setRowsPerPage(searchParams.get('size') ? +searchParams.get('size')! : 50);

    // eslint-disable-next-line
  }, [location]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    searchParams.set('size', newRowsPerPage.toString());
    navigate(`?${searchParams}`);
  };

  return (
    <MuiTablePagination
      component="div"
      page={page}
      count={!hasMore && page === 0 ? count : -1}
      rowsPerPage={rowsPerPage}
      nextIconButtonProps={{
        disabled: !hasMore,
      }}
      sx={{
        flexShrink: 0,
      }}
      rowsPerPageOptions={[10, 25, 50]}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default memo(TablePagination);
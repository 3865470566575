import { all, put, call, takeLatest } from 'redux-saga/effects';

import { apiSagaRequest } from '../../utils/saga';

import { FETCH_USERS_VIEW_REQUEST, UPDATE_USER__REQUEST } from './actionTypes';
import { IFetchUsersViewRequest, IUpdateUserRequest } from './types';
import { fetchUsersViewFailure, fetchUsersViewSuccess, updateUserSuccess } from './actions';
import { notifyOpen, setSending } from '../common/actions';

function* fetchUsersViewSaga({ params }: IFetchUsersViewRequest): any {
  try {
    const response = yield call(apiSagaRequest, { type: 'get', url: `/users/${params.id}` });
    yield put(fetchUsersViewSuccess(response));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(fetchUsersViewFailure({ error: e.message }));
    }
  }
}

function* updateUserSaga({ params }: IUpdateUserRequest): any {
  try {
    const { id, data } = params;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'put', url: `/users/${id}`, data });
    yield put(updateUserSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'User information successfully updated' }));
  } catch (e) {
    console.error(e);
    yield put(notifyOpen({ notifyType: 'error', notifyMessage: 'User information update failed' }));
  } finally {
    yield put(setSending(false));
  }
}

function* usersSaga() {
  yield all([takeLatest(FETCH_USERS_VIEW_REQUEST, fetchUsersViewSaga)]);
  yield all([takeLatest(UPDATE_USER__REQUEST, updateUserSaga)]);
}

export default usersSaga;
import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';


interface Thingamajig {
  title: string;
  path: string;
}

interface IBBreadcrumbsProps {
  propBreadCrumbsItems: Thingamajig[];
}


const BBreadcrumbs = ({ propBreadCrumbsItems }: IBBreadcrumbsProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ padding: '11px 0' }}>
      {
        propBreadCrumbsItems.map((item, index) => {
          return (
            item.path ?
              (
              <Link key={index} to={item.path} style={{ textDecoration: 'none' }}>
                <Typography color="black">
                  {item.title}
                </Typography>
              </Link>
              )
              : 
              (
                <Typography key={index} style={{ cursor: 'default' }}>{item.title}</Typography>
              )
          )
        })
      }
    </Breadcrumbs>
  )
}

export default BBreadcrumbs

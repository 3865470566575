import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getIsPendingSelector } from '../store/common/selectors';
import { getIssueViewSelector } from '../store/issues/selectors';
import { getCurrentJobSelector } from '../store/jobs/selectors';
import { viewIssuesRequest } from '../store/issues/actions';
import IssuesViewEdit from '../components/ActionItems/IssuesViewEdit/IssuesViewEdit';
import IssuesViewCard from '../components/ActionItems/IssuesViewCard/IssuesViewCard';
import Layout from '../components/layout';
import PageTitle from '../components/ui/PageTitle';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const IssuesView = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const job_id = useSelector(getCurrentJobSelector);
  const viewData = useSelector(getIssueViewSelector);

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (job_id && id) {
      dispatch(viewIssuesRequest({ ai_id: id!, job_id }));
    }

    // eslint-disable-next-line
  }, [id, job_id]);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Issues',
      path: '/issues',
    },
    {
      title: `${viewData.hid}`,
      path: '',
    }
  ]; 

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => {
    setEdit(false);
    if (job_id && id) {
      dispatch(viewIssuesRequest({ ai_id: id!, job_id }));
    }
  }

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <PageTitle title={`Issue ${viewData.hid}`} />
        {
          !edit &&
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        }
        {
          edit &&
          <div onClick={() => setEdit(false)}>
            <Button 
              sx={{ mb: 1 }} 
              variant="outlined"
              startIcon={<ArrowBackIcon />}
            >back</Button>
            </div>
        }
      </Stack>
      {
        edit
        ? <IssuesViewEdit 
            data={viewData} 
            setCancelEdit={handleCancelEdit}
          />
        : <IssuesViewCard data={viewData} />
      }
    </Layout>
  );
};

export default IssuesView;
import React, { memo } from 'react';

import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

import { isEqualPropsMemo } from '../../../utils/helpers';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface IMoreMenuItemProp {
  icon?: any,
  label: string,
  cb?: Function,
  divider?: boolean,
  menuItemProps?: MenuItemProps,
}

interface IMoreMenuProps {
  items: IMoreMenuItemProp[];
}

const MoreMenu = ({ items }: IMoreMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (cb?: Function | undefined) => {
    setAnchorEl(null);
    if (cb) {
      cb();
    }
  };

  return (
    <div>
      <IconButton
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
      >
        {
          items.map((item: IMoreMenuItemProp) => (
            <MenuItem disabled={!item.cb} key={item.label} divider={item.divider}
                      onClick={() => handleClose(item.cb)} {...item.menuItemProps} disableRipple>
              {item.icon && <item.icon />}
              {item.label}
            </MenuItem>
          ))
        }
      </StyledMenu>
    </div>
  );
};

export default memo(MoreMenu, isEqualPropsMemo);
import React, { memo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

interface SearchProps {
  type?: string;
  onChange?: (value: string) => void;
}

const Search = ({ type = 'text', onChange, ...rest }: SearchProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [value, setValue] = React.useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(debounce((value) => {
    if (onChange) {
      onChange(value);
    } else {
      value ? searchParams.set('search', value) : searchParams.delete('search');
      navigate(`?${searchParams}`);
    }
  }, 1000), [onChange]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handleDebounce(e.target.value);
  };

  return (
    <TextField
      type={type}
      placeholder="Search..."
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default memo(Search);
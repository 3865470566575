import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface InfoTooltipProps {
  title: string,
  placement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top',

  [key: string]: any,
}

const InfoTooltip = ({ title, placement, ...props }: InfoTooltipProps) => {
  return (
    <Tooltip title={title} placement={placement} {...props}>
      <HelpOutlineIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};

export default InfoTooltip;
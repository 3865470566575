import * as ACTION_TYPES from './actionTypes'
import * as TYPES from './types'

export const fetchManpowerRequest = (): TYPES.IFetchManpowerRequest => ({
  type: ACTION_TYPES.FETCH_MANPOWER_REQUEST,
})

export const fetchManpowerSuccess = (
  payload: TYPES.IFetchManpowerSuccessPayload
): TYPES.IFetchManpowerSuccess => ({
  type: ACTION_TYPES.FETCH_MANPOWER_SUCCESS,
  payload,
})

export const fetchManpowerDefaultsRequest =
  (): TYPES.IFetchManpowerDefaultsRequest => ({
    type: ACTION_TYPES.FETCH_MANPOWER_DEFAULTS_REQUEST,
  })

export const fetchManpowerDefaultsSuccess = (
  payload: TYPES.IFetchManpowerDefaultsSuccessPayload
): TYPES.IFetchManpowerDefaultsSuccess => ({
  type: ACTION_TYPES.FETCH_MANPOWER_DEFAULTS_SUCCESS,
  payload,
})

export const createManpowerRequest = (
  params: TYPES.ICreateManpowerRequestParams,
): TYPES.ICreateManpowerRequest => ({
  type: ACTION_TYPES.CREATE_MANPOWER_REQUEST,
  params,
});

export const viewManpowerRequest = (
  params: TYPES.IViewManpowerRequestParams,
): TYPES.IViewManpowerRequest => ({
  type: ACTION_TYPES.VIEW_MANPOWER_REQUEST,
  params,
});

export const viewManpowerRequestSuccess = (
  payload: any[],
): TYPES.IViewManpowerRequestSuccess => ({
  type: ACTION_TYPES.VIEW_MANPOWER_REQUEST_SUCCESS,
  payload,
});

export const updateManpowerDetailsRequest = (
  params: TYPES.IUpdateManpowerRequestDetailsParams,
): TYPES.IUpdateManpowerDetailsRequest => ({
  type: ACTION_TYPES.UPDATE_MANPOWER_DETAILS_REQUEST,
  params,
});

export const updateManpowerRequestDetailsSuccess = (
  payload: any[],
): TYPES.IUpdateManpowerRequestDetailsSuccess => ({
  type: ACTION_TYPES.UPDATE_MANPOWER_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const createNewManpowerDetails = (
  params: TYPES.IUpdateManpowerRequestDetailsParams,
): TYPES.ICreateManpowerDetails => ({
  type: ACTION_TYPES.CREATE_NEW_MANPOWER_DETAILS,
  params,
});

export const createNewManpowerDetailsSuccess = (
  payload: any[],
): TYPES.ICreateManpowerDetailsSuccess => ({
  type: ACTION_TYPES.CREATE_NEW_MANPOWER_DETAILS_SUCCESS,
  payload,
});


export const updateManpowerRequest = (
  params: TYPES.IUpdateManpowerRequestParams,
): TYPES.IUpdateManpowerRequest => ({
  type: ACTION_TYPES.UPDATE_MANPOWER_REQUEST,
  params,
});

export const updateManpowerRequestSuccess = (
  payload: any[],
): TYPES.IUpdateManpowerRequestSuccess => ({
  type: ACTION_TYPES.UPDATE_MANPOWER_REQUEST_SUCCESS,
  payload,
});

export const deleteManpowerRequestDetail = (
  params: TYPES.IDeleteManpowerRequestDetailParams,
): TYPES.IDeleteManpowerRequestDetail => ({
  type: ACTION_TYPES.DELETE_MANPOWER_REQUEST_DETAIL,
  params,
});

export const deleteManpowerRequestDetailSuccess = (
): TYPES.IDeleteManpowerRequestDetailSuccess => ({
  type: ACTION_TYPES.DELETE_MANPOWER_REQUEST_DETAIL_SUCCESS,
});


import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import { visuallyHidden } from '@mui/utils';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';

import { IOrder, ITableProps, IHeadCell, ITableHeadProps } from '../../../types/table';
import { capitalizeFirstLetter, isEqualPropsMemo } from '../../../utils/helpers';
import { getComparator, stableSort } from '../../../utils/table';
import { useTablePaginationState } from '../../../utils/hooks';
import TablePagination from '../TablePagination';
import Loading from '../Loading';
import { getIsActiveFiltersCollapsedSelector } from '../../../store/common/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveFiltersCollapsed } from '../../../store/common/actions';

function EnhancedTableHead({ order, orderBy, headCells, onRequestSort }: ITableHeadProps) {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: IHeadCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.style}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {capitalizeFirstLetter(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Table = ({ data, pending, hasMore, headCells, BodyCell }: ITableProps) => {
  const dispatch = useDispatch();
  dispatch(setActiveFiltersCollapsed(JSON.parse(localStorage.getItem('filtersCollapsed')!)));
  const [order, setOrder] = useState<IOrder>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, rowsPerPage] = useTablePaginationState();
  const stableSortedData = stableSort(data, getComparator(order, orderBy));

  const isActiveFiltersCollapsedSelector = useSelector(getIsActiveFiltersCollapsedSelector);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderData = data.length > rowsPerPage
    ? stableSortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : stableSortedData;

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer sx={{ maxHeight: isActiveFiltersCollapsedSelector ? 'calc(100vh - 330px)' : 'calc(100vh - 223px)' }}>
        <MuiTable stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {
              pending
                ? <TableRow
                  tabIndex={-1}
                >
                  <TableCell colSpan={headCells.length + 1} align="center">
                    <Loading style={{ minHeight: 100 }} />
                  </TableCell>
                </TableRow>
                : 
                <>
                {renderData.length
                  ? renderData.map((row, index) => (
                    <BodyCell key={index} index={index} data={row} />
                  )                )
                  : <TableRow>
                      <TableCell colSpan={headCells.length + 1} align="center">
                        <div style={{ height: '100px',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Data</div>
                      </TableCell>
                    </TableRow>
                }
                </>
                }
          </TableBody>
        </MuiTable>
      </TableContainer>
      {hasMore &&
        <TablePagination
          hasMore={hasMore}
          count={data.length}
        />
      }
    </Paper>
  );
};

export default memo(Table, isEqualPropsMemo);
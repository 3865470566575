import { all, put, call, takeLatest } from 'redux-saga/effects';

import { fetchARInvoiceSuccess, viewARInvoiceSuccess } from './actions';
import { FETCH_AR_INCOICE_REQUEST, FETCH_AR_INCOICE_VIEW_REQUEST } from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
import { setPending } from '../common/actions';

function* fetchARInvoiceSaga({ params }: any): any {  
  try {
    // const paramsFetch = `?job_id=${params.job}`
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/ars`, params: params.params });
    yield put(fetchARInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* viewARInvoiceSaga({ params }: any): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/ars/${params.ar_id}` });    
    yield put(viewARInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* posSaga() {
  yield all([
    takeLatest(FETCH_AR_INCOICE_REQUEST, fetchARInvoiceSaga),
    takeLatest(FETCH_AR_INCOICE_VIEW_REQUEST, viewARInvoiceSaga)
  ]);
}

export default posSaga;
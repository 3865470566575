import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import moment from 'moment';

import { getIsAuthCacheChecked } from './store/auth/selectors';
import { authFromCache } from './store/auth/actions';
import { routes } from './constants/routes';
import Loading from './components/ui/Loading/Loading';
import Notify from './components/ui/Notify';
import PrivateRoute from './components/PrivateRouter';
import AdminRouter from './components/AdminRouter';

import Home from './pages/Home';
import Jobs from './pages/Jobs';
import JobsView from './pages/JobsView';
import Users from './pages/Users';
import UsersView from './pages/UsersView';
import IssuesView from './pages/IssuesView';
import IssuesCreate from './pages/IssuesCreate';
import Trainings from './pages/Trainings';
import TrainingsCreate from './pages/TrainingsCreate';
import TrainingsView from './pages/TrainingsView';
import TrainingsViewLaunch from './pages/TrainingsViewLaunch'
import TrainingsProfile from './pages/TrainingsProfile';
import Manpower from './pages/Manpower';
import ManpowerCreate from './pages/ManpowerCreate';
import ManpowerView from './pages/ManpowerView';
import Invoices from './pages/Invoices';
import InvoicesView from './pages/InvoicesView';
import InvoicesARRequestView from './pages/InvoicesARRequestView';
import InvoicesARRequestCreate from './pages/InvoicesARRequestCreate';
import InvoicesARRequest from './pages/InvoicesARRequest';
import InvoicesAP from './pages/InvoicesAP';
import InvoicesAPView from './pages/InvoicesAPView';
import Session from './pages/Session';
import SessionCreate from './pages/SessionCreate';
import IssueCategories from './pages/IssueCategories';
import Issues from './pages/Issues';
import Pos from './pages/Pos';
import PosView from './pages/PosView'

import './styles/index.scss'

const SignIn = React.lazy(() => import('./pages/SignIn'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const Error = React.lazy(() => import('./pages/Error'));

moment.locale();
moment.defaultFormat = 'DD.MM.YYYY';

function App() {
  const dispatch = useDispatch();
  const isAuthCacheChecked = useSelector((getIsAuthCacheChecked));

  useLayoutEffect(() => {
    dispatch(authFromCache());
    // eslint-disable-next-line
  }, []);

  return (
    isAuthCacheChecked
      ? <Loading style={{ height: '100vh' }} />
      : <React.Suspense fallback={<Loading style={{ height: '100vh' }} />}>
        <Notify />
        <Routes>
          <Route path={routes.home} element={<PrivateRoute />}>
            <Route path={routes.home} element={<Home />} />
            
            <Route path={routes.jobs} element={<Jobs />} />
            <Route path={routes.jobsView} element={<JobsView />} />

            <Route path={routes.personnel} element={<Users />} />
            <Route path={routes.usersView} element={<UsersView />} />

            <Route path={routes.issues} element={<Issues />} />
            <Route path={routes.issuesView} element={<IssuesView />} />
            <Route path={routes.issuesCreate} element={<IssuesCreate />} />

            <Route path={routes.trainings} element={<Trainings />} />
            <Route path={routes.trainingsView} element={<TrainingsView />} />
            <Route path={routes.trainingsViewLaunch} element={<TrainingsViewLaunch />} />
            <Route path={routes.trainingsCreate} element={<TrainingsCreate />} />
            <Route path={routes.trainingsProfile} element={<TrainingsProfile />} />
            <Route path={routes.trainingSessions} element={<Session />} />
            <Route path={routes.trainingSessionsCreate} element={<SessionCreate />} />

            <Route path={routes.manpower} element={<Manpower />} />
            <Route path={routes.manpowerCreate} element={<ManpowerCreate />} />
            <Route path={routes.manpowerView} element={<ManpowerView />} />

            <Route path={routes.invoices} element={<Invoices />} />
            <Route path={routes.invoicesView} element={<InvoicesView />} />
            <Route path={routes.invoicesARRequest} element={<InvoicesARRequest />} />
            <Route path={routes.invoicesARRequestCreate} element={<InvoicesARRequestCreate />} />
            <Route path={routes.invoicesARRequestView} element={<InvoicesARRequestView />} />
            <Route path={routes.invoicesAP} element={<InvoicesAP />} />
            <Route path={routes.invoicesAPView} element={<InvoicesAPView />} />

            <Route path={routes.pos} element={<Pos />} />
            <Route path={routes.posView} element={<PosView />} />

            <Route path={routes.system} element={<AdminRouter />}>
            <Route path={routes.systemIssue} element={<IssueCategories />} />
            </Route>
          </Route>
          <Route path={routes.singIn} element={<SignIn />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </React.Suspense>
  );
}

export default App;

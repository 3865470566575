import { Auth } from 'aws-amplify';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import { COGNITO_ERRORS as errors } from '../../constants/errors';
import { routes } from '../../constants/routes';
import { AUTH_FROM_CACHE_REQUEST, CONFITM__FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST, SIGN_IN_REQUEST, SIGN_OUT_REQUEST } from './actionTypes';
import {
  confirmForgotPasswordFailed,
  confirmForgotPasswordSuccess,
  fetchAuthDataSuccess,
  forgotPasswordFailed,
  forgotPasswordSuccess,
  setError,
  signInFailed,
  signInSuccess,
  signOutFailed,
  signOutSuccess,
} from './actions';
import { IConfirmForgotPasswordRequest, IForgotPasswordRequest, ISignInRequest } from './types';
import { apiSagaRequest } from '../../utils/saga';
// import { setActiveFiltersCollapsed, setError } from '../common/actions';

function* singInSaga(params: ISignInRequest): any {
  try {
    const response = yield call([Auth, 'signIn'], params.params.data);
    const authData = yield call(apiSagaRequest, { type: 'get', url: '/auth' });
    yield put(fetchAuthDataSuccess(authData));
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(signInSuccess(response));
      if (params.params.navigate) {
        params.params.navigate(routes.changePassword);
      }
    } else if (response.challengeName === undefined) {
      yield put(signInSuccess(response));
    } else {
      console.error('signIn. Should never be here.');
    }
  } catch (e: any) {
    yield put(setError(e.message || 'User not found.'));
    yield put(signInFailed());
  }
}

function* checkAuthCache(): any {
  try {
    const response = yield call([Auth, 'currentAuthenticatedUser']);
    const authData = yield call(apiSagaRequest, { type: 'get', url: '/auth' });
    yield put(fetchAuthDataSuccess(authData));
    // if (!localStorage.getItem('filtersCollapsed')) {
    //   localStorage.setItem('filtersCollapsed', 'true');
    //   setActiveFiltersCollapsed(true)
    // } else {
    //   setActiveFiltersCollapsed(JSON.parse(localStorage.getItem('filtersCollapsed')!))
    // }
    yield put(signInSuccess(response));
  } catch (e) {
    yield put(signInFailed());
    if (e instanceof Error) {
      yield put(setError({ error: e.message }));
    }
  }
}

function* singOutSaga(): any {
  try {
    yield call([Auth, 'signOut']);
    yield put(signOutSuccess());
  } catch (e) {
    console.error(e);
    yield put(signOutFailed());
  }
}

function* forgotPasswordSaga(params: IForgotPasswordRequest): any {
  try {
    yield call([Auth, 'forgotPassword'], params.params.username);
    yield put(forgotPasswordSuccess());    
  } catch (e: any) {
    console.error(e);
    yield put(setError(e && e.code === 'UserNotFoundException' ? errors.noUser : e.message || errors.noUser));
    yield put(forgotPasswordFailed());
  }
}

function* confirmForgotPasswordSaga(params: IConfirmForgotPasswordRequest): any {    
  try {
    yield call([Auth, Auth.forgotPasswordSubmit], params.params.username, params.params.code, params.params.password)
    yield put(confirmForgotPasswordSuccess());
  } catch (e: any) {
    console.error(e);
    yield put(setError(e.message));
    yield put(confirmForgotPasswordFailed());
  }
}

function* authSaga() {
  yield all([
    takeLatest(SIGN_IN_REQUEST, singInSaga),
    takeLatest(SIGN_OUT_REQUEST, singOutSaga),
    takeLatest(AUTH_FROM_CACHE_REQUEST, checkAuthCache),
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeLatest(CONFITM__FORGOT_PASSWORD_REQUEST, confirmForgotPasswordSaga),
  ]);
}

export default authSaga;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// import Link from '@mui/material/Link';

// import { getUserRoleSelector } from '../../../store/auth/selectors';

import moment from 'moment';

const ManpowerTable = ({ manpowers, jobParams }: any) => {
  const navigate = useNavigate();
  const MaterialTable = require('material-table').default;
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const [manpowerList, setManpowerList] = useState([]);
  // const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    setManpowerList(manpowers?.sort((a: any,b: any) => (a.ReqID > b.ReqID) ? -1 : ((b.ReqID > a.ReqID) ? 1 : 0)).map((row: any) => ({
      Customer: row.Customer,
      Issued: row.Issued,
      Job: row.Job,
      ReqID: row.ReqID,
      Requested: row.Requested,
      StartTime: moment(row.StartTime).format('HH:mm'),
      StartDate: moment(row.StartTime).format('DD.MM.YYYY'),
      Status: row.Status,
      id: row.id
    })));
    if (jobParams !== null) {
      const filteredManpowers = manpowers
      ?.filter((item: any) => item?.Job === jobParams)
      ?.sort((a: any,b: any) => (a.ReqID > b.ReqID) ? -1 : ((b.ReqID > a.ReqID) ? 1 : 0))
      ?.map((row: any) => ({
        Customer: row.Customer,
        Issued: row.Issued,
        Job: row.Job,
        ReqID: row.ReqID,
        Requested: row.Requested,
        StartTime: moment(row.StartTime).format('HH:mm'),
        StartDate: moment(row.StartTime).format('DD.MM.YYYY'),
        Status: row.Status,
        id: row.id
      }))
      if (filteredManpowers.length === 0) {
        navigate(`/manpower/new?job=${jobParams}`);
      } else {
        setManpowerList(filteredManpowers);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobParams, manpowers]);

  return (
    <MaterialTable
      key={muiTableKey}
      title=''
      columns={[
        // ID #
        {
          title: 'ID #',
          field: 'ReqID',
          render: (rowData: any) => {
            if (rowData.ReqID === '' || rowData.ReqID === null) {
              return <span>&mdash;</span>
            } else
              return (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate(`/manpower/${rowData.ReqID}`)}
                >
                  {rowData.ReqID}
                </span>
              )
          },
        },
        // Job #
        {
          title: 'Job #',
          field: 'Job',
          render: (rowData: any) => {
            if (rowData.Job === '' || rowData.Job === null) {
              return <span>&mdash;</span>
            } else
              return (
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate(`/jobs/${rowData.Job}`)}
                >
                  {rowData.Job}
                </span>
              )
          },
        },
        // Customer
        {
          title: 'Customer',
          field: 'Customer',
          render: (rowData: any) => {
            if (rowData.Customer === '' || rowData.Customer === null) {
              return <span>&mdash;</span>
            } else return <span>{rowData.Customer}</span>
          },
        },
        // Status
        {
          title: 'Status',
          field: 'Status',
          lookup: {
            'progress': 'In progress',
            'completed': 'Completed',
            'canceled': 'Canceled',
          },
          align: 'left',
          render: (rowData: any) => (
            <>
              {rowData.Status === '' && <span>&mdash;</span>}
              {rowData.Status === null && <span>&mdash;</span>}
              {rowData.Status === 'progress' && (
                <span style={{ whiteSpace: 'nowrap', color: '#006aff' }}>
                  In progress
                </span>
              )}
              {rowData.Status === 'completed' && (
                <span style={{ whiteSpace: 'nowrap', color: '#2e7d32' }}>
                  Completed
                </span>
              )}
              {rowData.Status === 'canceled' && (
                <span style={{ whiteSpace: 'nowrap', color: '#f4ba00' }}>
                  Canceled
                </span>
              )}
            </>
          ),
        },
        // StartDate
        {
          title: 'Start date',
          field: 'StartDate',
        },
        // StartTime
        {
          title: 'Start time',
          field: 'StartTime',
        },
        // Requested
        {
          title: 'Requested',
          field: 'Requested',
          render: (rowData: any) => {
            if (rowData.Requested === '' || rowData.Requested === null) {
              return <span>&mdash;</span>
            } else return <>{rowData.Requested}</>
          },
        },
        // Issued
        {
          title: 'Issued',
          field: 'Issued',
          render: (rowData: any) => {
            if (rowData.Issued === '' || rowData.Issued === null) {
              return <span>&mdash;</span>
            } else return <>{rowData.Issued}</>
          },
        },
        // Actions
        // {
        //   title: 'Actions',
        //   render: (rowData: any) => (
        //     <span
        //       style={{
        //         display: 'flex',
        //         flexWrap: 'wrap',
        //         justifyContent: 'center',
        //         whiteSpace: 'nowrap',
        //         width: '160px',
        //       }}
        //     >
        //       <Link
        //         component='button'
        //         variant='body2'
        //         // onClick={() => {}}
        //       >
        //         Cancel
        //       </Link>
        //       <Link
        //         component='button'
        //         variant='body2'
        //         style={{ margin: '0 10px' }}
        //         onClick={() => navigate(`/manpower/${rowData.ReqID}`)}
        //       >
        //         Modify/View
        //       </Link>
        //       {(userRole === 'admin' || userRole === 'user_dispatcher') && (
        //         <Link
        //           component='button'
        //           variant='body2'
        //           // onClick={() => {}}
        //         >
        //           Issue
        //         </Link>
        //       )}
        //     </span>
        //   ),
        // },
      ]}
      data={manpowerList}
      options={{
        headerStyle: {
          whiteSpace: 'nowrap',
        },
        search: false,
        filtering: true,
        sorting: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
      }}
      actions={[
        {
          icon: () => (
            <div
              className='btn-clear-filter-table'
              style={{ borderRadius: '0px' }}
            >
              Clear all filters
            </div>
          ),
          tooltip: 'Сlear all filters',
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1)
          },
        },
      ]}
    />
  )
}

export default ManpowerTable

import * as ACTION_TYPES from './actionTypes';
import { AuthActions, IAuthState } from './types';

const initialState: IAuthState = {
  pending: false,
  username: null,
  cognitoUser: {},
  isAuth: false,
  isAuthCacheChecked: true,
  isForgotPassword: false,
  isConfirmForgotPassword: false,
  error: null,
  data: {
    user_id: '',
    user_role: '',
    user_fullname: '',
  },
};

export default function authReducer(state = initialState, action: AuthActions) {
  switch (action.type) {
    case ACTION_TYPES.SIGN_IN_REQUEST:
      return {
        ...state,
        pending: true,
        isAuth: false,
      };
    case ACTION_TYPES.SIGN_IN_SUCCESS:
      return {
        ...state,
        cognitoUser: action.payload,
        isAuth: true,
        pending: false,
        isAuthCacheChecked: false,
      };
    case ACTION_TYPES.SIGN_IN_FAILED:
      return {
        ...state,
        cognitoUser: {},
        isAuth: false,
        pending: false,
        isAuthCacheChecked: false,
      };
    case ACTION_TYPES.SIGN_OUT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ACTION_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...initialState,
        isAuthCacheChecked: false,
      };
    case ACTION_TYPES.SIGN_OUT_FAILED:
      return {
        ...state,
        pending: false,
      };
    case ACTION_TYPES.AUTH_FROM_CACHE_REQUEST:
      return {
        ...state,
        pending: true,
        isAuthCacheChecked: true,
      };
    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isForgotPassword: true,
      };
    case ACTION_TYPES.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isForgotPassword: false,
      };
      case ACTION_TYPES.CONFITM__FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          isConfirmForgotPassword: true,
        };
      case ACTION_TYPES.CONFITM__FORGOT_PASSWORD_FAILED:
        return {
          ...state,
          isConfirmForgotPassword: false,
        };
    case ACTION_TYPES.FETCH_AUTH_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
      case ACTION_TYPES.RESET_ERROR:
        return {
          ...state,
          error: null,
        };
    default:
      return state;
  }
};
import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { notifyClose } from '../../../store/common/actions';
import {
  getIsNotifyOpenSelector,
  getNotifyMessageSelector,
  getNotifyTypeSelector,
} from '../../../store/common/selectors';

const Notify: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const open = useSelector(getIsNotifyOpenSelector);
  const type = useSelector(getNotifyTypeSelector);
  const message = useSelector(getNotifyMessageSelector);
  const vertical = 'top';
  const horizontal = 'right';

  const handleClose = () => dispatch(notifyClose());

  return (
    <Snackbar
      open={open}
      message={message}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ alignItems: 'center' }}>{message}</Alert>
    </Snackbar>
  );
};

export default Notify;
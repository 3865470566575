import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import { DateRange } from 'react-date-range';
import TextField from '@mui/material/TextField';

const DatePickerRange = ({ onChange, error, helperText, startDate, endDate, disabled }: any): JSX.Element => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      disabled: disabled,
    },
  ]);

  useEffect(() => {
    if (range) {
      onChange(range[0])
    }
    // eslint-disable-next-line
  }, [range]);

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };
  const hideOnClickOutside = (e: MouseEvent) => {
    // @ts-ignore
    if (refOne.current && !refOne?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);

  return (
    <div
      className='calendarWrap'
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <TextField
        error={Boolean(error)}
        helperText={helperText}
        value={startDate ? startDate : `${moment(range[0].startDate).format('MM.DD.YYYY')}`}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => setOpen((open) => !open)}
      />
      <p>&mdash;</p>
      <TextField
        error={Boolean(error)}
        helperText={helperText}
        value={endDate ? endDate : `${moment(range[0].endDate).format('MM.DD.YYYY')}`}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => setOpen((open) => !open)}
      />
      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={(item: any) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={2}
            direction='horizontal'
            className='calendarElement'
          />
        )}
      </div>
    </div>
  )
}

export default DatePickerRange
import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchPosRequest = (
  params: TYPES.IFetchPosRequestParams,
): TYPES.IFetchPosRequest => ({
  type: ACTION_TYPES.FETCH_POS_REQUEST,
  params,
});

export const fetchPosSuccess = (
  payload: TYPES.IFetchPosSuccessPayload,
): TYPES.IFetchPosSuccess => ({
  type: ACTION_TYPES.FETCH_POS_SUCCESS,
  payload,
});

export const viewPosRequest = (
  params: TYPES.IViewPosRequestParams,
): TYPES.IViewPosRequest => ({
  type: ACTION_TYPES.FETCH_POS_VIEW_REQUEST,
  params,
});

export const viewPosSuccess = (
  payload: TYPES.IViewPosSuccessPayload,
): TYPES.IViewPosSuccess => ({
  type: ACTION_TYPES.FETCH_POS_VIEW_SUCCESS,
  payload,
});

// export const fetchCurrentPosRequest = (
//   params: TYPES.IFetchPosRequestParams,
// ): TYPES.IFetchCurrentPosRequest => ({
//   type: ACTION_TYPES.FETCH_CURRENT_POS_REQUEST,
//   params,
// });

// export const fetchCurrentPosSuccess = (
//   payload: TYPES.IFetchPosSuccessPayload,
// ): TYPES.IFetchCurrentPosSuccess => ({
//   type: ACTION_TYPES.FETCH_CURRENT_POS_SUCCESS,
//   payload,
// });

// export const setCurrentPos = (
//   payload: TYPES.ISetCurrentPosPayload,
// ): TYPES.ISetCurrentPos => ({
//   type: ACTION_TYPES.SET_CURRENT_POS,
//   payload,
// });
import React from 'react';
import { useSelector } from 'react-redux';

import { getJobsSelector } from '../store/jobs/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { fetchJobsRequest } from '../store/jobs/actions';
import { useGetPageItemsEffect } from '../utils/hooks';

import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import JobsTable from '../components/Jobs/JobsTable/JobsTable';
import Loading from '../components/ui/Loading/Loading';
import PageTitle from '../components/ui/PageTitle/PageTitle';


const Jobs = () => {
  const jobs = useSelector(getJobsSelector);
  const pending = useSelector(getIsPendingSelector);
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: 'Jobs',
      path: '',
    },
  ];

  useGetPageItemsEffect(fetchJobsRequest);

  if (jobs) {
    jobs.forEach(function (element: any) {
      if (element.JobType === 'T') {
        element.JobType = 'T&M';
      }    
      if (element.JobType === 'F') {
        element.JobType = 'Fixed Price';
      }  
      if (element.JobType === 'N') {
        element.JobType = 'Fixed Price';
      }  
    })
  }

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Jobs" />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <JobsTable dataValues={jobs} />
      }
    </Layout>
  );
};

export default Jobs;
import * as ACTION_TYPES from './actionTypes';
import { merge } from 'lodash';

import { IManpowerActions, IManpowerState } from './types';

const initialState: IManpowerState = {
  items: [],
  defaultsMPR: {
    certifications: [
      {},
    ],
    crafts: [
      {},
    ],
    classes: [
      {},
    ],
    employees: [
      {},
    ],
    specialities: [
      {},
    ],
  },
  view: {},
  details: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IManpowerActions) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MANPOWER_SUCCESS:
      return {
        ...state,
        items: action.payload,
      }
    case ACTION_TYPES.FETCH_MANPOWER_DEFAULTS_SUCCESS:
      return {
        ...state,
        defaultsMPR: action.payload,
      }
    case ACTION_TYPES.VIEW_MANPOWER_REQUEST_SUCCESS:
      return {
        ...state,
        view: action.payload,
      }
    case ACTION_TYPES.UPDATE_MANPOWER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        view: merge(state.view, action.payload),
      }
    case ACTION_TYPES.CREATE_NEW_MANPOWER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
      }
    case ACTION_TYPES.UPDATE_MANPOWER_REQUEST_SUCCESS:
      return {
        ...state,
        view: merge(state.view, action.payload),
      };
    case ACTION_TYPES.DELETE_MANPOWER_REQUEST_DETAIL_SUCCESS:
      return {
        ...state
      };
    default:
      return state
  }
}

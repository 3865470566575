import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { asideLinks, IAsideLinkItem, ILinks } from '../../../../constants/routes';
import { capitalizeFirstLetter } from '../../../../utils/helpers';

interface IAsideCurrentPageListProps {
  isMenuSm: boolean;
}

const AsideCurrentPageList = ({ isMenuSm }: IAsideCurrentPageListProps): JSX.Element => {
  const location = useLocation();
  const type = location.pathname.split('/')[1];
  const list = type && asideLinks.hasOwnProperty(type) ? asideLinks[type] : false;

  return (
    list ?
      <>
        <List>
          {
            isMenuSm &&
            <ListItem>
              {list.icon &&
                <ListItemIcon>
                  <list.icon />
                </ListItemIcon>
              }
              <ListItemText primary={capitalizeFirstLetter(list.label)} />
            </ListItem>
          }
          <List component="div" disablePadding>
            {
              asideLinks[type].submenu.map((link: ILinks) => (
                <ListItemButton sx={{ pl: isMenuSm ? 4 : 2 }} key={link.label} component={NavLink} to={link.path} end>
                  {link.icon && (
                    <ListItemIcon>
                      <link.icon />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={link.label} />
                </ListItemButton>
              ))
            }
          </List>
        </List>
        <Divider />
        {
          asideLinks[type].subcategory &&
          asideLinks[type].subcategory!.map((category: IAsideLinkItem) => (
            <React.Fragment key={category.label}>
              <List>
                {
                  isMenuSm &&
                  <ListItem>
                    {category.icon &&
                      <ListItemIcon>
                        <category.icon />
                      </ListItemIcon>
                    }
                    <ListItemText primary={capitalizeFirstLetter(category.label)} />
                  </ListItem>
                }
                <List component="div" disablePadding>
                  {
                    category.submenu.map((link: ILinks) => (
                      <ListItemButton sx={{ pl: isMenuSm ? 4 : 2 }} key={link.label} component={NavLink} to={link.path}
                                      end>
                        {link.icon && (
                          <ListItemIcon>
                            <link.icon />
                          </ListItemIcon>
                        )}
                        <ListItemText primary={link.label} />
                      </ListItemButton>
                    ))
                  }
                </List>
              </List>
              <Divider />
            </React.Fragment>
          ))
        }
      </>
      : <></>
  );
};

export default AsideCurrentPageList;
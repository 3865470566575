import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import Loading from '../components/ui/Loading/Loading';
import InvoicesARRequestTable from '../components/Invoices/InvoicesARRequestTable';

import { getUserRoleSelector } from '../store/auth/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { getARRequestInvoicesSelector } from '../store/ARRequestInvoices/selectors';
import { fetchARRequestInvoiceRequest } from '../store/ARRequestInvoices/actions';
import { useGetPageItemsEffect } from '../utils/hooks';
import { isEqualPropsMemo } from '../utils/helpers';
import { getUsersSelector } from '../store/users/selectors';
import { fetchUsersRequest } from '../store/users/actions';

const InvoicesARRequest: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const userRole = useSelector(getUserRoleSelector);
  const pending = useSelector(getIsPendingSelector);
  const items = useSelector(getARRequestInvoicesSelector);
  const users = useSelector(getUsersSelector);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: `AR Requests`,
      path: '',
    },
  ];
  const pageTitle = `AR Requests`;

  useGetPageItemsEffect(fetchARRequestInvoiceRequest, true);
  useGetPageItemsEffect(fetchUsersRequest);

  if (items) {
    items.forEach((element: any) => {
      if (element.JobType === 'T') {
        element.JobType = 'T&M';
      }    
      if (element.JobType === 'F') {
        element.JobType = 'Fixed Price';
      }  
      if (element.JobType === 'N') {
        element.JobType = 'Fixed Price';
      }  
    })
  }
  
  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <PageTitle title={pageTitle} />
        {(userRole === 'admin' || userRole === 'user_pm') &&
          <Button
            variant="contained" 
            onClick={() => navigate(`/invoices/request/new`)}
          >
            New AR Request
          </Button>
        }
      </div>
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <InvoicesARRequestTable items={items} users={users}/>
      }
    </Layout>
  )
};

export default memo(InvoicesARRequest, isEqualPropsMemo);

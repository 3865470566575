import * as ACTION_TYPES from './actionTypes';
import * as TYPES from './types';

export const fetchIssuesRequest = (
  params: TYPES.IFetchIssuesRequestParams,
): TYPES.IFetchIssuesRequest => ({
  type: ACTION_TYPES.FETCH_ISSUES_REQUEST,
  params,
});

export const fetchIssuesSuccess = (
  payload: TYPES.IFetchIssuesSuccessPayload,
): TYPES.IFetchIssuesSuccess => ({
  type: ACTION_TYPES.FETCH_ISSUES_SUCCESS,
  payload,
});

export const createIssuesRequest = (
  params: TYPES.ICreateIssuesRequestParams,
): TYPES.ICreateIssuesRequest => ({
  type: ACTION_TYPES.CREATE_ISSUES_REQUEST,
  params,
});

export const viewIssuesRequest = (
  params: TYPES.IViewIssuesRequestParams,
): TYPES.IViewIssuesRequest => ({
  type: ACTION_TYPES.VIEW_ISSUES_REQUEST,
  params,
});

export const viewIssuesSuccess = (
  payload: TYPES.IViewIssuesSuccessPayload,
): TYPES.IViewIssueSuccess => ({
  type: ACTION_TYPES.VIEW_ISSUES_SUCCESS,
  payload,
});

export const updateIssuesRequest = (
  params: TYPES.IUpdateIssuesRequestParams,
): TYPES.IUpdateIssuesRequest => ({
  type: ACTION_TYPES.UPDATE_ISSUES_REQUEST,
  params,
});

export const updateIssuesSuccess = (
  payload: TYPES.IUpdateIssuesSuccessPayload,
): TYPES.IUpdateIssueSuccess => ({
  type: ACTION_TYPES.UPDATE_ISSUES_SUCCESS,
  payload,
});
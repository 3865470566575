import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom'

import { isEqualPropsMemo } from '../../../utils/helpers';

const JobsTable = ({ dataValues } :any ) => {
  const MaterialTable = require("material-table").default;
  const navigate = useNavigate();
  const [muiTableKey, setMuiTableKey] = React.useState(0);

  const calculationFormula = (data: any) => {
    if (data.JobType === 'Fixed Price') {
      const formula = ((data.POAmt - data.ExpectedBilling)/data.POAmt)*100
      if (formula === -Infinity) {
        return '100'
      }
      if (isNaN(formula)) {
        return '0'
      }
      return formula.toFixed()
    }
    if (data.JobType === 'T&M') {
      const formula = ((data.POAmt - data.TotalCost)/data.POAmt)*100
      if (formula === -Infinity) {
        return '100'
      }
      if (isNaN(formula)) {
        return '0'
      }
      return formula.toFixed()
    }
  }

  const openJobDetail = (id: string) => {
    localStorage.setItem('currentJob', id);
    navigate(`/jobs/${id}`)
  }

  return (
    <MaterialTable 
      key={muiTableKey}
      title=""
      columns={[
        // Job #
        { 
          title: 'Job #', 
          field: 'Job',
          align: 'left',
          customSort: (a: any, b: any) => a.Job.localeCompare(b.Job, 'en', { numeric: true }),
          render: (rowData: any) =>
            <span 
              style={{ textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => openJobDetail(rowData.Job)}
            >
              {rowData.Job}
            </span> 
        },
        // Job Type
        { 
          title: 'Job Type', 
          field: 'JobType',
          align: 'left',
          render: (rowData: any) =>
            <div>
              {rowData.JobType}
            </div>
        },
        // Status
        { 
          title: 'Status', 
          field: 'Status', 
          align: 'left',
          lookup: { 'Open': 'Open', 'Closed': 'Closed' },
          render: (rowData: any) =>
            <div>
              {rowData.Status === 'Closed' && <div style={{ color: '#d32f2f' }}>Closed</div>}
              {rowData.Status === 'Open' && <div style={{ color: '#2e7d32' }}>Open</div>}
              {rowData.Status === 'Reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
              {rowData.Status === 'Progress' && <div style={{ color: '#19857b' }}>Progress</div>}
              {rowData.Status === 'Resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
              {rowData.Status === 'Duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
            </div>
        },
        // Customer
        { 
          title: 'Customer', 
          field: 'Customer',
          align: 'left',
          customSort: (a: any, b: any) => {
            return a?.Customer?.localeCompare(b?.Customer)
          },
          render: (rowData: any) =>
          <>
            {
              rowData.Customer
              ? <div>{rowData?.Customer}</div>
              : <>&mdash;</>
            }
          </>
        },
        // Total Cost
        { 
          title: 'Total Cost', 
          field: 'TotalCost',
          align: 'left',
          type: 'numeric',
          render: (rowData: any) =>
            <div>
              {
                (rowData.TotalCost).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </div>
        },
        // Total Billing
        { 
          title: 'Total Billing', 
          field: 'TotalBilling',
          align: 'left',
          type: 'numeric',
          render: (rowData: any) =>
            <div>
              {
                (rowData.TotalBilling).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </div>
        },
        // Expected Billing
        { 
          title: 'Expected Billing', 
          field: 'ExpectedBilling',
          align: 'left',
          type: 'numeric',
          render: (rowData: any) =>
            <div>
              {
                (rowData.ExpectedBilling).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </div>
        },
        // PO Amount
        { 
          title: 'PO Amount', 
          field: 'POAmt',
          align: 'left',
          type: 'numeric',
          render: (rowData: any) =>
            <div>
              {
                (rowData.POAmt).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
            </div>
        },
        // % Remain
        {
          title: '% Remain',
          align: 'left',
          field: 'Remain',
          type: 'numeric',
          render: (rowData: any) =>
            <div>
              {rowData.Remain}%
            </div>
        },
      ]}
      // data={dataValues?.sort((a: any, b: any) => (a.Status === 'Open')? -1 : 1)}    
      data={dataValues.map((v: any) => ({...v, Remain: Number(calculationFormula(v))}))}    
      options={{
        headerStyle: {
          whiteSpace: 'nowrap'
        },
        rowStyle: (rowData: any) => {
          const a = Number(calculationFormula(rowData))
          if (a >= 25) {
            return {backgroundColor: 'rgba(34,201,147,0.4)'}; //green
          }
          if (a >= 10 && a <= 25) {
            return {backgroundColor: 'rgba(254,165,48,0.4)'}; //yellow
          }
          if (a < 10) {
            return {backgroundColor: 'rgba(255,83,83,0.4'}; //red
          }          
          return {};
        },
        search: false,
        filtering: true,
        sorting: true,
        paging:true,
        pageSize:10,
        pageSizeOptions:[10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        maxBodyHeight: 'calc(100vh - 280px)',
      }}
      actions={[
        {
          icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
          tooltip: "Сlear all filters",
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1);
          }
        }
      ]}
    />
  )
}

export default memo(JobsTable, isEqualPropsMemo);
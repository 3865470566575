import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InvoicesARTable = ({dataValues, jobParams}: any) => {
  const navigate = useNavigate();
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const MaterialTable = require("material-table").default;
  const [data, setData] = useState([]);

  useEffect(() => {
    const sortedValues = dataValues?.sort((a: any, b: any) => (a.Status === 'Open')? -1 : 1);
    if (jobParams !== null) {
      setData(sortedValues.filter((item: any) => item?.Job === jobParams));
    } else {
      setData(sortedValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValues, jobParams])

  return (
  <MaterialTable
      title=""
      columns={[
        // Invoice #
        { 
          title: 'Invoice #', 
          field: 'Invoice',
          align: 'left',
          render: (rowData: any) =>
          <>
            {
              rowData.Invoice
              ? 
                <span 
                  style={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer' }} 
                  onClick={() => navigate(`/invoices/1`)}
                >
                  {rowData?.Invoice}
                </span> 
              : <>&mdash;</>
            }
          </>
        },   
        // Job #
        { 
          title: 'Job #', 
          field: 'Job',
          align: 'left',
          render: (rowData: any) =>
            <span 
              style={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer' }} 
              // onClick={() => navigate(`/invoices/1`)}
            >
              {rowData?.Job}
            </span> 
        }, 
        // Status
        { 
          title: 'Status', 
          field: 'Status', 
          align: 'left',
          lookup: { 'Open': 'Open', 'Closed': 'Closed' },
          render: (rowData: any) =>
            <div>
              {rowData?.Status === 'Closed' && <div style={{ color: '#d32f2f' }}>Closed</div>}
              {rowData?.Status === 'Open' && <div style={{ color: '#2e7d32' }}>Open</div>}
              {rowData?.Status === 'Reviewed' && <div style={{ color: '#00000014' }}>Reviewed</div>}
              {rowData?.Status === 'Progress' && <div style={{ color: '#19857b' }}>Progress</div>}
              {rowData?.Status === 'Resolved' && <div style={{ color: '#0288d1' }}>Resolved</div>}
              {rowData?.Status === 'Duplicate' && <div style={{ color: '#ed6c02' }}>Duplicate</div>}
            </div>
        },
        // Type
        { 
          title: 'Type', 
          field: 'Type',
          align: 'left',
          render: (rowData: any) =>
            <span style={{ whiteSpace: 'nowrap' }}>
              {rowData?.Type}
            </span> 
        },  
        // Invoice Amount  
        { 
          title: 'Invoice Amount', 
          field: 'InvoiceAmount',
          align: 'left',
          render: (rowData: any) =>
          <div>
            {
              (rowData?.InvoiceAmount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }
          </div>
        }, 
        // Paid Amount
        { 
          title: 'Paid Amount', 
          field: 'PaidAmount',
          align: 'left',
          render: (rowData: any) =>
          <>
            {
              rowData.PaidAmount
              ? 
              <div>
                {
                  (rowData?.PaidAmount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                }
              </div>
              : <>&mdash;</>
            }
          </>
        }, 
        // Remaining
        { 
          title: 'Remaining', 
          field: 'Remaining',
          align: 'left',
          render: (rowData: any) =>
          <>
            {
              rowData.Remaining
              ? 
                <div>
                  {
                    (rowData?.Remaining).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                  }
                </div>
              : <>&mdash;</>
            }
          </>
        }, 
        // Created
        { 
          title: 'Created', 
          field: 'CreatedDate',
          align: 'left',
          render: (rowData: any) =>
            <span >
              {rowData?.CreatedDate}
            </span> 
        }, 
        // Due Date
        { 
          title: 'Due Date', 
          field: 'DueDate',
          align: 'left',
          render: (rowData: any) =>
          <>
            {
              rowData.DueDate
              ? <div>{rowData?.DueDate}</div>
              : <>&mdash;</>
            }
          </>
        }, 
        // Request By
        { 
          title: 'Request By', 
          field: 'RequestedByName',
          align: 'left',
          render: (rowData: any) =>
          <>
            {
              rowData.RequestedByName
              ? <div>{rowData?.RequestedByName}</div>
              : <>&mdash;</>
            }
          </>
        },
      ]}
      data={data}     
      options={{
        headerStyle: {
          whiteSpace: 'nowrap'
        },
        search: false,
        filtering: true,
        sorting: true,
        paging:true,
        pageSize:10,
        pageSizeOptions:[10, 25, 50, 100],
        showTitle: false,
        toolbar: true,
        maxBodyHeight: 'calc(100vh - 280px)',
      }}
      actions={[
        {
          icon: () => <div className='btn-clear-filter-table' style={{ borderRadius: '0px' }}>Clear all filters</div>,
          tooltip: "Сlear all filters",
          isFreeAction: true,
          onClick: () => {
            setMuiTableKey(muiTableKey + 1);
          }
        }
      ]}
    />
  );
};

export default InvoicesARTable;
import * as ACTION_TYPES from './actionTypes';

import { IJobsActions, IJobsState } from './types';

const initialState: IJobsState = {
  items: [],
  has_more: false,
  currentJob: '',
  currentJobsPending: false,
  currentJobsItems: [],
  currentJobsHasMore: false,
  jobDetails: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IJobsActions) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_TYPES.FETCH_CURRENT_JOBS_REQUEST:
      return {
        ...state,
        currentJobsPending: true,
      };
    case ACTION_TYPES.FETCH_CURRENT_JOBS_SUCCESS:
      return {
        ...state,
        error: null,
        currentJobsPending: false,
        currentJob: localStorage.getItem('currentJob') || action.payload.items[0].Job,
        currentJobsItems: action.payload.items,
        currentJobsHasMore: action.payload.has_more,
      };
    case ACTION_TYPES.SET_CURRENT_JOB:
      return {
        ...state,
        currentJob: action.payload,
      };
      case ACTION_TYPES.FETCH_SUB_JOB_SUCCESS:
        return {
          ...state,
          jobDetails: action.payload,
        };
    default:
      return state;
  }
};
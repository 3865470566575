import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';

import {
  createIssueCategoriesRequest,
  editIssueCategoriesRequest,
} from '../../../store/system/issueCategories/actions';
import { getSendingSelector } from '../../../store/system/issueCategories/selectors';
import { getObjectsDifference, isEqualPropsMemo } from '../../../utils/helpers';
import { IIssueCategoriesItem } from '../../../types/system/issueCategories';
import CustomModal from '../../ui/CustomModal';
import TextEditor from '../../ui/TextEditor';

interface IssueCategoriesFormProps {
  data: IIssueCategoriesItem,
  open: boolean,
  onClose: () => void,
}

const IssueCategoriesForm = ({ data, open, onClose }: IssueCategoriesFormProps) => {
  const dispatch = useDispatch();
  const isSending = useSelector(getSendingSelector);
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: (values: IIssueCategoriesItem, { resetForm }) => {
      if (data.id) {
        const formData = getObjectsDifference(values, data);
        if (Object.keys(data).length) {
          dispatch(editIssueCategoriesRequest({ data: formData, id: values.id }));
        }
      } else {
        dispatch(createIssueCategoriesRequest({ data: values }));
      }
      onClose();
      resetForm();
    },
  });

  const { values, handleSubmit, handleChange, setFieldValue } = formik;

  return (
    <CustomModal
      title="Edit Issue Categories"
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <FormControl margin="normal" fullWidth>
          <TextField
            label="Category"
            name="category"
            value={values.category}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            label="Type"
            name="type"
            value={values.type}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextEditor
            name="description"
            label="Description"
            value={formik.values.description}
            onSetFieldValue={setFieldValue}
          />
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          disabled={isSending}
          endIcon={isSending ? <CircularProgress size={18} /> : null}
        >
          Save
        </Button>
      </form>
    </CustomModal>
  );
};

export default memo(IssueCategoriesForm, isEqualPropsMemo);
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getDataSelector, getPendingSelector } from '../store/usersView/selectors';
import { fetchUsersViewRequest } from '../store/usersView/actions';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import PageTitle from '../components/ui/PageTitle';
import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import UsersForm from '../components/Users/UsersForm/UsersForm';

const UsersView: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(getDataSelector);
  const pending = useSelector(getPendingSelector);
  const [value, setValue] = React.useState<string>('1');

  useEffect(() => {
    dispatch(fetchUsersViewRequest({ id: id! }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { user_fullname } = data;  

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Personnel',
      path: '/personnel',
    },
    {
      title: `${user_fullname}`,
      path: '',
    }
  ];
  
  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle goBack title={`Personnel Details — ${user_fullname}`} />
      <Card>
        <CardContent>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab}>
                <Tab label="User info" value="1" />
                <Tab label="Notifications" value="2" />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <CardContent>
                <UsersForm user={data} />
              </CardContent>
            </TabPanel>
            <TabPanel value="2">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 320px)'
                }}
              >
                <div style={{ fontWeight: '500', fontSize: '40px', lineHeight: '120%' }}>Notifications settings</div>
                {/* <div style={{ fontWeight: '400', fontSize: '16px' }}>Notifications settings will be in the futures versions</div> */}
              </Box>
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default UsersView;
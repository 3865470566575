import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { viewARRequestInvoiceRequest } from '../store/ARRequestInvoices/actions';
import { getARRequestInvoicesViewSelector } from '../store/ARRequestInvoices/selectors';
import { getIsPendingSelector } from '../store/common/selectors';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import InvoicesARRequestForm from '../components/Invoices/InvoicesARRequest/InvoicesARRequestForm/InvoicesARRequestForm';
import InvoicesNotes from '../components/Invoices/InvoicesNotes/InvoicesNotes';

const InvoicesARRequestView: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const ARRequestInvoicesView = useSelector(getARRequestInvoicesViewSelector);
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `AR Requests`,
      path: '/invoices/request',
    },
    {
      title: `AR Invoice Request — ${ARRequestInvoicesView?.RequestNumber}`,
      path: '',
    },
  ];
  const pageTitle = `AR Invoice Request — ${ARRequestInvoicesView?.RequestNumber}`;

  useEffect(() => {
    if (id) {
      dispatch(viewARRequestInvoiceRequest({ ar_id: id }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  
  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack spacing={2} direction="row" alignItems="flex-start">
        <PageTitle goBack title={pageTitle} />
        {ARRequestInvoicesView.Status === 'progress' && <Button variant="outlined" style={{cursor: 'default'}}>In process</Button>}
        {ARRequestInvoicesView.Status === 'returned' && <Button variant="outlined" color="warning" style={{cursor: 'default'}}>Returned</Button>}
        {ARRequestInvoicesView.Status === 'completed' && <Button variant="outlined"color="success" style={{cursor: 'default'}}>Completed</Button>}
      </Stack>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {ARRequestInvoicesView && <InvoicesARRequestForm data={ARRequestInvoicesView} />}
            </Grid>
            <Grid item xs={12} md={6}>
              <InvoicesNotes 
                status={ARRequestInvoicesView.Status}
                notesList={ARRequestInvoicesView.Notes}
                id={ARRequestInvoicesView.id} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  )
};

export default InvoicesARRequestView;

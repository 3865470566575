import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import { getCategoriesListOptionsSelector, getItemsSelector } from '../../../store/system/issueCategories/selectors';
import { updateIssuesRequest } from '../../../store/issues/actions';
import { getUsersOptionsSelector } from '../../../store/users/selectors';
import { getIsSendingSelector } from '../../../store/common/selectors';
import { getCurrentJobSelector } from '../../../store/jobs/selectors';
import { severitySelectOptions, statusesSelectOptions } from '../../../constants/selectOptions';
import { IssueFormValidation } from '../../../constants/validations';
import { getDaysDiff, getFilteredObjectsArray, getObjectsDifference, getSortedSelectOptions, isEqualPropsMemo } from '../../../utils/helpers';
import { useDocuments } from '../../../utils/hooks';
import { IIssueCategoriesItem, IIssueCategoriesData } from '../../../types/system/issueCategories';
import { EAction, ISelectOptions } from '../../../types/common';
import AutocompleteSelect from '../../ui/AutocompleteSelect/AutocompleteSelect';
import ImagePreview from '../../ui/ImagePreview/ImagePreview';
import LoadingOverlay from '../../ui/LoadingOverlay';
import SelectField from '../../ui/SelectField/SelectField';
import DatePicker from '../../ui/DatePicker/DatePicker';
import FormLabel from '../../ui/FormLabel';
import Documents from '../../ui/Documents/Documents';
import RichTextEditor from '../../ui/RichTextEditor/RichTextEditor';

interface IIssuesFormProps {
  data?: { [key: string]: any },
  setCancelEdit?: () => void;
}

const IssuesViewEdit = ({ data, setCancelEdit }: IIssuesFormProps) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const job_id = useSelector(getCurrentJobSelector);
  const sending = useSelector(getIsSendingSelector);
  const issueCategories = useSelector(getItemsSelector);
  const usersOptions = getFilteredObjectsArray(useSelector(getUsersOptionsSelector));
  const issueCategoriesOptions = useSelector(getCategoriesListOptionsSelector);
  const [issueTypeOptions, setIssueTypeOptions] = useState<ISelectOptions[]>([]);
  const [issueCategoryName, setIssueCategoryName] = useState('');
  const [errorIssueCategoryName, setErrorIssueCategoryName] = useState(false);
  const { documents, handleSetDocuments, handleRemoveDocuments, handleUpdateDocuments } = useDocuments();

  const formik = useFormik({
    initialValues: {
      summary: data?.summary || '',
      location: data?.location || '',
      description: data?.description || '',
      severity: data?.severity || '',
      status: data?.status || '',
      category: data?.category || '',
      due_date: data?.due_date || '',
      recommendation: data?.recommendation || '',
      assigned_to: {
        id: '',
        fullname: '',
        ...data?.assigned_to,
      },
      assigned_at: data?.assigned_at || '',
    },
    validationSchema: IssueFormValidation,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: IIssueCategoriesData) => {
      if (data) {
        setCancelEdit?.()
        dispatch(updateIssuesRequest({
          job_id,
          ai_id: data.id,
          data: { ...getObjectsDifference(values, data), documents: documents.filter(i => i.action) },
        }));
        // console.log('UPDATE - ', { ...getObjectsDifference(values, data), documents: documents.filter(i => i.action) });
      }
    },
  });

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    setIssueTypeOptions(issueCategories.reduce((result: ISelectOptions[], issue: IIssueCategoriesItem) => {
      if (issue?.category === issueCategoryName) {
        result.push({
          label: issue?.type,
          value: issue?.id,
        });
      }
      return getSortedSelectOptions(result);
    }, []));

    // eslint-disable-next-line
  }, [issueCategoryName, data?.category]);

  useEffect(() => {
    if (data) {
      if (data.documents) handleSetDocuments(data.documents);

      if (data.assigned_to) {
        setFieldValue('assigned_to', data.assigned_to);
      }
      setIssueCategoryName(issueCategories.find((issue: any) => issue?.id === data.category)?.category || '');
    }

    // eslint-disable-next-line
  }, [data, issueCategories]);

  useEffect(() => {
    if (issueCategories && values.category) {
      setFieldValue('recommendation', issueCategories.find((issue: any) => issue?.id === values.category)?.description);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.category, issueCategories]);

  useEffect(() => {
    if (values.description === '<p><br></p>') {
      console.log(values.description);
      setFieldValue('description', '');
    }
  })

  const handleChangeAssigned = useCallback((value: string | undefined) => {
    if (value!.length === 0) {
      setFieldValue('assigned_to', {
        id: '',
        fullname: '',
      });   
      setFieldValue('assigned_at', '')
    } else {
      setFieldValue('assigned_to', {
        id: value,
        fullname: usersOptions.find((user: ISelectOptions) => user.value === value)?.label || '',
      });
      setFieldValue('assigned_at', moment());
    }
    // eslint-disable-next-line
  }, [usersOptions]);

  const handleChangeIssueCategory = (e: any) => {
    setIssueCategoryName(e.target.value)
    values.category = ''
    values.recommendation = ''
  }

  const handleChangeIssueType = (value: string | undefined) => {
    console.log('handleChangeIssueType', value);
    if (value!.length === 0) {
      setFieldValue('category', '');
    }
  }

  const handleSaveIssue = () => {
    if (!issueCategoryName.length) {
      setErrorIssueCategoryName(true)
    } else {
      setErrorIssueCategoryName(false)
    }
    handleSubmit()
  }
  return (
    <Card>
      <CardContent sx={{ position: 'relative' }}>
        {
          sending && <LoadingOverlay />
        }
        <Box sx={{ textAlign: 'right' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={isEmpty(getObjectsDifference(values, data!)) && !documents.filter(d => d.action).length === data?.documents.length}
            onClick={handleSaveIssue}
          >
            Save Changes
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              {
                documents.filter(document => document?.filetype.includes('image') && document?.action !== EAction.delete).length
                  ? documents.filter(document => document?.filetype.includes('image') && document?.action !== EAction.delete).map((document, index) => (

                    <Grid key={document?.id} item xs={index === 0 ? 12 : 3}>
                      <img src={document?.url} width="100%" alt="" />
                    </Grid>
                  ))
                  : <Grid item xs={12}>
                    <ImagePreview />
                  </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal">
            <FormLabel title="Summary" required />
              <TextField
                error={Boolean(errors.summary)}
                helperText={errors.summary}
                name="summary"
                value={values.summary}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
            <FormLabel title="Location" tooltip="An open text field defining the location where the issue occured"/>
              <TextField
                name="location"
                value={values.location}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel title="Issue Category" required />
              <SelectField
                error={Boolean(errorIssueCategoryName)}
                helperText={(errorIssueCategoryName ? 'Field is required' : '')}
                name="categoryName"
                options={issueCategoriesOptions}
                value={issueCategoryName}
                onChange={e => handleChangeIssueCategory(e)}
              />
            </FormControl>
            {
              issueTypeOptions.length > 0 &&
              <FormControl fullWidth margin="normal">
                <FormLabel title="Issue Type" required />
                <AutocompleteSelect
                  name="category"
                  error={Boolean(errors.category)}
                  helperText={errors.category}
                  disabled={!issueCategoryName}
                  options={issueTypeOptions}
                  value={values.category}
                  onSetFieldChange={setFieldValue}
                  onChange={handleChangeIssueType}
                />
              </FormControl>
            }
            {
              values.recommendation &&
              <FormControl fullWidth margin="normal">
                <FormLabel title="Recommendation" />
                <RichTextEditor 
                  name="recommendation"
                  value={values.recommendation} 
                  onSetFieldValue={setFieldValue}  
                  error={false}
                />
              </FormControl>
            }
            <FormControl fullWidth margin="normal">
              <FormLabel title="Description" required />
                <RichTextEditor 
                  name="description"
                  value={values.description} 
                  onSetFieldValue={setFieldValue}
                  error={errors?.description} 
                />
              {errors.description && <FormHelperText error>{errors.description}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth margin="normal">
            <FormLabel title="Status" />
              <SelectField
                name="status"
                options={statusesSelectOptions}
                value={values.status}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel title="Severity" required />
              <SelectField
                error={Boolean(errors.severity)}
                helperText={errors.severity}
                name="severity"
                options={severitySelectOptions}
                value={values.severity}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel title="Due date" required />
              <DatePicker
                error={Boolean(errors.due_date)}
                helperText={errors.due_date}
                name="due_date"
                value={values.due_date}
                onChange={handleChange}
              />
              {
                values.due_date
                  ? getDaysDiff(values.due_date) >= 0
                    ? 
                    <>
                      {values.due_date && moment(values.due_date).format('DD.MM.YYYY') === moment(values.assigned_at).format()
                        ? <div>Today</div>
                        : `${getDaysDiff(values.due_date) + 1} day${getDaysDiff(values.due_date) === 0 ? '' : '(s)'} left`
                      }
                    </>
                    : 'Overdue'
                  : <></>
              }
            </FormControl>
            <FormControl fullWidth margin="normal">
              <FormLabel title="Assigned to" />
              <AutocompleteSelect
                name="assigned_to"
                options={usersOptions}
                value={values.assigned_to?.id}
                onChange={handleChangeAssigned}
              />
              {
                values.assigned_to?.fullname &&
                <Typography>Assigned on: {moment(values.assigned_at).format()}</Typography>
              }
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <Grid container>
            <Grid item xs={12} md={9}>
              <Documents
                documents={documents}
                onUpload={handleUpdateDocuments}
                onRemove={handleRemoveDocuments}
              />
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default React.memo(IssuesViewEdit, isEqualPropsMemo);
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { routes } from '../constants/routes';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getTrainingViewSelector } from '../store/trainings/selectors';
import { viewTrainingsRequest } from '../store/trainings/actions';
import { getIsPendingSelector } from '../store/common/selectors';

import Layout from '../components/layout';
import PageTitle from '../components/ui/PageTitle';
import TrainingsViewLaunchList from '../components/Trainings/TrainingsViewLaunchList/TrainingsViewLaunchList';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const TrainingsView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(getTrainingViewSelector);
  const pending = useSelector(getIsPendingSelector);

  useEffect(() => {
    if (id) {
      dispatch(viewTrainingsRequest({ id }));
    }
    // eslint-disable-next-line
  }, [id]);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `Trainings`,
      path: '/trainings',
    },
    {
      title: `${data.name}`,
      path: `/trainings/${data.id}`,
    },
    {
      title: `Launch`,
      path: '',
    },
  ];

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title={data.name} />
      <Button 
        sx={{ mb: 1 }} 
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        component={NavLink} to={`${routes.trainings}/${id}`}
      >back</Button>
      <Card sx={{ height: 'calc(100vh - 210px)', position: 'relative' }}>
        <TrainingsViewLaunchList data={data} />
      </Card>
    </Layout>
  )
}

export default TrainingsView;
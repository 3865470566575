import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchIssueCategoriesRequest } from '../store/system/issueCategories/actions';
import { getIsPendingSelector } from '../store/common/selectors';
import { fetchUsersRequest } from '../store/users/actions';
import Layout from '../components/layout';
import PageTitle from '../components/ui/PageTitle';
import IssuesForm from '../components/ActionItems/IssuesForm';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const IssuesCreate: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);

  useEffect(() => {
    dispatch(fetchIssueCategoriesRequest());
    dispatch(fetchUsersRequest({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Issues',
      path: '/issues',
    },
    {
      title: 'Issues Create',
      path: '',
    }
  ]; 

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Add new Issue" />
      <IssuesForm />
    </Layout>
  );
};

export default IssuesCreate;
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import { ITrainingOnlineContent } from '../../../../types/trainings';
import TrainingPreview from '../TrainingPreview/TrainingPreview';
import FileUploadField from '../../../ui/FileUploadField';
import FormLabel from '../../../ui/FormLabel';

interface TrainingFromVideoProp {
  data: ITrainingOnlineContent | any,
  active: number;
  setFieldValue: (name: string, value: any) => void;
  onFileChange?: (url: string, filename: string) => void;
}

type Value = 'link' | 'file';

const TrainingFormVideo = ({ data, active, onFileChange, setFieldValue }: TrainingFromVideoProp) => {
  const [file, setFile] = useState<any>();
  const [link, setLink] = useState<string>(data.link || '');
  const [value, setValue] = useState<Value>(data.link ? 'link' : 'file');
  const [preview, setPreview] = useState<string>(data.link || data.url || '');

  const handleFileChange = (f: any) => {
    setFile(f);
    onFileChange?.(URL.createObjectURL(f), f.name);
  };

  const handleSetValue = (v: Value) => {
    setValue(v);
    setPreview('');
  };

  const handlePreview = () => {
    if (value === 'link') {
      setFieldValue(`online_content[${active}].link`, link);
      setFieldValue(`online_content[${active}].file`, '');
      setFieldValue(`online_content[${active}].url`, '');
      setFieldValue(`online_content[${active}].filename`, '');
    } else {
      setFieldValue(`online_content[${active}].link`, '');
      setFieldValue(`online_content[${active}].file`, file);
      setFieldValue(`online_content[${active}].url`, URL.createObjectURL(file));
      setFieldValue(`online_content[${active}].filename`, file.name);
    }
    setPreview(value === 'link' ? `https://www.youtube.com/embed/${link.substring(link.indexOf('v=') + 2)}` : URL.createObjectURL(file));
  };

  return (
    <>
      <FormControl fullWidth>
        <RadioGroup
          defaultValue="link"
          name="radio-buttons-group"
        >
          <FormControl margin="normal">
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <FormControlLabel
                  value="link"
                  sx={{ mb: 1 }}
                  control={<Radio />}
                  checked={value === 'link'}
                  onClick={() => handleSetValue('link')}
                  label=""
                />
              </Grid>
              <Grid item xs>
                <FormLabel title="Link to www.youtube.com video" />
                <TextField
                  fullWidth
                  value={link}
                  disabled={value === 'file'}
                  name={`online_content[${active}].link`}
                  placeholder="https://www.youtube.com/watch?v=.........."
                  onChange={e => setLink(e.target.value)}
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl margin="normal">
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  checked={value === 'file'}
                  onClick={() => handleSetValue('file')}
                  label=""
                />
              </Grid>
              <Grid item xs>
                <FormLabel title="or upload video file" />
                <FileUploadField
                  onChange={handleFileChange}
                  placeholder="no file chosen"
                  disabled={value === 'link'}
                  accept="video/mp4,video/x-m4v,video/*"
                />
              </Grid>
            </Grid>
          </FormControl>
        </RadioGroup>
      </FormControl>
      {
        preview &&
        <TrainingPreview frame={value === 'link'} url={preview} />
      }
      <Box sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="primary" onClick={handlePreview}>
          Preview & Apply
        </Button>
      </Box>
    </>
  );
};

export default TrainingFormVideo;
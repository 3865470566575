import React from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const TrainingsViewLaunchListQuestion = ({quizList}: any) => {
  
  return (
    <>
      {quizList.questions.map((item: any, index: number) => (
        <Box key={index} sx={{ padding: '0 0 60px 0' }}>
          <Typography dangerouslySetInnerHTML={{ __html: item.question }} />
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
            {item.type === 'multy'
            ? <div>
              {item.answers.map((item: any, index: number) => (
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                  <Checkbox
                    name={``}
                    checked={true}
                  />
                <div>{item.answer}</div>
                </Stack>
              ))}
            </div>
            : <div>
              {item.answers.map((item: any, index: number) => (
                <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
                  <FormControlLabel
                    label=""
                    name={``}
                    checked={true}
                    // value={item.correct}
                    control={<Radio />}
                    // onChange={() => onChangeAnswerCorrect(qIndex, aIndex, q.type, !item.correct)}
                  />
                <div>{item.answer}</div>
                </Stack>
              ))}
            </div>}
          </Stack>
        </Box>
      ))}
    </>
  )
}

export default TrainingsViewLaunchListQuestion;
import React from 'react';

import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle';

const Home = () => {

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '',
    },
  ];

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Home" />
    </Layout>
  );
};

export default Home;
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getCurrentJobSelector } from '../store/jobs/selectors';
import { EAction, IDocumentItem } from '../types/common';
import { fetchIssueCategoriesRequest } from '../store/system/issueCategories/actions';
import { getItemsSelector } from '../store/system/issueCategories/selectors';

export const useGetPageItemsEffect = (handler: Function, isDependsOnJob?: boolean) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const currentJob = useSelector(getCurrentJobSelector);
  const currentJob = localStorage.getItem('currentJob');
  
  const params = location.search;
  useEffect(() => {
    if (isDependsOnJob) {
      if (currentJob) {
        dispatch(handler({ params, job: currentJob }));
      }
    } else {
      dispatch(handler({ params }));
    }

    // eslint-disable-next-line
  }, [location, isDependsOnJob, currentJob]);
};

export const useTablePaginationState = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    setPage(searchParams.get('page') ? +searchParams.get('page')! : 0);
    setRowsPerPage(searchParams.get('size') ? +searchParams.get('size')! : 50);

    // eslint-disable-next-line
  }, [location]);

  return [page, rowsPerPage];
};

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  return { isModalOpen, handleToggleModal };
};

export const useDocuments = () => {
  const [documents, setDocuments] = useState<IDocumentItem[]>([]);

  const handleUpdateDocuments = useCallback((document: IDocumentItem) => {
    setDocuments(prev => ([...prev, document]));
  }, []);

  const handleRemoveDocuments = useCallback((id: string | undefined, index: number) => {
    const newDocuments = [...documents];
    id
      ? newDocuments[newDocuments.findIndex(i => i.id === id)].action = EAction.delete
      : newDocuments.splice(index, 1);
    setDocuments(newDocuments);
  }, [documents]);

  const handleSetDocuments = useCallback((documents: IDocumentItem[]) => setDocuments(documents), []);

  return { documents, handleSetDocuments, handleUpdateDocuments, handleRemoveDocuments };
};

export const useFetchIssueCategories = () => {
  const dispatch = useDispatch();
  const issues = useSelector(getItemsSelector);

  useEffect(() => {
    if (!issues.length) dispatch(fetchIssueCategoriesRequest());

    // eslint-disable-next-line
  }, [issues]);
};
import { all, put, call, takeLatest } from 'redux-saga/effects';

import {
  createIssueCategoriesFailure,
  createIssueCategoriesSuccess,
  deleteIssueCategoriesFailure,
  deleteIssueCategoriesSuccess,
  editIssueCategoriesFailure,
  editIssueCategoriesSuccess,
  fetchIssueCategoriesFailure,
  fetchIssueCategoriesSuccess,
} from './actions';
import {
  CREATE_ISSUE_CATEGORIES_REQUEST,
  DELETE_ISSUE_CATEGORIES_REQUEST,
  EDIT_ISSUE_CATEGORIES_REQUEST,
  FETCH_ISSUE_CATEGORIES_REQUEST,
} from './actionTypes';
import { ICreateIssueCategoriesRequest, IDeleteIssueCategoriesRequest, IEditIssueCategoriesRequest } from './types';
import { apiSagaRequest } from '../../../utils/saga';
import { notifyOpen } from '../../common/actions';

function* fetchIssueCategoriesSaga(): any {
  try {
    const response = yield call(apiSagaRequest, { type: 'get', url: '/system/aic' });
    yield put(fetchIssueCategoriesSuccess(response));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(fetchIssueCategoriesFailure({ error: e.message }));
    }
  }
}

function* deleteIssueCategoriesSaga({ params }: IDeleteIssueCategoriesRequest): any {
  try {
    yield call(apiSagaRequest, { type: 'del', url: `/system/aic/${params.id}` });
    yield put(deleteIssueCategoriesSuccess(params));
    yield put(notifyOpen({ notifyType: 'error', notifyMessage: 'Issue Category successfully deleted' }));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(deleteIssueCategoriesFailure({ error: e.message }));
    }
  }
}

function* editIssueCategoriesSaga({ params }: IEditIssueCategoriesRequest): any {
  try {
    const response = yield call(apiSagaRequest, { type: 'put', url: `/system/aic/${params.id}`, data: params.data });
    yield put(editIssueCategoriesSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue Category information successfully updated' }));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(editIssueCategoriesFailure({ error: e.message }));
      yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue Category information update failed' }));
    }
  }
}

function* createIssueCategoriesSaga({ params }: ICreateIssueCategoriesRequest): any {
  try {
    const response = yield call(apiSagaRequest, { type: 'post', url: `/system/aic/`, data: params.data });
    yield put(createIssueCategoriesSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue Category successfully created' }));
  } catch (e) {
    console.error(e);
    if (e instanceof Error) {
      yield put(createIssueCategoriesFailure({ error: e.message }));
      yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Issue Category create failed' }));
    }
  }
}

function* usersSaga() {
  yield all([
    takeLatest(FETCH_ISSUE_CATEGORIES_REQUEST, fetchIssueCategoriesSaga),
    takeLatest(DELETE_ISSUE_CATEGORIES_REQUEST, deleteIssueCategoriesSaga),
    takeLatest(EDIT_ISSUE_CATEGORIES_REQUEST, editIssueCategoriesSaga),
    takeLatest(CREATE_ISSUE_CATEGORIES_REQUEST, createIssueCategoriesSaga),
  ]);
}

export default usersSaga;
import { all, put, call, takeLatest } from 'redux-saga/effects';

import { 
  createARRequestInvoiceSuccess, 
  fetchARRequestInvoiceSuccess, 
  updateARRequestInvoiceSuccess, 
  viewARRequestInvoiceSuccess 
} from './actions';
import { 
  CREATE_AR_REQUEST_INCOICE_REQUEST, 
  FETCH_AR_REQUEST_INCOICE_REQUEST, 
  VIEW_AR_REQUEST_INCOICE_REQUEST, 
  UPDATE_AR_REQUEST_INCOICE_REQUEST 
} from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
import { notifyOpen, setPending } from '../common/actions';
import { IFetchARRequestInvoicesRequest } from './types';
import { fetchUsersSuccess } from '../users/actions';
import { fetchJobsSuccess, fetchSubJobSuccess } from '../jobs/actions';

function* fetchARRequestInvoicesSaga({ params }: IFetchARRequestInvoicesRequest): any {  
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: '/arinvreq', params: params.params });
    yield put(fetchARRequestInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* viewARRequestInvoicesSaga({ params }: any): any {  
  try {
    const { ar_id } = params;
    yield put(setPending(true));

    const response = yield call(apiSagaRequest, { type: 'get', url: `/arinvreq/${ar_id}` });
    yield put(viewARRequestInvoiceSuccess(response));

    const subJob = yield call(apiSagaRequest, { type: 'get', url: `/jobs/${response.Job}` });   
    yield put(fetchSubJobSuccess(subJob));

    const jobs = yield call(apiSagaRequest, { type: 'get', url: '/jobs', params: '?size=1000000&filter=Status%3DOpen' });
    yield put(fetchJobsSuccess(jobs));

    const users = yield call(apiSagaRequest, { type: 'get', url: '/users' });
    yield put(fetchUsersSuccess(users));

  } finally {
    yield put(setPending(false));
  }
}

function* createARRequestInvoicesSaga({ params }: any): any {  
  const { data } = params;
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'post', url: '/arinvreq', data });
    yield put(createARRequestInvoiceSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'New AR Invoice Request successfully created' }));
    params.navigate('/invoices/request');
  } catch (e) {
    console.error(e);
    yield put(notifyOpen({ notifyType: 'error', notifyMessage: 'New AR Invoice Request created failed' }));
  } finally {
    yield put(setPending(false));
  }
}

function* updateARRequestInvoicesSaga({ params }: any): any {  
  const { ar_id, data } = params;
  try {
    if (data.Notes) {
      yield put(setPending(false));
    } else {
      yield put(setPending(true));
    }
    const response = yield call(apiSagaRequest, { type: 'put', url: `/arinvreq/${ar_id}`, data });
    yield put(updateARRequestInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* ARRequestInvoicesSaga() {
  yield all([
    takeLatest(FETCH_AR_REQUEST_INCOICE_REQUEST, fetchARRequestInvoicesSaga),
    takeLatest(VIEW_AR_REQUEST_INCOICE_REQUEST, viewARRequestInvoicesSaga),
    takeLatest(CREATE_AR_REQUEST_INCOICE_REQUEST, createARRequestInvoicesSaga),
    takeLatest(UPDATE_AR_REQUEST_INCOICE_REQUEST, updateARRequestInvoicesSaga),
  ]);
}

export default ARRequestInvoicesSaga;
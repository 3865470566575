import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ISelectOptions } from '../../../types/common';
import Box from '@mui/material/Box';

interface ButtonMenuProps {
  buttonText: string;
  options: ISelectOptions[];
  onChange: (v: string) => void;
}

const ButtonMenu = ({ buttonText, options, onChange }: ButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (v: string) => {
    handleClose();
    onChange(v);
  };

  return (
    <>
      <Button
        variant="outlined"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {buttonText}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {
          options.map((opt, index) => (
            <MenuItem key={`${opt}-${index}`} onClick={() => handleChoose(`${opt.value}`)}>
              {opt.Icon && <opt.Icon />}
              <Box sx={{ ml: 1.5 }}>{opt.label}</Box>
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

export default ButtonMenu;
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useFilter = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    if (searchParams.get('filter')) {
      searchParams.delete('filter');
      navigate(`?${searchParams}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFilters = (name: string, value: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemoveFilters = (name: string) => {
    const data = { ...filters };
    delete data[name];
    setFilters(data);
  };

  return { filters, handleChangeFilters, handleRemoveFilters };
};
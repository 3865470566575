// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getIsPendingSelector } from '../store/common/selectors';
import { getManpowerViewRequestSelector, getManpowerStatusSelector } from '../store/manpower/selectors';
import { getJobsSelector } from '../store/jobs/selectors';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import ManpowerForm from '../components/Manpower/ManpowerForm/ManpowerForm';

import { viewManpowerRequest } from '../store/manpower/actions';
import { useGetPageItemsEffect } from '../utils/hooks';
import { fetchJobsRequest } from '../store/jobs/actions';
import { fetchUsersRequest } from '../store/users/actions';
import { fetchManpowerDefaultsRequest } from '../store/manpower/actions';

const ManpowerView: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const data = useSelector(getManpowerViewRequestSelector);
  const [status, setStatus] = useState<string>('');
  const statusSelector = useSelector(getManpowerStatusSelector);
  const jobList = useSelector(getJobsSelector);
  const [job_id, setJob_id] = useState(localStorage.getItem('currentJob'));
  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
      },
      {
        title: 'Manpower',
        path: '/manpower',
      },
      {
        title: `Manpower Request — ${id}`,
        path: '',
      },
  ]

  useGetPageItemsEffect(fetchJobsRequest);
  useGetPageItemsEffect(fetchUsersRequest);

  useEffect(() => {
    if ((!job_id || job_id === undefined || job_id === '' || job_id === null) && jobList.length) {
      localStorage.setItem('currentJob', jobList[0]?.Job)
      setJob_id(jobList[0]?.Job);
    } else {
      const currentJobId = localStorage.getItem('currentJob');
      if (currentJobId !== null) {
        setJob_id(currentJobId);
      }
    }
  }, [jobList, job_id])

  const pageTitle = `Manpower Request — ${id}`;

  useEffect(() => {
    if (id) {
      dispatch(viewManpowerRequest({ mpr_id: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setStatus(data?.Status);
  }, [data])

  useEffect(() => {
    setStatus(statusSelector?.Status);
  }, [statusSelector])

  useEffect(() => {
    dispatch(fetchManpowerDefaultsRequest());
  }, [dispatch]);

  const handleUpdateStatus = (status: string) => {
    setStatus(status)
  }

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack spacing={2} direction='row' alignItems='flex-start'>
        <PageTitle goBack title={pageTitle} />
        {status === 'progress' && <Button variant='outlined' color="primary">In progress</Button>}
        {status === 'completed' && <Button variant='outlined' color="success">Completed</Button>}
        {status === 'canceled' && <Button variant='outlined' color="warning">Canceled</Button>}
      </Stack>
      <Card>
        <CardContent>
          <ManpowerForm data={data} handleUpdateStatus={handleUpdateStatus}/>
        </CardContent>
      </Card>
    </Layout>
  )
}

export default ManpowerView

import React from 'react';
import CustomModal from '../ui/CustomModal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface IProps {
  open: boolean,
  onClose: () => void,
  onConfirm: () => void,
}

const ConfirmDeletingModal = ({ open, onClose, onConfirm }: IProps) => {
  return (
    <CustomModal onClose={onClose} open={open} style={{ maxWidth: 300 }}>
      <Typography variant="body1" align="center" gutterBottom>
        Do you want to delete this item?
      </Typography>
      <Stack spacing={2} direction="row" sx={{ mt: 2, justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={onConfirm}>
          Delete
        </Button>
        <Button onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </CustomModal>
  );
};

export default ConfirmDeletingModal;
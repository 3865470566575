import { createSelector } from 'reselect';
import { getSortedSelectOptions } from '../../utils/helpers';

import { AppState } from '../rootReducer';

const getManpowerList = (state: AppState) => state.manpower.items;
const getManpowerDefaults = (state: AppState) => state.manpower.defaultsMPR;
const getManpowerDefaultsCertifications = (state: AppState) => state.manpower.defaultsMPR.certifications;
const getManpowerDefaultsClasses = (state: AppState) => state.manpower.defaultsMPR.classes;
const getManpowerDefaultsCrafts = (state: AppState) => state.manpower.defaultsMPR.crafts;
const getManpowerDefaultsEmployees = (state: AppState) => state.manpower.defaultsMPR.employees;
const getManpowerDefaultsSpecialities = (state: AppState) => state.manpower.defaultsMPR.specialities;
const getManpowerViewRequest = (state: AppState) => state.manpower.view;
const getManpowerEmployeesList = (state: AppState) => state.manpower.defaultsMPR.employees;
const getManpowerStatus = (state: AppState) => state.manpower.view;

export const getManpowerListSelector = createSelector(getManpowerList, items => items);
export const getManpowerDefaultsSelector = createSelector(getManpowerDefaults, defaultsMPR => defaultsMPR);
export const getManpowerDefaultsCertificationsOptionsSelector = createSelector(getManpowerDefaultsCertifications, certifications => getSortedSelectOptions(certifications.map((item: any) => ({
    label: item.Name,
    value: item.ID,
}))));
export const getManpowerDefaultsClassesOptionsSelector = createSelector(getManpowerDefaultsClasses, classes => getSortedSelectOptions(classes.map((item: any) => ({
    label: item.Name,
    value: item.ID,
}))));
export const getManpowerDefaultsCraftsOptionsSelector = createSelector(getManpowerDefaultsCrafts, crafts => getSortedSelectOptions(crafts.map((item: any) => ({
    label: item.Name,
    value: item?.ID?.trim(),
}))));
export const getManpowerDefaultsEmployeesOptionsSelector = createSelector(getManpowerDefaultsEmployees, employees => getSortedSelectOptions(employees.map((item: any) => ({
    label: item.Name,
    value: item.ID,
}))));
export const getManpowerDefaultsSpecialitiesOptionsSelector = createSelector(getManpowerDefaultsSpecialities, specialities => getSortedSelectOptions(specialities.map((item: any) => ({
    label: item.Name,
    value: item.ID,
}))));
export const getManpowerViewRequestSelector = createSelector(getManpowerViewRequest, items => items)
export const getEmployeesListSelector = createSelector(getManpowerEmployeesList, employees => getSortedSelectOptions(employees.map((employee: any) => ({
    label: employee.Name,
    value: employee.ID,
    class: employee.Class,
    craft: employee.Craft,
}))));
export const getManpowerStatusSelector = createSelector(getManpowerStatus, items => items)
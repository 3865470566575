import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { isEqualPropsMemo } from '../../../utils/helpers';
import { roleSelectOptions } from '../../../constants/selectOptions';
import { UserFormValidation } from '../../../constants/validations';
import { updateUserRequest } from '../../../store/usersView/actions';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '../../ui/FormLabel/FormLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import SelectField from '../../ui/SelectField/SelectField';

const UsersForm = ({user}: any) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      user_fullname: user?.user_fullname || '',
      user_email: user?.user_email || '',
      user_phone: user?.user_phone || '',
      user_role: user?.user_role || '',
    },
    validationSchema: UserFormValidation,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(updateUserRequest({
        id: user.id,
        data: {
          user_role: values.user_role
        },
      }));
    }
  })

  const { values, errors, handleChange, handleSubmit, resetForm } = formik;
  
  return (
    <Grid container sx={{ margin: '0 0 20px 0' }}>
      <Grid item xs={4} md={4}>
        <form onSubmit={handleSubmit}>
          {/* Full name */}
          <FormControl fullWidth margin="normal">
          <FormLabel title="Full name" required />
            <TextField
              error={Boolean(errors.user_fullname)}
              helperText={errors.user_fullname}
              name="user_fullname"
              value={values.user_fullname}
              disabled
              onChange={handleChange}
            />
          </FormControl>
          {/* Email */}
          <FormControl fullWidth margin="normal">
          <FormLabel title="Email" required />
            <TextField
              error={Boolean(errors.user_email)}
              helperText={errors.user_email}
              name="user_email"
              value={values.user_email}
              disabled
              onChange={handleChange}
            />
          </FormControl>
          {/* Phone */}
          <FormControl fullWidth margin="normal">
          <FormLabel title="Phone" />
            <TextField
              name="user_phone"
              value={values.user_phone}
              disabled
              onChange={handleChange}
            />
          </FormControl>
          {/* Role */}
          <FormControl fullWidth margin="normal">
          <FormLabel title="Role" required />
          <SelectField
            name="user_role"
            options={roleSelectOptions}
            value={values.user_role}
            onChange={handleChange}
          />
          </FormControl>
          <Box sx={{ textAlign: 'right' }}>
            {user?.user_role !== values.user_role &&
              <Button
                sx={{ margin: '0 10px 0 0' }}
                variant="outlined"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
            }
            <Button
              type="submit"
              variant="contained"
              disabled={user?.user_role === values.user_role}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

export default memo(UsersForm, isEqualPropsMemo);
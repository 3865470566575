import React from 'react';
import moment from 'moment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface IDatePickerProps {
  type?: 'date' | 'datetime-local';
}

const DatePicker = ({
                      type = 'date',
                      label,
                      name,
                      value,
                      onChange,
                      ...props
                    }: IDatePickerProps & TextFieldProps): JSX.Element => {
  return (
    <TextField
      name={name}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
      {...props}
      InputLabelProps={{
        shrink: true,
        ...props.InputLabelProps,
      }}
      inputProps={{
        min: moment().format('YYYY-MM-DD'),
        ...props.inputProps,
      }}
    />
  );
};

export default DatePicker;
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

import FormLabel from '../../ui/FormLabel/FormLabel';
import SelectField from '../../ui/SelectField/SelectField';
import DatePicker from '../../ui/DatePicker/DatePicker';
import ManpowerNotes from '../ManpowerNotes/ManpowerNotes'
import ManpowerEmployee from '../ManpowerEmployee/ManpowerEmployee';
import ManpowerDialog from '../ManpowerDialog/ManpowerDialog';

import { getFilteredObjectsArray } from '../../../utils/helpers';
import { getJobsOptionsSelector, getJobsSelector } from '../../../store/jobs/selectors';
import { getUsersOptionsManpowerCreateSelector } from '../../../store/users/selectors';
import { 
  createManpowerRequest,
  updateManpowerRequest,
  updateManpowerDetailsRequest,
  createNewManpowerDetails,
  deleteManpowerRequestDetail
} from '../../../store/manpower/actions';
import { getAuthDataSelector, getUserRoleSelector } from '../../../store/auth/selectors';
import { getEmployeesListSelector } from '../../../store/manpower/selectors';

import { 
  getManpowerDefaultsCertificationsOptionsSelector, 
  getManpowerDefaultsClassesOptionsSelector, 
  getManpowerDefaultsCraftsOptionsSelector, 
  getManpowerDefaultsSpecialitiesOptionsSelector 
} from '../../../store/manpower/selectors';
import AutocompleteSelect from '../../ui/AutocompleteSelect/AutocompleteSelect';

import { ManpowerRequestValidation } from '../../../constants/validations';
import moment from 'moment';

const ManpowerForm = ({ data, handleUpdateStatus }: any) => {
  const authData = useSelector(getAuthDataSelector);
  const userRole = useSelector(getUserRoleSelector);
  const [state, setState] = useState<boolean>(false);
  const [openEmployee, setOpenEmployee] = useState<boolean>(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
  const jobsOptions = getFilteredObjectsArray(useSelector(getJobsOptionsSelector));
  const jobs = useSelector(getJobsSelector);
  const certificationsOptions = useSelector(getManpowerDefaultsCertificationsOptionsSelector);
  const classesOptions = useSelector(getManpowerDefaultsClassesOptionsSelector);
  const craftsOptions = useSelector(getManpowerDefaultsCraftsOptionsSelector);
  const specialitiesOptions = useSelector(getManpowerDefaultsSpecialitiesOptionsSelector);
  const usersOptions = useSelector(getUsersOptionsManpowerCreateSelector);
  const employeesList = useSelector(getEmployeesListSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dt_id, setDtId] = useState<number>(0);
  const req_id = data?.ReqID;
  const [isModify, setIsModify] = useState<boolean>(false);
  const status = data?.Status;
  const [disabledUpdate, setDisabledUpdate] = useState<boolean>(true);
  const [currentRowId, setCurrentRowId] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const jobParams = searchParams.get('job');
  const [filteredUsersOptions, setFilteredUsersOptions] = useState([]);
  const [currentRow, setCurrentRow] = useState({
      id: '',
      Class: 0,
      Craft: '',
      Specialty: 0,
      Cert1: 0,
      Cert2: 0,
      Qty: 0,
      Duration: 0,
      IssuedQty: 0,
      Notes: '',
      Employees: []
  });

  const formik = useFormik({
    initialValues: {
      requestor: data?.RequestBy || usersOptions?.filter(user => user.label === authData?.user_fullname)[0]?.value,
      job: data?.Job || '' || jobParams,
      customer: data?.Customer || '',
      startDate: data?.StartTime ? moment(data?.StartTime).format('YYYY-MM-DD') : '',
      startTime: data?.StartTime ? moment(data?.StartTime).format('HH:mm:ss') : '',
      dateRequested: data?.Created ? moment(data?.Created).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      reportsTo: data?.ReportTo || '',
      hoursDay: data?.Hrs || '',
      daysWeek: data?.Days || '',
      rows: data?.Details ? data?.Details : [
        {
          id: 1,
          Craft: '',
          Class: '',
          Specialty: '',
          Cert1: '',
          Cert2: '',
          Qty: '',
          Duration: '',
          Notes: '',
          Employees: [],
        },
      ],
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ManpowerRequestValidation,
    onSubmit: (values: any) => {
      const rows = values.rows.map((row: any) => {
        if (row.Cert2 !== '' && row.Cert2 !== ' ' && row.Cert2 !== null && row.Cert2 !== undefined) {
          return {
            "Class": row.Class,
            "Craft": row.Craft,
            "Specialty": row.Specialty,
            "Cert1": row.Cert1,
            "Cert2": row.Cert2,
            "Qty": Number(row.Qty),
            "Duration": Number(row.Duration),
          }
        } else {
          return {
            "Class": row.Class,
            "Craft": row.Craft,
            "Specialty": row.Specialty,
            "Cert1": row.Cert1,
            "Qty": Number(row.Qty),
            "Duration": Number(row.Duration),
          }
        }
      });
      const startTime = data ? `${values.startDate}T${values.startTime}` : `${values.startDate}T${values.startTime}:00`
      const info1 = {
        "Job": values.job,
        "StartTime": startTime,
        "ReportTo": values.reportsTo,
        "Hrs": Number(values.hoursDay),
        "Days": Number(values.daysWeek),
        "Details": rows,
      };
      dispatch(createManpowerRequest({
        navigate,
        data: info1
      }));
    },
  });

  useEffect(() => {
    if (jobParams !== null) {
      setFieldValue('job', jobParams);
      const currentCustomer = jobs.filter(job => job.Job === jobParams)[0]?.Customer;
      setFieldValue('customer', currentCustomer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobParams, jobs]);

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (values.job === '' || values.job === ' ' || values.job === null || values.job === undefined) {
      setFieldValue('customer', '')
    }
  }, [setFieldValue, values.job])

  useEffect(() => {
    if (
      data?.Customer === values.customer &&
      data?.Days === Number(values.daysWeek) &&
      data?.Hrs === Number(values.hoursDay) &&
      data?.Job === values.job &&
      data?.ReportTo === values.reportsTo &&
      data?.RequestBy === values.requestor &&
      moment(data?.StartTime).format('YYYY-MM-DD') === values.startDate &&
      moment(data?.StartTime).format('HH:mm:ss') === values.startTime
    ) {
      setDisabledUpdate(true)
    } else {
      setDisabledUpdate(false)
    }
  }, [data, values]);

  useEffect(() => {
    const filteredUsers = usersOptions.filter((user: any) => user.role === 'admin' ||
      user.role === 'user_dispatcher' ||
      user.role === 'user_pm' ||
      user.role === 'user_super'
    )
    setFilteredUsersOptions(filteredUsers)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersOptions])

  const handleAddRow = () => {
    const id = values.rows.length + 1;
    const typeData = {
      id: id,
      Craft: '',
      Class: '',
      Specialty: '',
      Cert1: '',
      Cert2: '',
      Qty: '',
      Duration: '',
      Notes: '',
    };
    setFieldValue('rows', [...values.rows, typeData]);
  };

  const handleDeleteRow = (id: number) => {
    if (values.rows.length !== 1) {
      const newRowList = values.rows.filter((item: any) => item.id !== id);
      setFieldValue('rows', newRowList);
      dispatch(deleteManpowerRequestDetail({
        req_id: req_id,
        id: id,
      }));
    }
  };

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState(open);
  };

  const toggleEmployee = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setOpenEmployee(open);
  };

  const toggleRemoveDialog = (open: any) => (rowId: any) => {
    if (values.rows.length !== 1) {
      setCurrentRowId(rowId);
      setOpenRemoveDialog(open);
    }
  };

  const handleRejectRequest = () => {
    const startTime = `${values.startDate}T${values.startTime}`
    const info = {
      "Job": values.job,
      "StartTime": startTime,
      "ReportTo": values.reportsTo,
      "RequestBy": values.requestor,
      "Hrs": values.hoursDay,
      "Days": values.daysWeek,
      "Status": "canceled"
    }
    dispatch(updateManpowerRequest({
      mpr_id: req_id,
      data: info,
    }))
    handleUpdateStatus('canceled');
  };

  const handleApproveRequest = () => {
    const startTime = `${values.startDate}T${values.startTime}`
    const info = {
      "Job": values.job,
      "StartTime": startTime,
      "ReportTo": values.reportsTo,
      "RequestBy": values.requestor,
      "Hrs": values.hoursDay,
      "Days": values.daysWeek,
      "Status": "completed"
    }
    dispatch(updateManpowerRequest({
      mpr_id: req_id,
      data: info,
    }))
    handleUpdateStatus('completed');
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

  const handleUpdateRequest = () => {
    let startTime = `${values.startDate}T${values.startTime}`
    if (!startTime.includes(':00')) {
      startTime = `${values.startDate}T${values.startTime}:00`
    }
    const info = {
      "Job": values.job,
      "StartTime": startTime,
      "ReportTo": values.reportsTo,
      "RequestBy": values.requestor,
      "Hrs": Number(values.hoursDay),
      "Days": Number(values.daysWeek),
      "Status": "progress"
    }
    formik.validateForm().then((error) => {
      if (isEmpty(error)) {
        dispatch(updateManpowerRequest({
          mpr_id: req_id,
          data: info,
        }))
      }
    })
  }

  useEffect(() => {
    const currentCustomer = jobs.filter(job => job.Job === values.job)[0]?.Customer;
    setFieldValue('customer', currentCustomer);
  }, [values.job, jobs, setFieldValue])

  const handleSetCurrentRow = (row: any) => {
    if (currentRow.id === row.id) {
      setCurrentRow({
        id: row.id,
        Class: row.Class,
        Craft: row.Craft,
        Specialty: row.Specialty,
        Cert1: row.Cert1,
        Cert2: row.Cert2,
        Qty: Number(row.Qty),
        Duration: Number(row.Duration),
        IssuedQty: 0,
        Notes: currentRow.Notes,
        Employees: row.Employees ? row.Employees : [],
      });
    } else {
      setCurrentRow({
        id: row.id,
        Class: row.Class,
        Craft: row.Craft,
        Specialty: row.Specialty,
        Cert1: row.Cert1,
        Cert2: row.Cert2,
        Qty: Number(row.Qty),
        Duration: Number(row.Duration),
        IssuedQty: 0,
        Notes: row.Notes,
        Employees: row.Employees ? row.Employees : [],
      });
    }
  };

  const handleSaveRow = (row: any) => {
    if (data?.Details?.length < values.rows.length) {
      let data = {}
      if (row.Cert2 !== '' && row.Cert2 !== ' ' && row.Cert2 !== null && row.Cert2 !== undefined) {
        data = {
          Class: row.Class,
          Craft: row.Craft,
          Specialty: row.Specialty,
          Cert1: row.Cert1,
          Cert2: row.Cert2,
          Qty: Number(row.Qty),
          Duration: Number(row.Duration),
        };
      } else {
        data = {
          Class: row.Class,
          Craft: row.Craft,
          Specialty: row.Specialty,
          Cert1: row.Cert1,
          Qty: Number(row.Qty),
          Duration: Number(row.Duration),
        };
      }
      dispatch(createNewManpowerDetails({
        mpr_id: req_id,
        data: data,
      }));
    } else {
      let data = {}
      if (row.Cert2 !== '' && row.Cert2 !== ' ' && row.Cert2 !== null && row.Cert2 !== undefined) {
        data = {
          Class: row.Class,
          Craft: row.Craft,
          Specialty: row.Specialty,
          Cert1: row.Cert1,
          Cert2: row.Cert2,
          Qty: Number(row.Qty),
          Duration: Number(row.Duration),
          IssuedQty: row.IssuedQty,
          Notes: row.Notes,
          Employees: row.Employees?.length ? row.Employees : [],
        };
      } else {
        data = {
          Class: row.Class,
          Craft: row.Craft,
          Specialty: row.Specialty,
          Cert1: row.Cert1,
          Qty: Number(row.Qty),
          Duration: Number(row.Duration),
          IssuedQty: row.IssuedQty,
          Notes: row.Notes,
          Employees: row.Employees?.length ? row.Employees : [],
        };
      }
      dispatch(updateManpowerDetailsRequest({
        mpr_id: req_id,
        dt_id: row.id,
        data: data,
      }));
      if (row.Employees === undefined) {
        currentRow.Employees = []
        currentRow.id = newRowId
      }
    }
  };

  const handleUpdateEmployees = (employees: any) => (open: boolean) => {
    const row = values?.rows?.filter((row: any) => row.id === currentRow?.id);
    const needRow = row[0];
    needRow.Employees = employees.filter((employee: any) => employee !== null && employee !== undefined);
    needRow.Duration = Number(needRow.Duration);
    needRow.IssuedQty = employees.length || 0;
    handleSaveRow(needRow);
    toggleEmployee(false)(open);
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {/* Requestor */}
            <FormControl margin='normal' fullWidth>
              <FormLabel title='Requestor' required />
              <SelectField
                name='requestor'
                error={Boolean(errors.requestor)}
                helperText={errors.requestor}
                value={values.requestor}
                options={filteredUsersOptions}
                onChange={handleChange}
                disabled={!data || status === 'canceled' || status === 'completed' || userRole === 'user_pm' || userRole === 'user_super'}
              />
            </FormControl>
            {/* Job */}
            <FormControl margin='normal' fullWidth>
              <FormLabel title='Job' required />
              <AutocompleteSelect
                name='job'
                error={Boolean(errors.job)}
                helperText={errors.job}
                options={jobsOptions}
                value={values.job}
                onChange={(e) => {
                  setFieldValue('job', e);
                  const currentCustomer = jobs.filter(job => job.Job === e)[0]?.Customer;
                  setFieldValue('customer', currentCustomer);
                }}
                disabled={status === 'canceled' || status === 'completed'}
              />
            </FormControl>
            {/* Customer */}
            <FormControl margin='normal' fullWidth>
              <FormLabel title='Customer' required />
              <TextField
                placeholder='Not avaliable'
                name='customer'
                value={values.customer}
                onChange={handleChange}
                disabled={true}
              />
            </FormControl>
            {/* Start date */}
            <FormControl margin='normal' fullWidth>
              <FormLabel title='Start date' required />
              <DatePicker
                error={Boolean(errors.startDate)}
                helperText={errors.startDate}
                name='startDate'
                value={values.startDate}
                onChange={handleChange}
                disabled={status === 'canceled' || status === 'completed'}
              />
            </FormControl>
            {/* Start time */}
            <FormControl margin='normal' fullWidth>
              <FormLabel title='Start time' required />
              <TextField
                error={Boolean(errors.startTime)}
                helperText={errors.startTime}
                name='startTime'
                type='time'
                value={values.startTime}
                onChange={handleChange}
                disabled={status === 'canceled' || status === 'completed'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              height='100%'
              direction='column'
              justifyContent='space-between'
            >
              <div>
                {/* Date Requested */}
                <FormControl margin='normal' fullWidth>
                  <FormLabel title='Date Requested' required />
                  <DatePicker
                    error={Boolean(errors.dateRequested)}
                    helperText={errors.dateRequested}
                    name='dateRequested'
                    value={values.dateRequested}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormControl>
                {/* Reports to */}
                <FormControl margin='normal' fullWidth>
                  <FormLabel title='Reports to' required />
                  <AutocompleteSelect
                    name='reportsTo'
                    error={Boolean(errors.reportsTo)}
                    helperText={errors.reportsTo}
                    options={filteredUsersOptions}
                    value={values.reportsTo}
                    onChange={(e) => {
                      setFieldValue('reportsTo', e)
                    }}
                    disabled={status === 'canceled' || status === 'completed'}
                  />
                </FormControl>
              </div>
              <div>
                {/* Hours/Day */}
                <FormControl margin='normal' fullWidth>
                  <FormLabel title='Hours/Day' required />
                  <TextField
                    error={Boolean(errors.hoursDay)}
                    helperText={errors.hoursDay}
                    name='hoursDay'
                    value={values.hoursDay}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      handleChange(e);
                    }}
                    disabled={status === 'canceled' || status === 'completed'}
                  />
                </FormControl>
                {/* Days/Week */}
                <FormControl margin='normal' fullWidth>
                  <FormLabel title='Days/Week' required />
                  <TextField
                    error={Boolean(errors.daysWeek)}
                    helperText={errors.daysWeek}
                    name='daysWeek'
                    value={values.daysWeek}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      handleChange(e);
                    }}
                    disabled={status === 'canceled' || status === 'completed'}
                  />
                </FormControl>
              </div>
            </Stack>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2, mt: 2 }} />
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell style={{ borderBottom: '0' }}>ID</TableCell>
              <TableCell style={{ borderBottom: '0' }}>Craft <span style={{color: 'red'}}>*</span></TableCell>
              <TableCell style={{ borderBottom: '0' }}>Class  <span style={{color: 'red'}}>*</span></TableCell>
              <TableCell style={{ borderBottom: '0' }}>Specialty  <span style={{color: 'red'}}>*</span></TableCell>
              <TableCell style={{ borderBottom: '0' }}>Certification  <span style={{color: 'red'}}>*</span></TableCell>
              <TableCell style={{ borderBottom: '0' }}>Certification 2</TableCell>
              <TableCell style={{ borderBottom: '0' }}>Qty  <span style={{color: 'red'}}>*</span></TableCell>
              <TableCell style={{ borderBottom: '0' }}>Duration (Days)  <span style={{color: 'red'}}>*</span></TableCell>
              {data && <TableCell style={{ borderBottom: '0' }}>Notes</TableCell>}
              {data && <TableCell style={{ borderBottom: '0' }}>Employees</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.rows.map((row: any, index: number) => (
              <TableRow key={index}>
                {/* Id */}
                <TableCell style={{ borderBottom: '0' }}>{index + 1}</TableCell>
                {/* Craft */}
                <TableCell style={{ borderBottom: '0' }}>
                  <SelectField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Craft : false)}
                    helperText={errors?.rows?.[index]?.Craft}
                    name={`rows[${index}].Craft`}
                    value={row.Craft}
                    options={craftsOptions}
                    onChange={handleChange}
                    sx={{ minWidth: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Class */}
                <TableCell style={{ borderBottom: '0' }}>
                  <SelectField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Class : false)}
                    helperText={errors?.rows?.[index]?.Class}
                    name={`rows[${index}].Class`}
                    value={row.Class}
                    options={classesOptions}
                    onChange={handleChange}
                    sx={{ minWidth: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Specialty */}
                <TableCell style={{ borderBottom: '0' }}>
                  <SelectField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Specialty : false)}
                    helperText={errors?.rows?.[index]?.Specialty}
                    name={`rows[${index}].Specialty`}
                    value={row.Specialty}
                    options={specialitiesOptions}
                    onChange={handleChange}
                    sx={{ minWidth: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Certification 1 */}
                <TableCell style={{ borderBottom: '0' }}>
                  <SelectField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Cert1 : false)}
                    helperText={errors?.rows?.[index]?.Cert1}
                    name={`rows[${index}].Cert1`}
                    value={row.Cert1}
                    options={certificationsOptions}
                    onChange={handleChange}
                    sx={{ minWidth: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Certification 2 */}
                <TableCell style={{ borderBottom: '0' }}>
                  <SelectField
                    name={`rows[${index}].Cert2`}
                    value={row.Cert2}
                    options={certificationsOptions}
                    onChange={handleChange}
                    sx={{ minWidth: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Qty */}
                <TableCell style={{ borderBottom: '0' }}>
                  <TextField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Qty : false)}
                    helperText={errors?.rows?.[index]?.Qty}
                    name={`rows[${index}].Qty`}
                    value={row.Qty}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      handleChange(e);
                    }}
                    sx={{ width: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {/* Duration */}
                <TableCell style={{ borderBottom: '0' }}>
                  <TextField
                    error={Boolean(errors?.rows ? errors?.rows[index]?.Duration : false)}
                    helperText={errors?.rows?.[index]?.Duration}
                    name={`rows[${index}].Duration`}
                    value={row.Duration}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      handleChange(e);
                    }}
                    sx={{ width: 120 }}
                    disabled={data && !(currentRow.id === row.id && isModify)}
                  />
                </TableCell>
                {data && !row.Notes && (status !== 'completed' && status !== 'canceled') && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='text' onClick={(e: any) => {
                      handleSetCurrentRow(row);
                      toggleDrawer(true)(e);
                      setDtId(row.id);
                    }}
                      >
                      Add notes
                    </Button>
                  </TableCell>
                )}
                {data && row.Notes && (status !== 'completed' && status !== 'canceled') && (
                  <TableCell style={{ borderBottom: '0' }}>
                  <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='outlined' onClick={(e: any) => {
                    handleSetCurrentRow(row);
                    toggleDrawer(true)(e);
                    setDtId(row.id);
                  }}
                    >
                    View comments
                  </Button>
                </TableCell>
                )}
                {data && row.Notes && (status === 'canceled' || status === 'completed') && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='outlined' onClick={(e: any) => {
                      handleSetCurrentRow(row);
                      toggleDrawer(true)(e);
                      setDtId(row.id);
                    }}
                      >
                      View comments
                    </Button>
                  </TableCell>
                )}
                {data && !row.Notes && status === 'canceled' && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <p style={{color: 'gray'}}>Empty</p>
                  </TableCell>
                )}
                {data && !row.Notes && status === 'completed' && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <p style={{color: 'gray'}}>Without notes</p>
                  </TableCell>
                )}
                {/* Employee */}
                {data && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Typography sx={{ fontSize: 15, color: 'gray' }}>
                      {row?.Employees?.length ? `Selected ${row?.Employees?.length} from ${row?.Qty}` : `No employees selected`}
                      </Typography> 
                  </TableCell>
                )}
                {data && status !== 'canceled' && status !== 'completed' && (
                  <>
                    {isModify && row.id === currentRow.id && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='contained' onClick={() => {
                          handleSaveRow(row);
                          setIsModify(false);
                        }}>
                          Save
                        </Button>
                      </TableCell>
                    )}
                    {!isModify && (userRole !== 'user_dispatcher') && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='contained' onClick={() => {
                          handleSetCurrentRow(row);
                          setIsModify(true);
                          }}>
                          Modify
                        </Button>
                      </TableCell>
                    )}
                    {isModify && (userRole !== 'user_dispatcher') && row.id !== currentRow.id && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='contained' onClick={() => {
                          handleSetCurrentRow(row);
                          setIsModify(true);
                          }}>
                          Modify
                        </Button>
                      </TableCell>
                    )}
                    {isModify && row.id === currentRow.id && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} onClick={() => {setIsModify(false)}}>
                          Cancel
                        </Button>
                      </TableCell>
                    )}
                    {!isModify && (userRole === 'admin' || userRole === 'user_dispatcher') && !row?.Employees?.length && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='contained' onClick={(e) => {
                          handleSetCurrentRow(row);
                          toggleEmployee(true)(e);
                          }}>
                          Issue
                        </Button>
                      </TableCell>
                    )}
                    {isModify && (userRole === 'admin' || userRole === 'user_dispatcher') && row.id !== currentRow.id && !row?.Employees?.length && (
                      <TableCell style={{ borderBottom: '0' }}>
                        <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant='contained' onClick={(e) => {
                          handleSetCurrentRow(row);
                          toggleEmployee(true)(e);
                        }}>
                          Issue
                        </Button>
                      </TableCell>
                    )}
                  </>
                )}
                {data && Boolean(row?.Employees?.length) && !isModify && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant="contained" onClick={(e) => {
                      handleSetCurrentRow(row);
                      toggleEmployee(true)(e);
                      }}
                      >
                        View Employees
                      </Button>
                  </TableCell>
                )}
                {data && Boolean(row?.Employees?.length) && isModify && row.id !== currentRow.id && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button sx={{whiteSpace: 'nowrap', minWidth: 'auto'}} variant="contained" onClick={(e) => {
                      handleSetCurrentRow(row);
                      toggleEmployee(true)(e);
                      }}
                      >
                        View Employees
                      </Button>
                  </TableCell>
                )}
                {isModify && row.id === currentRow.id && values.rows.length !== 1 && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button
                      variant='text'
                      onClick={() => {
                        toggleRemoveDialog(true)(row.id);
                      }}
                      sx={{ maxWidth: 60 }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
                {!data && values.rows.length !== 1 && (
                  <TableCell style={{ borderBottom: '0' }}>
                    <Button
                      variant='text'
                      onClick={() => {
                        toggleRemoveDialog(true)(row.id);
                      }}
                      sx={{ maxWidth: 60 }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data && status !== 'canceled' && status === 'progress' && (
          <Button variant='outlined' sx={{ mt: 3, mb: 3 }} onClick={handleAddRow}>
            Add new row
          </Button>
        )}
        {!data && (
          <Button variant='outlined' sx={{ mt: 3, mb: 3 }} onClick={handleAddRow}>
            Add new row
          </Button>
        )}
        <Box sx={{ textAlign: 'left' }}>
          {!data && (
            <>
              <Button type='submit' variant='contained' sx={{ mr: 1 }} disabled={data}>
                Submit
              </Button>
              <Button onClick={() => navigate('/manpower')}>Cancel</Button>
            </>
          )}
          {data && status !== 'calceled' && status === 'progress' && (
            <div style={{marginTop: '1rem'}}>
              <Button variant='contained' sx={{ mr: 1 }} onClick={handleUpdateRequest} disabled={disabledUpdate}>
                Update Request
              </Button>
              {(userRole === 'admin' || userRole === 'user_dispatcher') && (
                <Button variant='contained' sx={{ mr: 1 }} color="success" onClick={() => handleApproveRequest()}>
                  Approve
                </Button>
              )}
              {(userRole === 'admin' || userRole === 'user_super' || userRole === 'user_pm') && (
                <Button variant='contained' sx={{ mr: 1 }} color="error" onClick={() => handleRejectRequest()}>
                  Reject
                </Button>
              )}
              <Button onClick={() => navigate('/manpower')}>Cancel</Button>
            </div>
          )}
          {data && status !== 'progress' && (
            <div style={{marginTop: '1rem'}}>
              <Button onClick={() => navigate('/manpower')}>Cancel</Button>
            </div>
          )}
        </Box>
      </form>
      <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
        <div style={{ width: '600px', padding: '75px 20px 10px 20px' }}>
          <ManpowerNotes
            reqId={req_id}
            id={dt_id}
            row={currentRow} 
            notesList={currentRow.Notes}
            setCurrentRow={setCurrentRow}
            status={status}
            setState={setState}
            rows={values.rows}
            />
        </div>
      </Drawer>
      <Drawer anchor={'right'} open={openEmployee} onClose={toggleEmployee(false)}>
        <div style={{ width: '600px', padding: '75px 20px 10px 20px' }}>
          <ManpowerEmployee
            toggleEmployee={toggleEmployee}
            status={status}
            row={currentRow}
            employeesList={employeesList}
            handleUpdateEmployees={handleUpdateEmployees}
            userRole={userRole}
            />
        </div>
      </Drawer>
      <ManpowerDialog
        open={openRemoveDialog}
        setOpenRemoveDialog={setOpenRemoveDialog}
        rowId={currentRowId}
        handleDeleteRow={handleDeleteRow}
        />
    </>
  )
}

export default ManpowerForm

const awsconfig = {
    Auth: {
        mandatorySignIn: true,
        region: "us-east-1",
        userPoolId: "us-east-1_dIqkJGHZu",
        userPoolWebClientId: "7gm1bdtbdbi7l5a21955vv0kpu"
    },
    API: {
        endpoints: [
            {
                name: "gci",
                endpoint: "https://uyjykuzzwj.execute-api.us-east-1.amazonaws.com/prod"
            }
        ]
    }
};


export default awsconfig;
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAPInvoiceViewSelector } from '../store/APInvoice/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { viewAPInvoiceRequest } from '../store/APInvoice/actions';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
// import DocumentPDF from '../components/ui/DocumentPDF/DocumentPDF'

const InvoicesAPView: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);
  const data = useSelector(getAPInvoiceViewSelector);

  useEffect(() => {
    if (id) {      
      dispatch(viewAPInvoiceRequest({ ap_id: id!}));
    }
    // eslint-disable-next-line
  }, [id]);


  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `AP Invoices`,
      path: '/invoices/ap',
    },
    {
      title: `${id}`,
      path: '',
    },
  ];
  const pageTitle = `AP Invoice ${id}`;
  // const data = [
  //   {title: 'Job', value: '023346E-F'},
  //   {title: 'Subjob', value: '023346E-01F'},
  //   {title: 'Vendor', value: 'TECO - Big Bend'},
  //   {title: 'Invoice Amount', value: '$70000.00'},
  //   {title: 'Invoice Date', value: '12/04/2022'},
  //   {title: 'Cost Type', value: '???'},
  //   {title: 'JCTransType', value: '???'},
  //   {title: 'CT', value: '???'},
  //   {title: 'PO #', value: '6768950-09D'},
  //   {title: 'Approved Date', value: '12/04/2022'},
  //   {title: 'Paid Date', value: '12/04/2022'},
  //   {title: 'PM', value: 'Larry Cali'},
  //   {title: 'Job Status', value: 'Open'},
  //   {title: 'Job Description', value: 'Job description'},
  //   {title: 'Attachment', value: 'Not applicable'}
  // ]
  
  // console.log('ITEM VIEW - ', data);
  
  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle goBack title={pageTitle} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
            {/* Job */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Job</Typography>
              <TextField style={{ width: '360px' }} disabled value={data.Job} />
            </div>
            {/* Subjob */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Subjob</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.SubJob} />
            </div>
            {/* Vendor */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Vendor</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.Vendor} />
            </div>
            {/* Invoice Amount */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Invoice Amount</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.InvoiceAmount} />
            </div>
            {/* Invoice Date */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Invoice Date</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.InvoiceDate} />
            </div>
            {/* Cost Type */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Cost Type</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.Cost} />
            </div>
            {/* JCTransType */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>JCTransType</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.JCTransType} />
            </div>
            {/* CT */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>CT</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.value} />
            </div>
            {/* PO # */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>PO #</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.PO} />
            </div>
            {/* Approved Date */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Approved Date</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.ApprovedDate} />
            </div>
            {/* Paid Date */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Paid Date</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.PaidDate} />
            </div>
            {/* PM */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>PM</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.PM} />
            </div>
            {/* Job Status */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Job Status</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.Status} />
            </div>
            {/* Job Description */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Job Description</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.JobDescription} />
            </div>
            {/* Attachment */}
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '20px' }}>
              <Typography color="black" style={{ width: '200px' }}>Attachment</Typography>
              <TextField style={{ width: '360px' }} disabled value={data?.Attachment} />
            </div>
            <Stack style={{ width: '560px' }} spacing={2} direction="row" justifyContent="space-between">
              <Button variant="contained" color="success">Approve</Button>
              <Button variant="contained" color="error">Reject</Button>
            </Stack>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <DocumentPDF />
        </Grid> */}
      </Grid>
    </Layout>
  )
};

export default InvoicesAPView;

import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';
import { getSortedSelectOptions } from '../../utils/helpers';

const getUsers = (state: AppState) => state.users.items;

export const getUsersSelector = createSelector(getUsers, items => items);
export const getUsersOptionsSelector = createSelector(getUsers, items => getSortedSelectOptions(items.map((item: any) => ({
  label: item.user_fullname,
  value: item.id,
}))));
export const getUsersOptionsManpowerCreateSelector = createSelector(getUsers, items => getSortedSelectOptions(items.map((item: any) => ({
  label: item.user_fullname,
  value: item.user_vid,
  role: item.user_role,
}))))
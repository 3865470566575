export const FETCH_ISSUE_CATEGORIES_REQUEST = 'FETCH_ISSUE_CATEGORIES_REQUEST';
export const FETCH_ISSUE_CATEGORIES_SUCCESS = 'FETCH_ISSUE_CATEGORIES_SUCCESS';
export const FETCH_ISSUE_CATEGORIES_FAILURE = 'FETCH_ISSUE_CATEGORIES_FAILURE';

export const CREATE_ISSUE_CATEGORIES_REQUEST = 'CREATE_ISSUE_CATEGORIES_REQUEST';
export const CREATE_ISSUE_CATEGORIES_SUCCESS = 'CREATE_ISSUE_CATEGORIES_SUCCESS';
export const CREATE_ISSUE_CATEGORIES_FAILURE = 'CREATE_ISSUE_CATEGORIES_FAILURE';

export const EDIT_ISSUE_CATEGORIES_REQUEST = 'EDIT_ISSUE_CATEGORIES_REQUEST';
export const EDIT_ISSUE_CATEGORIES_SUCCESS = 'EDIT_ISSUE_CATEGORIES_SUCCESS';
export const EDIT_ISSUE_CATEGORIES_FAILURE = 'EDIT_ISSUE_CATEGORIES_FAILURE';

export const DELETE_ISSUE_CATEGORIES_REQUEST = 'DELETE_ISSUE_CATEGORIES_REQUEST';
export const DELETE_ISSUE_CATEGORIES_SUCCESS = 'DELETE_ISSUE_CATEGORIES_SUCCESS';
export const DELETE_ISSUE_CATEGORIES_FAILURE = 'DELETE_ISSUE_CATEGORIES_FAILURE';
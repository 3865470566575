import React from 'react';
import Amplify from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import awsExports from '../../awsconfig';
import { getIsAuthSelector } from '../../store/auth/selectors';

Amplify.configure(awsExports);

export default function PrivateRoute() {
  const isAuth = useSelector(getIsAuthSelector);

  return isAuth ? <Outlet /> : <Navigate to="/sign-in" />;
}
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '1264px',
  height: '100vh',
});

export const WrapperInner = styled('div')({
  display: 'flex',
  flexGrow: 1,
  minHeight: 0,
});

export const MainContent = styled('main')({
  flexGrow: 1,
  width: '100%',
  padding: '10px 40px',
  overflow: 'auto',
});
import { IDocumentItem } from './common';
import { ETrainingLanguage, ETrainingLevel, ETrainingTypes } from './trainings';

export enum ESessionStatus {
  'canceled' = 'canceled',
  'complete' = 'complete',
  'expired' = 'expired',
  'scheduled' = 'scheduled',
}

export enum ESessionValidationType {
  'attendance' = 'attendance',
  'pass' = 'pass',
}

export interface ISessionItem {
  id?: string;
  training: {
    id: string
    name: string
    category: string
    description: string
    type: typeof ETrainingTypes | ''
    language: typeof ETrainingLanguage | ''
    duration: 0
    level: typeof ETrainingLevel | ''
  };
  time_zone: string;
  start_time: string;
  finish_time: string;
  verification_type: typeof ESessionValidationType;
  status?: typeof ESessionStatus;
  link?: string;
  notes?: string;
  trainer: {
    fullname: string
    email: string
    phone: string
  };
  address: {
    zipcode: string
    country: string
    state: string
    city: string
    street: string
  };
  notifications?: number[];
  participants?: string[];
  documents?: IDocumentItem[];
}
import { all, put, call, takeLatest } from 'redux-saga/effects';

import { fetchManpowerSuccess,
  fetchManpowerDefaultsSuccess,
  viewManpowerRequestSuccess,
  updateManpowerRequestDetailsSuccess,
  updateManpowerRequestSuccess,
  createNewManpowerDetailsSuccess
} from './actions';
import { FETCH_MANPOWER_REQUEST,
  FETCH_MANPOWER_DEFAULTS_REQUEST,
  VIEW_MANPOWER_REQUEST,
  CREATE_MANPOWER_REQUEST,
  UPDATE_MANPOWER_DETAILS_REQUEST,
  UPDATE_MANPOWER_REQUEST,
  CREATE_NEW_MANPOWER_DETAILS,
  DELETE_MANPOWER_REQUEST_DETAIL
} from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
import { notifyOpen, setPending, setSending } from '../common/actions';
import { ICreateManpowerRequest } from './types';

function* fetchAPIManpowerSaga(): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/mpr` });
    yield put(fetchManpowerSuccess(response));
  } finally {
    yield put(setPending(false));
  }
};

function* fetchAPIManpowerDefaultsSaga(): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/defaults/mpr` });
    yield put(fetchManpowerDefaultsSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* createManpowerSaga({ params }: ICreateManpowerRequest): any {
  try {
    const data = params.data;
    yield put(setSending(true));
    const response = yield call(apiSagaRequest, { type: 'post', url: `/mpr`, data });
    console.log('Response from create MP', response)

    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Manpower successfully added' }));
    params.navigate('/manpower');
  } finally {
    yield put(setSending(false));
  }
}

function* viewManpowerRequestSaga({ params }: any): any {  
  try {
    const { mpr_id } = params;
    yield put(setPending(true));

    const response = yield call(apiSagaRequest, { type: 'get', url: `/mpr/${mpr_id}` });
    yield put(viewManpowerRequestSuccess(response));

  } finally {
    yield put(setPending(false));
  }
}

function* updateManpowerDetailsRequestSaga ({ params }: any): any {
  const { mpr_id, dt_id, data } = params;
  try {   
    const response = yield call(apiSagaRequest, { type: 'put', url: `/mpr/${mpr_id}/details/${dt_id}`, data });
    yield put(updateManpowerRequestDetailsSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* createNewManpowerDetailsSaga ({ params }: any): any {
  const { mpr_id, data } = params;
  try {
    const response = yield call(apiSagaRequest, { type: 'post', url: `/mpr/${mpr_id}/details`, data });
    yield put(createNewManpowerDetailsSuccess(response));
    console.log(response);
  } finally {
    yield put(setPending(false));
  }
}

function* updateManpowerRequestSaga ({ params }: any): any {  
  const { mpr_id, data } = params;
  try {   
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'put', url: `/mpr/${mpr_id}`, data });
    yield put(updateManpowerRequestSuccess(response));
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Manpower successfully updated!' }));
  } finally {
    yield put(setPending(false));
  }
}

function* deleteManpowerRequestDetail ({ params }: any): any {  
  const { req_id, id } = params;
  try {   
    const response = yield call(apiSagaRequest, { type: 'del', url: `/mpr/${req_id}/details/${id}` });
    console.log(response)
    yield put(notifyOpen({ notifyType: 'success', notifyMessage: 'Manpower detail successfully deleted!' }));
  } finally {
    yield put(setPending(false));
  }
}

function* posSaga() {
  yield all([
    takeLatest(FETCH_MANPOWER_REQUEST, fetchAPIManpowerSaga),
    takeLatest(FETCH_MANPOWER_DEFAULTS_REQUEST, fetchAPIManpowerDefaultsSaga),
    takeLatest(CREATE_MANPOWER_REQUEST, createManpowerSaga),
    takeLatest(VIEW_MANPOWER_REQUEST, viewManpowerRequestSaga),
    takeLatest(UPDATE_MANPOWER_DETAILS_REQUEST, updateManpowerDetailsRequestSaga),
    takeLatest(CREATE_NEW_MANPOWER_DETAILS, createNewManpowerDetailsSaga),
    takeLatest(UPDATE_MANPOWER_REQUEST, updateManpowerRequestSaga),
    takeLatest(DELETE_MANPOWER_REQUEST_DETAIL, deleteManpowerRequestDetail)
  ])
};

export default posSaga

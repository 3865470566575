import { all, put, call, takeLatest } from 'redux-saga/effects'

import { setPending } from '../common/actions'
import { fetchUsersSuccess } from './actions'
import { FETCH_USERS_REQUEST } from './actionTypes'
import { apiSagaRequest } from '../../utils/saga'
import { IFetchUsersRequest } from './types'

function* fetchUsersSaga({ params }: IFetchUsersRequest): any {
  try {
    const paramsFetch = '?size=1000000'
    yield put(setPending(true))
    const response = yield call(apiSagaRequest, {
      type: 'get',
      url: '/users',
      params: paramsFetch,
    })
    yield put(fetchUsersSuccess(response))
  } finally {
    yield put(setPending(false))
  }
}

function* usersSaga() {
  yield all([takeLatest(FETCH_USERS_REQUEST, fetchUsersSaga)])
}

export default usersSaga

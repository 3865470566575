import { all, put, call, takeLatest } from 'redux-saga/effects';

import { fetchAPInvoiceSuccess, viewAPInvoiceSuccess } from './actions';
import { FETCH_AP_INCOICE_REQUEST, FETCH_AP_INCOICE_VIEW_REQUEST } from './actionTypes';
import { apiSagaRequest } from '../../utils/saga';
// import { IFetchPosRequest } from './types';
import { setPending } from '../common/actions';

function* fetchAPInvoiceSaga({ params }: any): any {
  try {
    const paramsFetch = `?job_id=${params.job}`
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/aps`, params: paramsFetch });
    yield put(fetchAPInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* viewAPInvoiceSaga({ params }: any): any {
  try {
    yield put(setPending(true));
    const response = yield call(apiSagaRequest, { type: 'get', url: `/aps/${params.ap_id}` });    
    yield put(viewAPInvoiceSuccess(response));
  } finally {
    yield put(setPending(false));
  }
}

function* posSaga() {
  yield all([
    takeLatest(FETCH_AP_INCOICE_REQUEST, fetchAPInvoiceSaga),
    takeLatest(FETCH_AP_INCOICE_VIEW_REQUEST, viewAPInvoiceSaga)
  ]);
}

export default posSaga;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Layout from '../components/layout/Layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import ManpowerForm from '../components/Manpower/ManpowerForm/ManpowerForm';

import { getIsPendingSelector } from '../store/common/selectors';
import { useGetPageItemsEffect } from '../utils/hooks';
import { fetchJobsRequest } from '../store/jobs/actions';
import { fetchUsersRequest } from '../store/users/actions';
import { fetchManpowerDefaultsRequest } from '../store/manpower/actions';

const ManpowerCreate: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const pending = useSelector(getIsPendingSelector);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `Manpower`,
      path: '/manpower',
    },
    {
      title: `New Manpower Request`,
      path: '',
    },
  ];
  const pageTitle = `New Manpower Request`;

  useGetPageItemsEffect(fetchJobsRequest);
  useGetPageItemsEffect(fetchUsersRequest);

  useEffect(() => {
    dispatch(fetchManpowerDefaultsRequest())
  }, [dispatch]);

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack spacing={2} direction='row' alignItems='flex-start'>
        <PageTitle goBack title={pageTitle} />
      </Stack>
      <Card>
        <CardContent>
          <ManpowerForm />
        </CardContent>
      </Card>
    </Layout>
  )
}

export default ManpowerCreate

export enum ETrainingTypes {
  'online' = 'Online',
  'webinar' = 'Webinar',
  'onsite' = 'Onsite'
}

export enum ETrainingLanguage {
  'us' = 'us',
  'es' = 'es'
}

export enum ETrainingStatus {
  'active' = 'Active',
  'inactive' = 'Inactive'
}

export enum ETrainingLevel {
  'fundamental' = 'Fundamental',
  'intermediate' = 'Intermediate',
  'advanced' = 'Advanced',
}

export enum ETrainingOnlineContentType {
  'video' = 'ideo',
  'quiz' = 'quiz',
  'text' = 'text'
}

export enum ETrainingOnlineContentQuestionTypes {
  'select' = 'select',
  'multy' = 'multy'
}

export interface ITrainingOnlineContentQuestionAnswer {
  id: number,
  answer: string,
  correct: boolean
}

export interface ITrainingOnlineContentQuestion {
  id: number,
  question: string,
  type: ETrainingOnlineContentQuestionTypes,
  answers: ITrainingOnlineContentQuestionAnswer[]
}

export interface ITrainingOnlineContent {
  id: number | string,
  title: string,
  type: keyof ETrainingOnlineContentType,
  url?: string,
  link?: string,
  filename?: string,
  pass_criteria?: number,
  text_content?: string,
  questions?: ITrainingOnlineContentQuestion[]
}

export interface ITrainingItem {
  id: string,
  name: string,
  category: string,
  description: string,
  type: ETrainingTypes,
  language: ETrainingLanguage,
  duration: number,
  status: ETrainingStatus,
  level: ETrainingLevel,
  certificate_validity: number,
  url?: string,
  content?: string,
  online_content: ITrainingOnlineContent[],
  certificate_template?: {
    filename: string,
    url: string,
    url_post: string
  },
  date: string
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchIssueCategoriesRequest } from '../store/system/issueCategories/actions';
import { getIssuesSelector } from '../store/issues/selectors';
import { fetchIssuesRequest } from '../store/issues/actions';
import { fetchUsersRequest } from '../store/users/actions';
import { getCurrentJobSelector } from '../store/jobs/selectors';
import { getIsPendingSelector } from '../store/common/selectors';
import { useFetchIssueCategories, useGetPageItemsEffect } from '../utils/hooks';
import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';
import PageTitle from '../components/ui/PageTitle/PageTitle';
import ActionItemsTable from '../components/ActionItems/ActionItemsTable/ActionItemsTable';
import Loading from '../components/ui/Loading/Loading';

const Issues: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const items = useSelector(getIssuesSelector);
  const job_id = useSelector(getCurrentJobSelector);
  const pending = useSelector(getIsPendingSelector);

  useEffect(() => {
    if (job_id) {
      dispatch(fetchIssueCategoriesRequest());
      dispatch(fetchUsersRequest({}));
    }

    // eslint-disable-next-line
  }, [job_id]);

  useFetchIssueCategories();
  useGetPageItemsEffect(fetchIssuesRequest, true);

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
     {
      title: 'Issues',
      path: '',
    }
  ]; 

  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Issues" />
      {pending
        ? <Loading style={{ height: 'calc(100vh - 170px);' }} />
        : <ActionItemsTable dataValues={items} />
      }
    </Layout>
  );
};

export default Issues;
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ReportIcon from '@mui/icons-material/Report';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export interface ILinks {
  path: string,
  label: string,
  icon?: any,
}

export interface IAsideLinkItem {
  label: string;
  submenu: ILinks[];
  icon?: any;
}

interface IAsideLinks {
  [key: string]: IAsideLinkItem & {
    subcategory?: IAsideLinkItem[];
  },
}

export const routes = {
  home: '/',
  jobs: '/jobs',
  jobsView: '/jobs/:id',
  // addJobs: '/jobs/new',
  personnel: '/personnel',
  usersView: '/personnel/:id',
  issues: '/issues',
  issuesCreate: '/issues/new',
  issuesView: '/issues/:id',
  trainings: '/trainings',
  trainingsCreate: '/trainings/new',
  trainingsView: '/trainings/:id',
  trainingsViewLaunch: '/trainings/:id/launch',
  trainingsProfile: '/trainings/profile',
  trainingSessions: '/trainings/sessions',
  trainingSessionsCreate: '/trainings/sessions/new',

  manpower: '/manpower',
  manpowerCreate: '/manpower/new',
  manpowerView: '/manpower/:id',

  invoices: '/invoices',
  invoicesView: '/invoices/:id',
  invoicesARRequest: '/invoices/request',
  invoicesARRequestCreate: '/invoices/request/new',
  invoicesARRequestView: '/invoices/request/:id',
  invoicesAP: '/invoices/ap',
  invoicesAPView: '/invoices/ap/:id',

  pos: '/pos',
  posView: '/pos/:id',

  system: '/system',
  systemIssue: '/system/issue',

  singIn: '/sign-in',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
};

export const appBarLinks: ILinks[] = [
  { path: routes.jobs, label: 'Jobs' },
  { path: routes.personnel, label: 'Personnel' },
  // { path: routes.trainings, label: 'Training' },
  // { path: routes.issues, label: 'Action Items' },
  { path: routes.manpower, label: 'Manpower' },
  { path: routes.invoices, label: 'Invoices' },
  // { path: routes.pos, label: `PO's` },
];

export const systemLinks: ILinks[] = [
  { path: routes.systemIssue, label: 'Issue Categories', icon: ListAltIcon },
  { path: '/', label: 'Support', icon: ListAltIcon },
  { path: '/', label: 'Permission', icon: ListAltIcon },
];

export const asideLinks: IAsideLinks = {
  jobs: {
    label: 'Jobs',
    icon: WorkIcon,
    submenu: [
      { path: routes.jobs, label: 'List', icon: WorkIcon },
      // { path: routes.addJobs, label: 'Add Job', icon: WorkIcon },
    ],
  },
  personnel: {
    label: 'Personnel',
    icon: PeopleAltIcon,
    submenu: [
      { path: routes.personnel, label: 'List', icon: PersonIcon },
    ],
  },
  // issues: {
  //   label: 'Action items',
  //   icon: ReportIcon,
  //   submenu: [
  //     { path: routes.issues, label: 'Issue', icon: ListAltIcon },
  //     { path: routes.issuesCreate, label: 'Add Issue', icon: PlaylistAddIcon },
  //   ],
  // },
  // trainings: {
  //   label: 'Training',
  //   icon: SchoolIcon,
  //   submenu: [
  //     { path: routes.trainings, label: 'Catalog', icon: ListAltIcon },
  //     { path: routes.trainingsCreate, label: 'Add Training', icon: PlaylistAddIcon },
  //     { path: routes.trainingsProfile, label: 'Profile', icon: AssignmentOutlinedIcon },
  //   ],
  //   subcategory: [
  //     {
  //       label: 'Session',
  //       icon: EventIcon,
  //       submenu: [
  //         { path: routes.trainingSessions, label: 'Calendar', icon: EventNoteIcon },
  //         { path: routes.trainingSessionsCreate, label: 'Schedule', icon: EventAvailableIcon },
  //       ],
  //     },
  //   ],
  // },
  manpower: {
    label: 'Manpower',
    icon: ListAltIcon,
    submenu: [
      { path: routes.manpower, label: `List`, icon: ListAltIcon }
    ],
  },
  invoices: {
    label: `Invoices`,
    icon: ListAltIcon,
    submenu: [
      { path: routes.invoices, label: 'AR List', icon: ListAltIcon },
      { path: routes.invoicesARRequest, label: 'AR Requests', icon: ListAltIcon },
      { path: routes.invoicesAP, label: 'AP List', icon: ListAltIcon }
    ],
  },
  pos: {
    label: `PO's`,
    icon: ListAltIcon,
    submenu: [
      { path: routes.pos, label: `List`, icon: ListAltIcon }
    ],
  }
};
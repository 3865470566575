import React, { memo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { EAction, IDocumentItem } from '../../../types/common';
import { byte2string, isEqualPropsMemo } from '../../../utils/helpers';
import { notifyOpen } from '../../../store/common/actions';

interface IDocumentsProps {
  edit?: boolean,
  documents: IDocumentItem[],
  onRemove: (id: string | undefined, index: number) => void,
  onUpload: (e: IDocumentItem) => void,
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton component="span" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Documents = ({ edit, documents, onUpload, onRemove }: IDocumentsProps): JSX.Element => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [data, setData] = useState<IDocumentItem>({
    filename: '',
    filetype: '',
    filesize: 0,
    url: '',
    description: '',
    file: {},
    action: EAction.create,
  });
  const fileRef = useRef<HTMLInputElement | any>();

  const handleShowUpload = () => setIsOpen(true);

  const handleToggleExpanded = () => setIsExpanded(!isExpanded);

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      description: e.target.value,
    }));
    setError('');
  };

  const handleChangeFile = (e: any) => {
    if ( e.target.files![0]?.size > 104857600) {
      console.log(e.target.files![0]?.size);
      dispatch(notifyOpen({ notifyType: 'error', notifyMessage: 'Document size must be less then 100MB' }));
      return;
    }
    if (e.target?.files.length === 0) {
      return;
    }    
    if (documents?.some((document: IDocumentItem) => isEqual(document.filename, e.target.files![0].name))) {
      setError('This file is already exists');
      return;
    }
    setData(prev => ({
      ...prev,
      action: EAction.create,
      filename: e.target.files![0]?.name,
      filetype: e.target.files![0]?.type,
      filesize: e.target.files![0]?.size,
      file: e.target.files![0],
      url: URL.createObjectURL(e.target.files![0]),
    }));
    setError('');
  };

  const handleUploadDocument = () => {
    if (!data.description || !data.filename) {
      setError(!data.description ? 'Description is required' : 'Upload a file');
      return;
    }
    onUpload(data);
    setData({
      filename: '',
      filetype: '',
      filesize: 0,
      url: '',
      action: EAction.create,
      description: '',
      file: {},
    });
    fileRef.current!.value = '';
    setIsOpen(false);
  };

  return (
    <>
      <Button
        fullWidth
        size="small"
        color="inherit"
        sx={{
          justifyContent: 'space-between',
          p: 0,
        }}
        endIcon={
          <ExpandMore
            expand={isExpanded}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        onClick={handleToggleExpanded}
      >
        Documents <Box sx={{ ml: 'auto' }}>{documents.filter(i => i.action !== 'delete').length}</Box>
      </Button>
      <Divider />
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {
          documents.filter(document => document.action !== 'delete').map((document: IDocumentItem, index: number) => (
            <Box my={1} key={document.filename}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs>
                  {document.filename}
                </Grid>
                <Grid item xs>
                  {document.description}
                </Grid>
                <Grid item xs>
                  Size: {byte2string(document?.filesize)}
                </Grid>
                <Grid item xs="auto">
                  <IconButton size="small" component="a" href={document.url} download={document.filename}>
                    <DownloadIcon color="inherit" />
                  </IconButton>
                  {
                    edit &&
                    <IconButton size="small" component="span" onClick={() => onRemove(document?.id, index)}>
                      <DeleteIcon color="inherit" />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            </Box>
          ))
        }
      </Collapse>
      {
        edit &&
        <Box mt={2}>
          {
            isOpen
              ? <>
                <Grid container>
                  <Grid item xs={6}>
                    <Stack component="label" direction="row">
                      <input
                        ref={fileRef}
                        type="file"
                        onChange={handleChangeFile}
                        style={{ display: 'none' }}
                      />
                      <Button
                        color="primary"
                        component="span"
                        variant="outlined"
                      >
                        Browse
                      </Button>
                      <TextField
                        fullWidth
                        sx={{ pointerEvents: 'none' }}
                        InputProps={{
                          readOnly: true,
                        }}
                        value={data.filename}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        placeholder="Please enter file document description"
                        name="description"
                        inputProps={{ maxLength: 40 }}
                        value={data.description}
                        onChange={handleChangeDescription}
                      />
                      <Button
                        component="span"
                        variant="contained"
                        disabled={data.description.trim().length < 1}
                        onClick={handleUploadDocument}
                      >
                        Upload
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                {error && <FormHelperText error>{error}</FormHelperText>}
              </>
              : <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleShowUpload}
              >
                Upload Documents/Photos
              </Button>
          }
        </Box>
      }
    </>
  );
};

export default memo(Documents, isEqualPropsMemo);
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { ISelectOptions } from '../types/common';

export const getRoundedNumber = (number: number) => number === 0 ? `0.00` : number?.toFixed(2);

export const isEqualPropsMemo = (prevProps: object, nextProps: object) => isEqual(prevProps, nextProps);

export const capitalizeFirstLetter = (string: string | any) => `${string}`.charAt(0).toUpperCase() + string.slice(1);

export const getObjectsDifference = (obj1: { [key: string]: any }, obj2: { [key: string]: any }) => {
  // console.log('obj1', obj1);
  // console.log('obj2', obj2);
  
  
  return Object.keys(obj1).reduce((diff, key) => {
    if (isEqual(obj1[key], obj2[key])) return diff;
    return {
      ...diff,
      [key]: obj1[key],
    };
  }, {});
};

export const getFilteredObjectsArray = (arr: any[]) => arr.reduce((unique: { [key: string]: any }, cur: { [key: string]: any }) => {
  if (!unique.some((obj: ISelectOptions) => obj.label === cur.label)) {
    unique.push(cur);
  }
  return unique;
}, []);

export const getSortedSelectOptions = (arr: ISelectOptions[]) => arr?.sort((a: ISelectOptions, b: ISelectOptions) => (a.label > b.label) ? 1 : -1);

export const byte2string = (size: number) => {
  if (size < 1024) return size + ' B';
  if (size < 1024 * 1024) return Math.round(size / 1024) + ' kB';
  if (size < 1024 * 1024 * 1024) return Math.round(100 * size / 1024 / 1024) / 100 + ' Mb';
  return Math.round(100 * size / 1024 / 1024 / 1024) / 100 + ' Gb';
};

export const uploadFileToS3 = (presignedPostData: { [key: string]: any }, file: any) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key]);
    });

    formData.append('file', file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function () {
      this.status === 204 ? resolve({}) : reject(this.responseText);
    };
    xhr.onerror = function () {
      reject(this.statusText);
    };
  });
};

export const getDaysDiff = (from: string, to?: string) => moment(from).diff(to || moment(), 'days');

export const getLastArrayValue = (arr: any[]) => arr[arr.length - 1];
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { routes } from '../constants/routes';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

import { getTrainingViewSelector } from '../store/trainings/selectors';
import { viewTrainingsRequest } from '../store/trainings/actions';
import { getIsPendingSelector } from '../store/common/selectors';
import { ITrainingOnlineContent } from '../types/trainings';
import { StyledFootnote } from '../components/Trainings/TrainingsCard/styles';
import TrainingsForm from '../components/Trainings/TrainingsForm';
import PageTitle from '../components/ui/PageTitle';
import Layout from '../components/layout';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const TrainingsView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(getTrainingViewSelector);
  const pending = useSelector(getIsPendingSelector);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(viewTrainingsRequest({ id }));
    }

    // eslint-disable-next-line
  }, [id]);

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => setEdit(false);

  

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `Trainings`,
      path: '/trainings',
    },
    {
      title: `${data.name}`,
      path: '',
    },
  ];

  return (
    <Layout pending={pending}>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <PageTitle title={data.name} />
        {
          !edit &&
          <IconButton color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        }
      </Stack>
      {
        edit
          ? <TrainingsForm onCancelEdit={handleCancelEdit} edit data={data} />
          : <Card>
            <CardContent>
              <Stack direction="row" sx={{ mb: .5 }} spacing={1}>
                <StyledFootnote>
                  <AccessTimeOutlinedIcon />
                  {data.duration}
                </StyledFootnote>
                <StyledFootnote>
                  <TranslateOutlinedIcon />
                  {data.language}
                </StyledFootnote>
                <StyledFootnote>
                  <BarChartOutlinedIcon />
                  {data.level}
                </StyledFootnote>
              </Stack>

              <Box sx={{ mt: 1 }}>
                <Typography color="primary" gutterBottom variant="h6">
                  Description
                </Typography>
                <Typography gutterBottom>
                  {data.description}
                </Typography>
                <Typography gutterBottom>
                  language: {data.language}
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography color="primary" gutterBottom variant="h6">
                  Content
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Box sx={{ textAlign: 'right' }}>
                      <Button variant="outlined" component={NavLink} to={`${routes.trainings}/${id}/launch`}>
                        Launch training
                      </Button>
                    </Box>
                    {
                      data.online_content &&
                      <Card sx={{ mt: 1 }}>
                        <CardContent>
                          <Grid container spacing={4}>
                            <Grid item xs={6} sm={4}>
                              {
                                data.online_content.map((i: ITrainingOnlineContent, index: number) => (
                                  <Box key={i.id} sx={{ p: 1, backgroundColor: index === 0 ? 'rgba(0,0,0,.05)' : '' }}>
                                    {index + 1}. {i.title}
                                  </Box>
                                ))
                              }
                            </Grid>
                            <Grid item xs={6} sm={8}>
                              {
                                data.online_content[0].text_content
                              }
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    }
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
      }
    </Layout>
  );
};

export default TrainingsView;
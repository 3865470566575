import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ITrainingProfileItem } from '../../../store/trainings/types';
import { isEqualPropsMemo } from '../../../utils/helpers';
import { ITableRowProps } from '../../../types/table';

const TrainingsProfileTableRow = ({ index, data }: ITableRowProps<ITrainingProfileItem>) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
    >
      <TableCell sx={{ width: 30 }}>
        {index + 1}.
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {data.training_category}
      </TableCell>
      <TableCell>
        {data.training_name}
      </TableCell>
      <TableCell>
        {data.training_session_id}
      </TableCell>
      <TableCell>
        {data.completion_date}
      </TableCell>
      <TableCell>
        {data.completion_grade}
      </TableCell>
    </TableRow>
  );
};

export default memo(TrainingsProfileTableRow, isEqualPropsMemo);
import React, { memo, ReactChild } from 'react';
import Stack from '@mui/material/Stack';

type Props = {
  children: ReactChild | ReactChild[],
};

const FiltersContainer = ({ children }: Props) => {
  return (
    <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
      {children}
    </Stack>
  );
};

export default memo(FiltersContainer);
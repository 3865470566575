import * as ACTION_TYPES from './actionTypes'
import * as TYPES from './types'

export const fetchUsersRequest = (
  params: TYPES.IFetchUsersRequestParams
): TYPES.IFetchUsersRequest => ({
  type: ACTION_TYPES.FETCH_USERS_REQUEST,
  params,
})

export const fetchUsersSuccess = (
  payload: TYPES.IFetchUsersSuccessPayload
): TYPES.IFetchUsersSuccess => ({
  type: ACTION_TYPES.FETCH_USERS_SUCCESS,
  payload,
})

import React from 'react';

import Layout from '../components/layout';
import { useGetPageItemsEffect } from '../utils/hooks';
import { fetchTrainingsRequest } from '../store/trainings/actions';
import TrainingsFrom from '../components/Trainings/TrainingsForm';
import PageTitle from '../components/ui/PageTitle';
import BBreadcrumbs from '../components/ui/BBreadcrumbs/BBreadcrumbs';

const TrainingsCreate: React.FC = (): JSX.Element => {

  useGetPageItemsEffect(fetchTrainingsRequest);  

  const breadCrumbsItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: `Trainings`,
      path: '/trainings',
    },
    {
      title: `Create Training`,
      path: '',
    },
  ];
  
  return (
    <Layout>
      <BBreadcrumbs propBreadCrumbsItems={breadCrumbsItems} />
      <PageTitle title="Create Training" />
      <TrainingsFrom />
    </Layout>
  );
};

export default TrainingsCreate;
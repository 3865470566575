import React, { memo, ReactChild, ReactChildren } from 'react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { isEqualPropsMemo } from '../../../utils/helpers';

interface ICustomModalProps {
  open: boolean,
  title?: string,
  style?: object,
  onClose: () => void,
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[],
}

const defaultStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  p: 2,
  width: '90vw',
  maxWidth: 600,
  maxHeight: '90vh',
  overflow: 'auto',
  transform: 'translate(-50%, -50%)',
};

const CustomModal = ({ open, title, style, onClose, children }: ICustomModalProps): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Paper elevation={2} sx={{ ...defaultStyle, ...style }}>
        {title &&
          <Typography gutterBottom variant="h5" align="center">
            {title}
          </Typography>
        }
        {children}
      </Paper>
    </Modal>
  );
};

export default memo(CustomModal, isEqualPropsMemo);
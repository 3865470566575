import * as ACTION_TYPES from './actionTypes';

import { IARInvoiceActions, IARInvoiceState } from './types';

const initialState: IARInvoiceState = {
  items: [],
  has_more: false,
  itemView: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: IARInvoiceActions)  => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_AR_INCOICE_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case ACTION_TYPES.FETCH_AR_INCOICE_VIEW_SUCCESS:
      return {
        ...state,
        itemView: action.payload,
      };
    default:
      return state;
  }
};
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

interface IPageTitle {
  title: string,
  goBack?: boolean,
  goHome?: boolean,
  spaceBottom?: boolean
}

const TitleWrap = styled(Stack)({
  alignItems: 'center',
  marginBottom: 10,
});

const PageTitle = ({ title, goBack, goHome, ...props }: IPageTitle) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (goHome) {
      navigate('/')
    } else {
      navigate(-1)
    }
  };

  return (
    <TitleWrap direction="row" spacing={2}>
      {
        goBack && 
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back
        </Button>
      }
      {
        goHome && 
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back
        </Button>
      }
      <Typography {...props} variant="h4" sx={{ fontSize: 19 }} component="h1">
        {title}
      </Typography>
    </TitleWrap>
  );
};

export default memo(PageTitle);
import { all, fork } from 'redux-saga/effects'

import authSaga from './auth/sagas'
import jobsSaga from './jobs/sagas'
import usersSaga from './users/sagas'
import usersViewSaga from './usersView/sagas'
import issuesSaga from './issues/sagas'
import issuesCategoriesSaga from './system/issueCategories/sagas'
import trainingsSaga from './trainings/sagas'
import posSaga from './pos/sagas'
import ARInvoiceSaga from './ARInvoice/sagas'
import APInvoiceSaga from './APInvoice/sagas'
import ARRequestInvoicesSaga from './ARRequestInvoices/sagas'
import manpowerSaga from './manpower/sagas'

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(jobsSaga),
    fork(usersSaga),
    fork(usersViewSaga),
    fork(issuesSaga),
    fork(issuesCategoriesSaga),
    fork(trainingsSaga),
    fork(posSaga),
    fork(ARInvoiceSaga),
    fork(APInvoiceSaga),
    fork(APInvoiceSaga),
    fork(ARRequestInvoicesSaga),
    fork(manpowerSaga),
  ])
}

import * as ACTION_TYPES from './actionTypes';
import { IIssuesActions, IIssuesState } from './types';
import { merge } from 'lodash';

const initialState: IIssuesState = {
  items: [],
  has_more: false,
  itemView: {},
};

export default function issueReducer(state = initialState, action: IIssuesActions) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ISSUES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_TYPES.VIEW_ISSUES_SUCCESS:
      return {
        ...state,
        itemView: action.payload,
      };
    case ACTION_TYPES.UPDATE_ISSUES_SUCCESS:
      return {
        ...state,
        itemView: merge(state.itemView, action.payload.data),
      };
    default:
      return state;
  }
};